import React, { useEffect, useState, useMemo } from 'react';
// import TableAccount from './TableAccount';
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DefaultColumnFilter } from '../Common/extensionData';


const CampaignList = () => {
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    // const [status, setStatus] = useState('');
    const [updateAccount, setUpdateAccount] = useState(false);
    const [username, setUsername] = useState('');
    const [passAccount, setPassAccount] = useState('');
    const [roleId, setRoleId] = useState('agent');
    const [popup, setPopup] = useState({
        show: false,
        id: null,
    });

    const handleClose = () => {
        setPopup(
            {
                show: false,
                id: null,
            });
    };
    const handleShow = (row) => {
        setPopup({ show: true, id: row['row'].original.username });
        console.log('Show account11: ', row['row'].original.username)
        console.log('Show account22: ', row)
    }
    const handleEdit = (row) => {
        // setPopup({ show: true, id: row['row'].original.username });
        let vaitro = row['row'].original.roleId;
        setUpdateAccount(true)
        setUsername(row['row'].original.username)
        setRoleId(vaitro)
        // console.log('Edit account: ', row['row'].original.username, row['row'].original.roleId)
    }
    const handleDeleteYes = async () => {
        // const xoa = async () => {
        if (popup.show && popup.id) {
            console.log('Da xoa: ', popup.id)
            setPopup({
                show: false,
                id: null,
            });
            await axios.delete(`http://localhost:6600/api/v1/account/deleteAccount/${popup.id}`);
            setTimeout(() => {
                toast.success('Đã xóa account thành công ' + popup.id);
                // setStatus('Đã xóa thành côg Account: ' + popup.id);
                setLoadingData(popup.id)
            }, 1000);
        }
    }
    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                Cell: ({ row }) => (
                    <><h6>{row.index + 1}</h6></>
                ),
            },
            {
                Header: 'Tài khoản',
                accessor: 'username',
                Filter: DefaultColumnFilter,
            },
            {
                Header: 'Mật khẩu',
                accessor: 'password',
                Filter: false,
                Cell: ({ row, id }) =>
                    <>
                        ******************************
                    </>,
            },
            {
                Header: 'Vai trò',
                accessor: 'roleId',
            },
            {
                Header: 'Action',
                accessor: 'action',
                Filter: false,
                Cell: ({ row, id }) =>
                    <>{row.header}
                        <a href='#top' onClick={() => { handleEdit({ row }) }}>Sửa</a> &nbsp;
                        <a href='#top' onClick={() => { handleShow({ row }) }}>Xóa</a>
                    </>,
            },
        ]);
    useEffect(() => {
        async function getCampaignList() {
            const result = await axios.get('http://localhost:6600/api/v1/account/getCampaignList');
            setData(result.data);
            setLoadingData(false);
            // setStatus();
            console.log('Ket qua Account: ', result)
        };
        // if (loadingData) {
        // if the result is not ready so you make the axios call
        getCampaignList();
        // }
    }, [loadingData]);
    const handleOnChangeUserName = (event) => {
        console.log('handleOnChangeUserName: ', event.target.value)
        setUsername(event.target.value)
    }
    const handleOnChangePassword = (event) => {
        console.log('handleOnChangePassword: ', event.target.value)
        setPassAccount(event.target.value)
    }
    const handleOnChangeRole = (event) => {
        console.log('RoleId: ', event.target.value)
        setRoleId(event.target.value);
    }
    // Add new Account
    const HandleAddNewAccount = () => {
        console.log('AccountId: ', username)
        console.log('Password Account: ', passAccount)
        console.log('RoleId: ', roleId)
        // console.log('Images: ', roleId)

        // POST request using axios inside useEffect React hook
        let account = { username: username, password: passAccount, roleId: roleId }
        axios.post("http://localhost:6600/api/v1/account/saveAccount", account)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });

        setTimeout(() => {
            toast.success('Thêm mới Account thành công ' + username);
            // setStatus('Thêm mới thành công Account: ' + username);
            setLoadingData(username)
            setUsername('')
        }, 1000);
    }
    const HandleUpdateAccount = () => {
        console.log('AccountId: ', username)
        console.log('Password Account: ', passAccount)
        console.log('RoleId: ', roleId)
        // console.log('Images: ', roleId)

        // POST request using axios inside useEffect React hook
        let updateAccount = { username: username, password: passAccount, roleId: roleId }
        axios.post(`http://localhost:6600/api/v1/account/updateAccount/${username}`, updateAccount)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });

        setTimeout(() => {
            toast.success('Cập nhật Account thành công ' + username);
            // setStatus('Cập nhật thành công Account: ' + username);
            setLoadingData(username)
            setUsername('')
        }, 1000);
    }

    const handleAddNew = () => {
        setUpdateAccount(false)
        setUsername('')
        setPassAccount('')
    }
    return (
        <div>
            <Modal show={popup.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cảnh báo xóa chiến dịch</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc muốn xóa chiến dịch này? {popup.id}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteYes}>
                        Có
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Không
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Section 2 */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-3">
                            {/* general form elements */}
                            <div className="card card-success">
                                <div className="card-header">
                                    <h3 className="card-title sm">
                                        {(updateAccount === false) ? (
                                            <span>Thêm mới chiến dịch</span>
                                        ) : (
                                            <span>Cập nhật chiến dịch</span>
                                        )}
                                    </h3>
                                </div>
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="userName">Tên chiến dịch</label>
                                            <input type="text" disabled={updateAccount}
                                                onChange={(event) => { handleOnChangeUserName(event) }} value={username}
                                                className="form-control form-control-sm is-valid" id="username"
                                                placeholder="Nhập vào tên chiến dịch" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="CampaignCode">Mã chiến dịch</label>
                                            <input type="CampaignCode"
                                                onChange={(event) => { handleOnChangePassword(event) }} value={passAccount}
                                                className="form-control form-control-sm is-valid" id="passAccount" placeholder="Mã chiến dịch" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="role">Mẫu dữ liệu</label>
                                            <select defaultValue={roleId} value={roleId} onChange={(event) => { handleOnChangeRole(event) }} className="form-control form-control-sm">
                                                <option value='agent'>Nhân viên</option>
                                                <option value='supervisor'>Giám sát</option>
                                                <option value='admin'>Quản trị</option>
                                            </select>
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="button" onClick={() => {
                                            (updateAccount === false) ? HandleAddNewAccount() : HandleUpdateAccount()
                                        }}
                                            className="btn btn-success">
                                            {(updateAccount === false) ? (
                                                <span><i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</span>
                                            ) : (
                                                <span>Cập nhật</span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (left) */}
                        {/* right column */}

                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Danh sách chiến dịch</h3>
                                    <div className="card-tools">
                                        <a href='#top' onClick={() => { handleAddNew() }}>
                                            {(updateAccount === true) ? (
                                                <span>Thêm mới</span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </a>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* <TableAccount columns={columns} data={data} /> */}
                                        </div>
                                    </div>
                                    {/* /.row */}
                                </div>
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div >
    )
}

export default CampaignList