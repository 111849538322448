import React, { useEffect, useState, useMemo } from 'react';
import TableAccount from './TableAccount';
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import { DefaultColumnFilter } from '../Common/extensionData';


const Script = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [isEnableButton, setIsEnableButton] = useState(true);
    const [again, setAgain] = useState(false);
    const [updateNetwork, setUpdateNetwork] = useState(false);
    const [id, setId] = useState('');
    const [networkName, setNetworkName] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [port, setPort] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [notes, setNotes] = useState('');
    const [popup, setPopup] = useState({
        show: false,
        id: null,
        deleteId: null,
    });

    const handleClose = () => {
        setPopup(
            {
                show: false,
                id: null,
                deleteId: null,
            });
    };
    const handleShowNetwork = (row) => {
        setPopup({ show: true, id: row['row'].original.id, deleteId: row['row'].index });
        console.log('Show account11: ', row['row'].original.id)
        console.log('Show account22: ', row)
        console.log('Show account33 deleteId: ', row['row'].index)
    }
    const handleEditNetwork = (row) => {
        // setPopup({ show: true, id: row['row'].original.networkName });
        let port = row['row'].original.port;
        setUpdateNetwork(true)
        setId(row['row'].original.id)
        setNetworkName(row['row'].original.networkName)
        setIpAddress(row['row'].original.ipAddress)
        setPort(port)
        setUsername(row['row'].original.username)
        setPassword(row['row'].original.password)
        setNotes(row['row'].original.notes)

        // (networkName) ? setIsEnableButton(false) : setIsEnableButton(true);
        // console.log('Edit account: ', row['row'].original.networkName, row['row'].original.roleId)
    }
    const handleDeleteYes = async () => {
        const token = localStorage.getItem('accessToken');
        let maloi = '';
        if (popup.show && popup.id) {
            setPopup({
                show: false,
                id: null,
                deleteId: null,
            });
            let tralai = await axios.delete(`${URL_API}/network/deleteNetwork/${popup.id}`, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    console.log(' Da Xoa 1: ', response);
                    const newObj = data.splice(popup.deleteId, 1)
                    console.log(' Xoa 2: ', response.data.errCode)
                    console.log('Xoa New Object: ', newObj)
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' loi 1: ', error);
                });

            setTimeout(() => {
                if (maloi === 0) {
                    toast.success('Đã xóa nhà mạng thành công ' + popup.id);
                    console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Xóa nhà mạng không thành công ' + popup.id);
                }
                setLoadingData(true)
                setAgain(true)
                console.log('Xóa 4 deleteId: ', popup.deleteId)
                console.log('Data new: ', data)
            }, 50);
        }
    }

    const handleShowPass = () => {
        alert('Show mật khẩu')
    }

    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                Cell: ({ row }) => (
                    <><h6>{row.index + 1}</h6></>
                ),
            },
            {
                Header: 'Tên nhà mạng',
                accessor: 'networkName',
                Filter: DefaultColumnFilter,
            },
            {
                Header: 'Địa chỉ IP',
                accessor: 'ipAddress',
                Filter: false,
            },
            {
                Header: 'Port',
                accessor: 'port',
            },
            {
                Header: 'User',
                accessor: 'username',
            },
            {
                Header: 'Password',
                accessor: 'password',
                Filter: false,
                Cell: ({ row, id }) =>
                    <>
                        **************
                    </>,
            },
            {
                Header: 'Ghi chú',
                accessor: 'notes',
            },
            {
                Header: 'Action',
                accessor: 'action',
                Filter: false,
                Cell: ({ row, id }) =>
                    <>{row.header}
                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleEditNetwork({ row }) }}><i className='fas fa-edit'></i></span> &nbsp;
                        <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { handleShowNetwork({ row }) }}><i className='fas fa-trash-alt'></i></span>
                    </>,
            },
        ]);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        console.log('Again in effect: ', again)
        async function getNetworks() {
            if (again === false) {
                const result = await axios.get(`${URL_API}/network/getNetworks`, {
                    headers: {
                        'x-access-token': token
                    }
                });
                setData(result.data);
                setLoadingData(false);
                console.log('refresh API')
            } else {
                // setData(data);
                // setAgain(true);
                setLoadingData(false);
                console.log('refresh No API')
            }
            // setStatus();
            console.log('Ket qua refresh1: ', data)
            console.log('Ket qua again: ', again)
        };
        getNetworks();
        // }
    }, [loadingData]);

    const handleOnChangeNetworkName = (event) => {
        setNetworkName(event.target.value)
        setIsEnableButton(false);
    }

    const handleOnChangeUsername = (event) => {
        setUsername(event.target.value);
    }
    const handleOnChangePassword = (event) => {
        setPassword(event.target.value);
    }
    const handleOnChangeNotes = (event) => {
        setNotes(event.target.value);
    }
    // Add new Network
    const HandleAddNewNetwork = async () => {
        const token = localStorage.getItem('accessToken');
        console.log('Networks networkName, ipAddress, port, username, password, notes: ', networkName, ipAddress, port, username, password, notes)
        let maloi = '';
        if ((networkName !== '') && (ipAddress !== '')) {
            console.log('data: ', data)
            console.log('data id: ', data[data.length - 1].id)
            // POST request using axios inside useEffect React hook
            let network = { networkName: networkName, ipAddress: ipAddress, port: port, username: username, password: password, notes: notes }
            let tralai = await axios.post(`${URL_API}/network/saveNetwork`, network, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    const newObj = data.push(Object.assign({ id: data[data.length - 1].id + 1 }, network))
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' loi 1: ', error);
                });
            setTimeout(() => {
                if (maloi === 0) {
                    toast.success('Thêm mới nhà mạng thành công ' + networkName);
                    // console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Thêm mới nhà mạng không thành công, địa chỉ IP đã sử dụng ' + networkName);
                }
                setLoadingData(true)
                setNetworkName('')
                // console.log('Data new: ', data)
            }, 50);

        } else {
            alert("Tên nhà mạng hoặc địa chỉ IP không được để trống!")
        }
    }
    const HandleUpdateNetwork = () => {
        const token = localStorage.getItem('accessToken');
        // POST request using axios inside useEffect React hook
        let updateNetwork = { id: id, networkName: networkName, ipAddress: ipAddress, port: port, username: username, password: password, notes: notes }
        axios.post(`${URL_API}/network/updateNetwork/${id}`, updateNetwork, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // console.log(response);
            })
            .catch(function (error) {
                // console.log(error);
            });

        setTimeout(() => {
            toast.success('Cập nhật nhà mạng thành công ' + networkName);
            // setStatus('Cập nhật thành công Account: ' + networkName);
            setLoadingData(networkName)
            setNetworkName('')
        }, 1000);
    }

    const handleAddNew = () => {
        setUpdateNetwork(false)
        setNetworkName('')
        setIsEnableButton(true)
    }

    const handleAddNewScript = () => {
        alert('Add new script: ')
    }

    const handleEditScript = () => {
        alert('Edit kịch bản script: ')
    }

    const handleDeleteScript = () => {
        alert('Xóa kịch bản script: ')
    }

    const handleAddNewQuestion = () => {
        alert(' Tạo mới câu hỏi ')
    }

    return (
        <div>
            <Modal show={popup.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cảnh báo xóa thông tin nhà mạng</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc muốn xóa nhà mạng này? {popup.id}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteYes}>
                        Có
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Không
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Section 2 */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-4">
                            <div className="card card-success">
                                <div className="card-header">
                                    <h3 className="card-title sm">
                                        <span><i className='fas fa-tape'>
                                        </i>&nbsp;&nbsp;Danh sách kịch bản
                                        </span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span style={{ color: 'white', cursor: 'pointer', padding: '0' }} onClick={() => { handleAddNewScript() }}>
                                            <i className='fas fa-plus'></i> Thêm mới
                                        </span>
                                    </h3>
                                </div>
                                {/* form start */}
                                <form>

                                    <div className="card-body p-0">
                                        {/* <table bordered className="table"> */}
                                        <Table bordered>
                                            <thead>
                                                <tr>
                                                    <th style={{ padding: '3' }}>Tên kịch bản</th>
                                                    <th style={{ width: 20 }}>Mã</th>
                                                    <th style={{ width: 20, padding: '3' }}>Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Kịch bản tháng 10/2022</td>
                                                    <td>
                                                        M111
                                                    </td>
                                                    <td>
                                                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleEditScript() }}>
                                                            <i className='fas fa-edit'></i>
                                                        </span>
                                                        &nbsp;&nbsp;
                                                        <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { handleDeleteScript() }}>
                                                            <i className='fas fa-trash-alt'></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Kịch bản 2</td>
                                                    <td>
                                                        M222
                                                    </td>
                                                    <td>
                                                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleEditScript() }}>
                                                            <i className='fas fa-edit'></i>
                                                        </span>
                                                        &nbsp;&nbsp;
                                                        <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { handleDeleteScript() }}>
                                                            <i className='fas fa-trash-alt'></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>Kịch bản 3</td>
                                                    <td>
                                                        M333
                                                    </td>
                                                    <td>
                                                        <span style={{ cursor: 'pointer', color: 'blue' }}>
                                                            <i className='fas fa-edit'></i>
                                                        </span>
                                                        &nbsp;&nbsp;
                                                        <span style={{ cursor: 'pointer', color: 'red' }} >
                                                            <i className='fas fa-trash-alt'></i>
                                                        </span>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </Table>
                                        {/* </table> */}
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">
                                    <span><i className='fas fa-th'></i>&nbsp;&nbsp;Chi tiết kịch bản</span>
                                    <div className="card-tools">
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="input-group input-group">
                                                <div className='input-group-prepend'>
                                                    <span className='input-group-text'>Tên kịch bản:</span>
                                                </div>
                                                <input type="text" className="form-control" />
                                                <span className="input-group-append">
                                                    <button type="button" className="btn btn-success btn-flat">Cập nhật</button>
                                                </span>
                                            </div>
                                            {/* <TableAccount columns={columns} data={data} /> */}
                                            <Table striped bordered hover size="sm">
                                                <thead>
                                                    <tr>
                                                        <th style={{ padding: '3' }} colSpan='4'>
                                                            Thông tin kịch bản &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span style={{ color: 'red', cursor: 'pointer', padding: '0' }} onClick={() => { handleAddNewQuestion() }}>
                                                                <i className='fas fa-plus'></i> Thêm mới câu hỏi
                                                            </span>
                                                        </th>
                                                    </tr>
                                                    <tr>
                                                        <td style={{ padding: '3', width: 20 }}>STT</td>
                                                        <td style={{ padding: '3', width: 200 }}>
                                                            Mã câu hỏi
                                                        </td>
                                                        <td>
                                                            Câu hỏi&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span style={{ color: 'red', cursor: 'pointer', padding: '0' }} onClick={() => { handleAddNewQuestion() }}>
                                                                <i className='fas fa-plus'></i> Thêm mới câu hỏi
                                                            </span>
                                                        </td>
                                                        <td>
                                                            Câu trả lời&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                            <span style={{ color: 'red', cursor: 'pointer', padding: '0' }} onClick={() => { handleAddNewQuestion() }}>
                                                                <i className='fas fa-plus'></i> Thêm mới câu trả lời
                                                            </span>
                                                        </td>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                </tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default Script