import React, { useState, useMemo, useEffect } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { CSVLink } from "react-csv";
import axios from 'axios';
import moment from 'moment';
import { signOut, convertDate } from '../Utils'
import TablePerformanceByExtensions from './TablePerformanceByExtensions'


const Agents = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    // console.log('>>> Báo cáo năng suất: ')

    let startDate = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY HH:mm");
    let endDate = moment().subtract(0, 'days').startOf('day').format("MM/DD/YYYY HH:mm");
    const [data, setData] = useState([]);
    const [playFile, setPlayFile] = useState(false);
    const [recordingURL, setRecordingURL] = useState('');
    // const [status, setStatus] = useState('');
    const [thoigian, setThoigian] = useState(startDate + ' - ' + endDate);
    const [phone, setPhone] = useState('');
    const [agentId, setAgentId] = useState('all')
    const [queue, setQueue] = useState('all')
    const [extension, setExtension] = useState('all')
    const [direction, setDirection] = useState('both')
    const [callStatus, setCallStatus] = useState('both')
    const [disconnect, setDisconnect] = useState('both')
    const [exten, setExten] = useState([])
    const [username, setUsername] = useState([])


    useEffect(() => {
        async function getExtensions() {
            // const result = await axios('http://localhost:6600/api/v1/extension/listExten');
            // setExten(result.data);
        };
        getExtensions();
    }, []);

    useEffect(() => {
        async function getUsername() {
            // const result = await axios('http://localhost:6600/api/v1/account/getAllAccountUsername');
            // setUsername(result.data);
        };
        getUsername();
    }, []);

    const handleCallback = (thoi) => {
        // alert('handlCallback: ');
        setThoigian(thoi);
        console.log("Handle Callback: ", thoigian)
    }
    const handleOnSearchPerformance = async () => {
        const token = localStorage.getItem('accessToken');
        // setRecordingURL('')
        // setPlayFile(false)
        if (!thoigian) {
            setThoigian("26/04/2022 08:00 PM - 28/04/2022 04:00 AM");
            let start = startDate;
            let end = endDate;
            console.log('Start: ', start)
            console.log('End: ', end)
        }
        if (!agentId) {
            setAgentId("all");
        }
        if (!queue) {
            setQueue("all");
        }
        function convert12to24(gio) {
            let getDate = gio.slice(0, 10)
            let getTime = gio.slice(11, 19);
            let get24Time = moment(getTime, ["h:mm A"]).format("HH:mm");
            let fullTime = getDate + " " + get24Time + ":00";
            let convertDate = new Date(fullTime);
            const unixTimestamp = Math.floor(convertDate.getTime() / 1000);
            // console.log(unixTimestamp); // 👉️ 1664000732
            return unixTimestamp;
        }
        let dates = thoigian.split(" - ");
        let start = convert12to24(dates[0]);
        let end = convert12to24(dates[1]);;
        console.log('Start time: ', start)
        console.log('End time: ', end)
        // console.log('phone: ', phone);
        console.log('agentId: ', agentId);
        console.log('queue: ', queue);
        console.log('extension: ', extension)
        console.log('direction: ', direction)
        // console.log('handleDateTime _thoi gian: ', thoigian);
        try {
            let searchRecordingsPerformance = { start_time: start, end_time: end, agent_id: agentId, queue_name: queue, extension: extension, direction: '' }
            // Truyền giá trị vào API để lấy data
            // const result = await axios.post('http://localhost:6600/api/v1/recording/reportingPerformance', searchRecordingsPerformance)
            //    setData(result.data)
            //    setLoadingData(false);
            // setStatus();
            // setData(result.data);

            const resultPerformance = await axios.post(`${URL_API}/reporting/reportingPerformance`, searchRecordingsPerformance, {
                headers: { 'x-access-token': token }
            })
            setData(resultPerformance.data)
            console.log('Agents.js Reporting list Data: ')

        } catch (err) {
            console.log('Get error: ', err)
            console.log('Get error response: ', err.response)
            console.log('Get error response.data: ', err.response.data)
            console.log('Get error response.data.errMsg: ', err.response.data.errMsg)
            if (err.response.data.errMsg === 'jwt expired') {
                console.log('Token was expired')
                signOut();
            } else {
                console.log('Recordings.js Error in 266: ', err)
            }
        }
    }
    const handleOnChangeThoiGian = (event) => {
        setThoigian(event.target.value);
        console.log("handleChange: ", thoigian)
    };
    const handleOnChangePhone = (event) => {
        if (!event) {
            setPhone('all');
        }
        setPhone(event.target.value);
        console.log("handleOnChangePhone: ", phone)
    };
    const handleOnChangeAgentId = (event) => {
        // const valueOfInput = setValue(event.target.value);
        if (!event) {
            setAgentId('all');
        }
        setAgentId(event.target.value);
        console.log("handleOnChangeAgentId: ", agentId)
    };
    const handleOnChangeQueue = (event) => {
        if (!event) {
            setQueue('all');
        }
        setQueue(event.target.value);
        console.log("handleOnChangeQueue: ", queue)
    };
    const handleOnChangeExtension = (event) => {
        setExtension(event.target.value);
        console.log("handleOnChangeExtension: ", extension)
    }
    const handleOnChangeDirection = (event) => {
        setDirection(event.target.value);
        console.log("handleOnChangeDirection: ", direction)
    }
    const handleOnChangeStatus = (event) => {
        setCallStatus(event.target.value);
        console.log("handleOnChangeStatus: ", callStatus)
    }
    const handleOnChangeDisconnect = (event) => {
        setDisconnect(event.target.value);
        console.log("handleOnChangeDirection: ", disconnect)
    }

    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                accessor: 'id',
                Filter: false,
                Cell: ({ row }) => (
                    <>
                        <span>{row.index + 1}</span>
                    </>
                ),
            },
            {
                Header: 'Thời gian',
                accessor: row => convertDate(row.started_time),
                // accessor: 'started_time',
                Filter: false,
            },
            {
                Header: 'AgentID',
                accessor: 'agent_id',
            },
            {
                Header: 'Số nội bộ',
                accessor: 'extension',
            },
            {
                Header: 'Login',
                accessor: 'direction',
            },
            {
                Header: 'Sẵn sàng',
                // accessor: 'direction',
            },
            {
                Header: 'Không sẵn sàng',
                // accessor: 'direction',
            },
            {
                Header: 'Tổng cuộc gọi',
                accessor: 'call_status',
            },
            {
                Header: 'Tổng cuộc gọi vào',
                // accessor: 'call_status',
            },
            {
                Header: 'Tổng cuộc gọi ra',
                // accessor: 'call_status',
            },
            {
                Header: 'Tổng cuộc gọi vào trả lời',
                // accessor: 'call_status',
            },
            {
                Header: 'Tổng cuộc gọi ra trả lời',
                // accessor: 'call_status',
            },
        ]);

    return (
        <div>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Năng suất tài khoản</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/index.html">Home</a></li>
                                    <li className="breadcrumb-item active">Năng suất tài khoản</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* /.card */}
                                <div className="card">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label className="col-form-label col-form-label-sm">Thời gian</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="far fa-clock" /></span>
                                                        </div>
                                                        {/* <input type="text" className="form-control form-control-sm float-right" id="reservationtime" /> */}
                                                        <DateRangePicker
                                                            className="form-control form-control-sm float-right"
                                                            onEvent={(event) => handleOnChangeThoiGian(event)} value={thoigian}
                                                            onCallback={(event) => handleCallback(event.timePicker)}
                                                            initialSettings={{
                                                                timePicker: true,
                                                                startDate: startDate,
                                                                endDate: endDate,
                                                                locale: {
                                                                    format: 'MM/DD/YYYY hh:mm A',
                                                                },
                                                            }}
                                                        >
                                                            <input type="text" className="form-control col-12  form-control-sm" />
                                                        </DateRangePicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">Agent ID</label>
                                                        <select defaultValue={'all'} onChange={(event) => { handleOnChangeAgentId(event) }} className='form-control form-control-sm'>
                                                            <option value='all'>Tất cả</option>
                                                            {
                                                                username.map((item, i) => <option key={i} value={item.username}>{item.username}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">Nhóm</label>
                                                        <select style={{ transition: 0 }} defaultValue={'all'} onChange={(event) => { handleOnChangeQueue(event) }} className="form-control form-control-sm">
                                                            <option value='all'>Tất cả</option>
                                                            <option value='kythuat'>Kỹ thuật</option>
                                                            <option value='kinhdoanh'>Kinh doanh</option>
                                                            <option value='ketoan'>Kế toán</option>
                                                            <option value='nhansu'>Nhân sự</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">&nbsp;</label>
                                                        <button type="submit" onClick={() => { handleOnSearchPerformance() }}
                                                            className="btn btn-success form-control form-control-sm btn-sm">
                                                            <i className="fas fa-search fa-fw" />&nbsp; Xem báo cáo</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div>
                                                    <label className="col-form-label col-form-label-sm">&nbsp;</label><br></br>
                                                    {/* <button type="submit" onClick={() => { handleExportCallDetails() }}
                                                        className="btn btn-success form-control form-control-sm btn-sm">
                                                        <i className="fas fa-file-export" />&nbsp; Export báo cáo</button> */}
                                                    <i className="fas fa-download" style={{ color: 'red' }} />&nbsp;&nbsp;
                                                    <CSVLink
                                                        filename="Nang_suat_nhan_vien.csv"
                                                        data={data}>Download báo cáo
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TablePerformanceByExtensions columns={columns} data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </div>
    )
}

export default Agents