import React from 'react';
import CampaignList from './Campaigns/CampaignList'
import CustomerTemplate from './Campaigns/CustomerTemplate';
import DistributeGroup from './Campaigns/DistributeGroup';
import Script from './Campaigns/Script';


const Campaigns = (props) => {
    // const giatri = props.Trunks;
    return (
        <div>
            <div className="content-wrapper" style={{ padding: '0px', borderRadius: '0px' }}>
                <section style={{ height: '3px' }}>
                </section>
                {/*  */}
                <section className="content" style={{ borderRadius: '1px' }}>
                    <div className="container-fluid" style={{ padding: '10px', borderRadius: '1px' }}>
                        <div className="row">
                            <div className="col-12 col-sm-12" style={{ padding: '0px', borderRadius: '1px' }}>
                                <div className="card card-success card-tabs" style={{ padding: '0px', borderRadius: '1px' }}>
                                    <div className="card-header p-0 pt-1" style={{ padding: '0px', borderRadius: '0px' }}>
                                        <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist" style={{ padding: '0px', borderRadius: '0px' }}>
                                            <li className="pt-2 px-3" style={{ padding: '0px', borderRadius: '0px', backgroundColor: 'red' }}>
                                                <h3 className="card-title"><i className='nav-icon fas fa-edit' style={{ color: 'white' }}></i>&nbsp;Chiến dịch</h3>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link active" id="custom-tabs-two-listCampaign-tab"
                                                    data-toggle="pill" href="#custom-tabs-two-listCampaign" role="tab"
                                                    aria-controls="custom-tabs-two-listCampaign" aria-selected="true">&nbsp;
                                                    <i className='far fa-list-alt'></i>&nbsp;&nbsp;Danh sách chiến dịch</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-two-profile-tab"
                                                    data-toggle="pill" href="#custom-tabs-two-profile" role="tab"
                                                    aria-controls="custom-tabs-two-profile" aria-selected="false">&nbsp;
                                                    <i className='fas fa-hourglass-half'></i>&nbsp;&nbsp;Phân công</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-two-messages-tab"
                                                    data-toggle="pill" href="#custom-tabs-two-messages" role="tab"
                                                    aria-controls="custom-tabs-two-messages" aria-selected="false">&nbsp;
                                                    <i className='fas fa-database'></i>&nbsp;&nbsp;Mẫu dữ liệu khách hàng</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-two-Campaigns-tab"
                                                    data-toggle="pill" href="#custom-tabs-two-Campaigns" role="tab"
                                                    aria-controls="custom-tabs-two-Campaigns" aria-selected="false">
                                                    <i className='fas fa-file-import'></i>&nbsp;&nbsp;Import dữ liệu</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-fifth-kichban-tab"
                                                    data-toggle="pill" href="#custom-tabs-fifth-kichban" role="tab"
                                                    aria-controls="custom-tabs-two-Campaigns" aria-selected="false">
                                                    <i className='fas fa-tape'></i>&nbsp;&nbsp;Kịch bản</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content" id="custom-tabs-two-tabContent">
                                            <div className="tab-pane show active" id="custom-tabs-two-listCampaign" role="tabpanel" aria-labelledby="custom-tabs-two-listCampaign-tab">
                                                <CampaignList />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-two-profile" role="tabpanel" aria-labelledby="custom-tabs-two-profile-tab">
                                                <DistributeGroup />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-two-messages" role="tabpanel" aria-labelledby="custom-tabs-two-messages-tab">
                                                <CustomerTemplate />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-two-Campaigns" role="tabpanel" aria-labelledby="custom-tabs-two-Campaigns-tab">
                                                Import dữ liệu
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-fifth-kichban" role="tabpanel" aria-labelledby="custom-tabs-fifth-kichban-tab">
                                                <Script />
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </div >
    )
}

export default Campaigns