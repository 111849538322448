import React, { useEffect, useState, useMemo } from 'react'
import TableSuperExtensions from './TableSuperExtensions';
import Header from '../Header';
import axios from 'axios';
// import io from 'socket.io-client';
// const socket = io('http://localhost:6600', { transports: ['websocket'], autoConnect: false });
import socket from '../socketConnect'

const SuperExtensions = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API

    const [extensions, setExtensions] = useState([]);
    const [phoneState, setPhoneState] = useState('')
    const [loading, setLoading] = useState(0);
    // Lay danh sach tat ca Extension khi nạp trang
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // console.log('Get extension list: ', extensions)
        async function getAllExtensions() {
            const result1 = await axios.get(`${URL_API}/online/getOnlineExtensionsList`, {
                headers: {
                    'x-access-token': token
                }
            });
            console.log('Lay danh sach so noi bo qua API. useEffect 1: ', result1)
            console.log('So noi bo refresh API. useEffect 1 1: ', result1.data)
            setExtensions(result1.data);
        };
        getAllExtensions();
        // console.log('Show data 1: ', extensions)
    }, []);
    // Nhan event tu server gui den va cap nhat
    useEffect(() => {
        console.log('socket.connected in SuperExtensions: ', socket.connected)
        console.log('1 connect socket_id: ', socket.id)

        if (socket.connected !== true) {
            socket.open((err) => {
                if (err) {
                    // an error has occurred
                    console.log('Thiet lập kết nối không thành công: ', err)
                } else {
                    // the connection was successfully established
                    console.log('Thiet lập kết nối thành công: ')
                    console.log('socket connected: ', socket.connected); // true
                    console.log('connect socket_id: ', socket.id)
                }
            });
            // if (socket.connected === true) {
            //     socket.on("connect", () => {
            //         console.log('2 connect socket_id: ', socket.id)
            //     });
            // }
        }
        socket.on("connect_error", (err) => {
            console.log('connect_error : socket_id: ', socket.id)
            console.log(`socket listen error due to ${err.message}`);
        });
        socket.on('SupervisorEvent', async (message) => {
            console.log('message: ', message)
        });
        // let count = 0;
        socket.on('ExtensionsEvent', async (da) => {
            if (extensions.length > 0) {
                // console.log(' chieu dai ', extensions.length)
                await console.log(' extension Event: ', da.device, ' _data.length: ', extensions.length, ' _socket Id: ', socket.id, ' thoigian: ', Math.floor(new Date().getTime()))
                // console.log('Truoc khi tim: ', Math.floor(new Date().getTime()))
                const index = extensions.findIndex(object => {
                    return object.device === da.device;
                });
                // if (da.device === "PJSIP/" + localStorage.getItem('extension')) {
                if (da.device === localStorage.getItem('extension')) {
                    console.log('Nhan trang thai extension: ', da.device)
                    setPhoneState(da.state)
                }

                console.log("Tìm ra Index: ", index)
                extensions[index].state = da.state
                setExtensions(extensions);
                // console.log('Sau khi tim: ', Math.floor(new Date().getTime()))
                setLoading(count => count + 1);
                // console.log('loading: ', loading)

                // for (let i = 0; i < extensions.length; i++) {
                //     try {
                //         console.log('data[i].device: ', i, extensions[i].device)
                //         if (extensions[i].device === da.device) {
                //             extensions[i].state = da.state
                //             console.log('data[i].state: ', i,
                //                 extensions[i].state, ' data[i]: ', extensions[i], ' da.state: ', da.state);
                //             setExtensions(extensions);
                //             setLoading(count => count + 1);
                //             // setSeconds(currCount => currCount + 1);
                //             // console.log('extensions: ', extensions, ' loading: ', loading)
                //             break;
                //         } else {
                //             console.log('show information all extensions : ', extensions)
                //         }
                //     } catch (e) {
                //         console.error('extensions: Hiển thị thông tin error: ', e);
                //     }
                // }
            }
        });

        return () => {
            socket.on("disconnect", (reason) => {
                console.log('disconnect socketid: ', socket.id); // undefined
                console.log('disconnect reason: ', reason);
                socket.disconnect(true);
            });
            // before the component is destroyed
            // unbind all event handlers used in this component
            socket.off("ExtensionsEvent", function () {
                console.log('socket off ExtensionsEvent: ')
            });
            socket.off("connect_error", function () {
                console.log('socket off connect_error: ')
            });
        };
    }, [extensions])

    const handleListen = () => {

    }
    // const handleConf = () => {

    // }
    // const handleCoach = () => {

    // }
    // const handleDisconnect = () => {

    // }

    const headerData = [
        {
            Header: 'STT',
            accessor: 'id',
            Filter: false,
            Cell: ({ row }) => (
                <>
                    <span>{row.index + 1}</span>
                </>
            ),
        },
        {
            Header: 'URI',
            accessor: 'uri',
        },
        {
            Header: 'Status',
            accessor: 'state',
            Cell: ({ row }) =>
                <>{
                    ((row.original.state === 'UNAVAILABLE') &&
                        (<span className='badge badge-danger' style={{ fontSize: '14px' }}>Không sẵn sàng</span>)) ||
                    ((row.original.state === 'NOT_INUSE') &&
                        (<span className='badge badge-success' style={{ fontSize: '14px' }}>Sẵn sàng</span>)) ||
                    ((row.original.state === 'RINGING') &&
                        (<span className='badge badge-warning' style={{ fontSize: '14px' }}>Đổ chuông</span>)) ||
                    ((row.original.state === 'INUSE') &&
                        (<span className='badge badge-info' style={{ fontSize: '14px' }}>Đang bận</span>)) ||
                    ((row.original.state === 'ONHOLD') &&
                        (<span className='badge badge-info' style={{ fontSize: '14px' }}>Hold máy</span>))
                }
                </>
        },
        {
            Header: 'Số nội bộ',
            accessor: 'device',
            // Cell: ({ row }) =>
            //     <>
            //         <span className='badge badge-success'>{row.original.device}</span>
            //     </>
        },
        {
            Header: 'Action',
            Filter: false,
            // accessor: 'call_id',
            Cell: ({ row }) =>
                <>
                    <span style={{ cursor: 'pointer' }} onClick={() => handleListen(row.original)}><button><i className="fas fa-user-secret" title='Nghe xen' style={{ color: 'green' }} /></button></span>
                    {/* <span className='badge badge-success' style={{ cursor: 'pointer' }} onClick={() => handleCoach(row.original)}>nhắc</span>, */}
                    {/*<span style={{ cursor: 'pointer' }} onClick={() => handleConf(row.original)}>hội</span>
                    <span style={{ cursor: 'pointer' }} onClick={() => handleDisconnect(row.original)}>Ngắt</span> */}
                </>,
        },
    ]

    const columns = useMemo(
        () => headerData);

    return (
        <div>
            <div className="row mb-1" style={{ padding: '1px' }}>
                <div className="col-sm-6" style={{ padding: '1px' }}>
                    <h5>Giám sát số nội bộ</h5>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right" style={{ padding: '1px', backgroundColor: 'white' }}>
                        <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                        <li className="breadcrumb-item active">Giám sát số nội bộ</li>
                    </ol>
                </div>
            </div>
            <section className="content">
                <div className="row mb-2">
                    <div className="col-sm-12">
                        <Header statePhone={phoneState} />
                        <TableSuperExtensions columns={columns} data={extensions} />
                    </div>
                </div>
            </section>
        </div >
    )
}

export default SuperExtensions