import React, { useState, useMemo, useEffect } from 'react'
import { CSVLink } from "react-csv";
import Select from 'react-select';
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from 'axios';
import moment from 'moment';
import { convert12to24, convertComplete, signOut } from '../Utils'
import TableBillingDetail from './TableBillingDetail'


const BillingDetails = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    let startDate = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY HH:mm");
    let endDate = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY 23:59");
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [agent, setAgent] = useState([]);
    const [agents, setAgents] = useState([]);
    const [thoigian, setThoigian] = useState(startDate + ' - ' + endDate);
    const [phone, setPhone] = useState("all");
    const [dauso, setDauso] = useState("all");
    const [sonoibo, setSonoibo] = useState("all");
    const [extension, setExtension] = useState([])
    const [extensions, setExtensions] = useState([])
    const [prefix, setPrefix] = useState([])
    const [prefixes, setPrefixes] = useState([])
    const [workgroup, setWorkgroup] = useState('all');
    const [workgroups, setWorkgroups] = useState([]);
    const [exten, setExten] = useState([])


    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        let URL_API_LISTEXTEN = URL_API + '/extension/listBillingExten'
        async function getExtensions() {
            const result = await axios(`${URL_API_LISTEXTEN}`, {
                headers: {
                    'x-access-token': token
                }
            });
            setExten(result.data);
        };
        getExtensions();
    }, []);

    // Lấy danh sách account username
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getUsernames() {
            const result = await axios.get(`${URL_API}/account/getAllAccountUsername`, {
                headers: {
                    'x-access-token': token
                }
            });
            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i]['label'] = result.data[i]['username'];
                result.data[i].value = result.data[i].username;
                delete result.data[i]['username'];
            }
            setLoadingData(false)
            setAgents(result.data);
            console.log('BillingDetails.js - get result username in BillingDetails.js: ', result.data)
        };
        getUsernames();
    }, [loadingData]);

    // Lấy danh sách only prefix
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getOnlyPrefix() {
            const result = await axios.get(`${URL_API}/billing/getAllOnlyPrefix`, {
                headers: {
                    'x-access-token': token
                }
            });
            // change key name from prefix to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i]['label'] = result.data[i]['prefix'];
                result.data[i].value = result.data[i].prefix;
                delete result.data[i]['prefix'];
            }
            setLoadingData(false)
            setPrefixes(result.data);
            console.log('Queues - get result username in Queues1: ', result.data)
        };
        getOnlyPrefix();
    }, [loadingData]);

    // Lấy danh sách workgroups
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getWorkgroupNames() {
            const result = await axios.get(`${URL_API}/workgroup/listWorkgroup`, {
                headers: {
                    'x-access-token': token
                }
            });
            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i].label = result.data[i]['workgroupName'];
                result.data[i].value = result.data[i]['workgroupCode'];
                delete result.data[i]['workgroupCode'];
                delete result.data[i]['workgroupName'];
            }
            setLoadingData(false)
            setWorkgroups(result.data);
            console.log('BillingDetails.js - get result workgroupCode: ', result.data)
        };
        getWorkgroupNames();
    }, [loadingData]);


    const handleCallback = (thoi) => {
        // alert('handlCallback: ');
        setThoigian(thoi);
        console.log("Handle Callback: ", thoigian)
    }
    const handleChangeAgents = (agent) => {
        console.log('Get Agent value: ', agent)
        setAgent(agent);
    }
    const handleChangePrefixes = (prefix) => {
        console.log('Get Prefix : ', prefix)
        // console.log('Get Prefix value: ', prefix.value)
        let dauso = "";
        for (let i = 0; i < prefix.length; i++) {
            console.log('prefix ', i, ': ', prefix[i].value)
            dauso = dauso + "," + prefix[i].value
            // result.data[i].label = result.data[i]['extension'];
            // result.data[i].value = result.data[i]['extension'];
            // delete result.data[i]['extension'];
            // delete result.data[i]['workgroupCode'];
            // delete result.data[i]['extension'];
        }
        console.log('Dau so: ', dauso.slice(1))
        setPrefix(prefix);
        setDauso(dauso.slice(1))
    }

    const handleOnChangeWorkgroups = async (workgroup) => {
        console.log('Handle On Change Workgroup ok: ', workgroup)
        console.log('Handle On Change Workgroup value: ', workgroup.value)
        const token = localStorage.getItem('accessToken');
        let workgroupCode = { workgroupCode: workgroup.value }
        const result = await axios.post(`${URL_API}/workgroup/getExtensionsInWorkgroup`, workgroupCode, {
            headers: {
                'x-access-token': token
            }
        });
        let sonoibo = "";
        // change key name from extension to label and add key name is value
        for (let i = 0; i < result.data.length; i++) {
            sonoibo = sonoibo + "," + result.data[i]['extension'];
            result.data[i].label = result.data[i]['extension'];
            result.data[i].value = result.data[i]['extension'];
            delete result.data[i]['extension'];
            delete result.data[i]['workgroupCode'];
            // delete result.data[i]['extension'];
        }
        setSonoibo(sonoibo.slice(1));
        console.log('So noi bo: ', sonoibo.slice(1))
        console.log('Handle on change Workgroup Result.data: ', result.data)
        setExtension(result.data)
        console.log('Handle change Workgroup result.data.length: ', result.data.length)

        let ext = '';
        for (let i = 0; i < result.data.length; i++) {
            ext = ext + ',' + result.data[i].value;
        }
        if (ext === '') {
            setExten(exten);
        } else {
            setExten(ext.slice(1));
        }
        setWorkgroup(workgroup)
    }

    const handleOnSearchBilling = async () => {
        const token = localStorage.getItem('accessToken');
        if (!thoigian) {
            setThoigian("26/04/2022 08:00 PM - 28/04/2022 04:00 AM");
            let start = startDate;
            let end = endDate;
            console.log('Start: ', start)
            console.log('End: ', end)
        }

        let dates = thoigian.split(" - ");
        let start = convert12to24(dates[0]);
        let end = convert12to24(dates[1]);;
        let dauso1 = dauso;
        let sonoibo1 = sonoibo;
        console.log('Start time: ', start)
        console.log('End time: ', end)
        console.log('phone: ', phone);
        // console.log('prefix: ', prefix);
        // console.log('extension: ', extension)
        console.log('Đầu số trên Billing: ', dauso)
        console.log('Số nội bộ: ', sonoibo)

        // console.log('handleDateTime _thoi gian: ', thoigian);
        try {
            let getBillingDetail = { start_time: start, end_time: end, phone: phone, prefix: dauso1, extension: sonoibo1 }
            // Truyền giá trị vào API để lấy data
            const result = await axios.post(`${URL_API}/billing/getBillingDetail`, getBillingDetail, {
                headers: { 'x-access-token': token }
            })
            setData(result.data)
            //    setLoadingData(false);
            // setStatus();
            console.log('get list Data: ', getBillingDetail)
            console.log('get list Data result: ', result.data)
        } catch (err) {
            console.log('Get error: ', err)
            console.log('Get error response: ', err.response)
            console.log('Get error response.data: ', err.response.data)
            // console.log('Get error response.data.errMsg: ', err.response.data.errMsg)
            if (err.response.data.errMsg === 'jwt expired') {
                console.log('Token was expired')
                signOut();
            } else {
                console.log('BillingDetails.js Error in 225: ', err)
            }
        }

    }

    const handleOnChangeThoiGian = (event) => {
        setThoigian(event.target.value);
        console.log("handleChange: ", thoigian)
    };
    const handleOnChangePhone = (event) => {
        setPhone(event.target.value);
        console.log("handleOnChangePhone: ", phone)
    };

    const handleOnChangeExtension = (event) => {
        if (event.target.value === 'all') {

        } else {
            setExten(event.target.value)
            setSonoibo(event.target.value)
        }
        console.log("handleOnChangeExtension : ", event.target.value)
        console.log("handleOnChangeExtension workgroup: ", workgroup)
        console.log("handleOnChangeExtension exten: ", exten)
        console.log("handleOnChangeExtension sonoibo: ", event.target.value)
    }

    const handleExportCallDetails = () => {
        alert('Export báo cáo Call Details')
    }
    function convertDate(unix_timestamp) {

        const milliseconds = unix_timestamp * 1000
        const dateObject = new Date(milliseconds)
        const startDate = dateObject.toLocaleString()
        return startDate;
    }
    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                accessor: 'id',
                Filter: false,
                Cell: ({ row }) => (
                    <>
                        <span>{row.index + 1}</span>
                    </>
                ),
            },
            {
                Header: 'Thời gian',
                // accessor: row => convertDate(row.started_time),
                accessor: row => row.started_time.toLocaleString(),
                // accessor: 'started_time',

                // toLocaleString()
                Filter: false,
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'AgentID',
                accessor: 'agent_id',
            },
            {
                Header: 'Nhóm',
                accessor: 'queue_name',
            },
            {
                Header: 'Số nội bộ',
                accessor: 'extension',
            },
            {
                Header: 'Waiting',
                accessor: 'wait_time',
                Filter: false,
            },
            {
                Header: 'Talktime',
                accessor: 'talk_time',
                Filter: false,
            },
            {
                Header: 'Số tiền/giây',
                accessor: 'prefixMoney',
                Footer: 'Tổng các trang'
            },
            {
                Header: 'Số tiền',
                accessor: 'fee',
                Footer: info => {
                    // Only calculate total visits if rows change
                    const total = React.useMemo(
                        () =>
                            info.rows.reduce((sum, row) => row.values.fee + sum, 0),
                        [info.rows]
                    )

                    return <><center><span><b> {total.toLocaleString('vi-VN')}</b></span></center></>
                },
            },
            // {
            //     Header: 'Ngắt máy',
            //     accessor: row => convertComplete(row.disconnect),
            // },
        ]);
    // Thiết lập header cho báo cáo
    const headers = [
        {
            label: "Ngày",
            key: "started_time"
        },
        {
            label: "Số điện thoại",
            key: "phone"
        },
        {
            label: "AgentId",
            key: "agent_id"
        },
        {
            label: "Nhóm",
            key: "queue_name"
        },
        {
            label: "Số nội bộ",
            key: "extension"
        },
        {
            label: "Thời gian chờ",
            key: "wait_time"
        },
        {
            label: "Đàm thoại",
            key: "talk_time"
        },
        {
            label: "Số tiền/giây",
            key: "prefixMoney"
        },
        {
            label: "Số tiền",
            key: "fee"
        }
    ];

    return (
        <div>
            {/* <div className="content-wrapper"> */}
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>Tính cước chi tiết</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                                <li className="breadcrumb-item active">Tính cước chi tiết</li>
                            </ol>
                        </div>
                    </div>
                </div>

            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            {/* /.card */}
                            <div className="card">
                                <div className="card-header">
                                    <div className='row'>
                                        <div className="col-4">
                                            <div className="form-group">
                                                <label className="col-form-label col-form-label-sm">Thời gian</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text"><i className="far fa-clock" /></span>
                                                    </div>
                                                    {/* <input type="text" className="form-control form-control-sm float-right" id="reservationtime" /> */}
                                                    <DateRangePicker
                                                        className="form-control form-control-sm float-right"
                                                        onEvent={(event) => handleOnChangeThoiGian(event)} value={thoigian}
                                                        onCallback={(event) => handleCallback(event.timePicker)}
                                                        initialSettings={{
                                                            // timePicker: true,
                                                            showDropdowns: true,
                                                            startDate: startDate,
                                                            endDate: endDate,
                                                            locale: {
                                                                format: 'MM/DD/YYYY HH:mm:ss',
                                                            },
                                                        }}
                                                    >
                                                        <input type="text" className="form-control col-12  form-control-md" />
                                                    </DateRangePicker>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="form-group">
                                                <div>
                                                    <label className="col-form-label col-form-label-sm">Phone</label>
                                                    <input type="text" onChange={(event) => handleOnChangePhone(event)}
                                                        value={phone} className="form-control form-control-md" placeholder="Nhập SĐT..." />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <label htmlFor="workgroup" className="col-form-label col-form-label-sm">Tên nhóm</label>
                                            <Select
                                                defaultValue={(workgroup)}
                                                value={workgroup}
                                                onChange={(event) => { handleOnChangeWorkgroups(event) }}
                                                options={workgroups}
                                                getOptionValue={(option) => option.value}
                                                getOptionLabel={(option) => option.label}
                                                placeholder='Chọn nhóm'
                                                noOptionsMessage={() => "Không còn nhóm"}
                                            />
                                        </div>

                                        {/* <div className="col-3">
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <label htmlFor="agents" className="col-form-label col-form-label-sm">Nhân viên</label>
                                                    <Select
                                                        value={agent}
                                                        onChange={(event) => { handleChangeAgents(event) }}
                                                        options={agents}
                                                        getOptionValue={(option) => option.value}
                                                        getOptionLabel={(option) => option.label}
                                                        placeholder='Chọn nhân viên'
                                                    />
                                                </div>

                                            </div>
                                        </div> */}

                                        <div className="col-1">
                                            <div className="form-group">
                                                <label className="col-form-label col-form-label-sm">Extension</label>
                                                <select defaultValue='all' onChange={(event) => { handleOnChangeExtension(event) }}
                                                    className="form-control form-control-md">
                                                    <option value='all'>Tất cả</option>
                                                    {
                                                        extension.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                        {/* <div className="col-1">
                                            <div className="form-group">
                                                <label className="col-form-label col-form-label-sm">Hướng gọi</label>
                                                <select defaultValue={'all'}
                                                    onChange={(event) => { handleOnChangeDirection(event) }}
                                                    className='form-control form-control-md'>
                                                    <option value='both'>Cả hai</option>
                                                    <option value='inbound'>Gọi vào</option>
                                                    <option value='outbound'>Gọi ra</option>
                                                </select>
                                            </div>
                                        </div> */}
                                    </div>
                                    <div className='row' style={{ padding: 0 }}>
                                        {/* <div className="col-2">
                                            <label className="col-form-label col-form-label-sm">Tình trạng</label>
                                            <select defaultValue={'both'}
                                                onChange={(event) => { handleOnChangeStatus(event) }}
                                                className="form-control form-control-md">
                                                <option value='both'>Tất cả</option>
                                                <option value='answered'>Trả lời</option>
                                                <option value='unanswered'>Không trả lời</option>
                                            </select>
                                        </div> */}
                                        <div className="col-3">
                                            <div className="form-group">
                                                <label htmlFor="agents"
                                                    className="col-form-label col-form-label-sm">Đầu số</label>
                                                <Select
                                                    value={prefix}
                                                    onChange={(event) => { handleChangePrefixes(event) }}
                                                    options={prefixes}
                                                    getOptionValue={(option) => option.value}
                                                    getOptionLabel={(option) => option.value}
                                                    placeholder='Chọn đầu số'
                                                    isMulti />
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div>
                                                <label className="col-form-label col-form-label-sm">&nbsp;</label>
                                                <button type="submit" onClick={() => { handleOnSearchBilling() }}
                                                    className="btn btn-success form-control form-control-md btn-md">
                                                    <i className="fas fa-search fa-fw" />&nbsp; Xem chi tiết cước</button>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div>
                                                <label className="col-form-label col-form-label-md">&nbsp;</label><br></br>
                                                {/* <button type="submit" onClick={() => { handleExportCallDetails() }}
                                                        className="btn btn-success form-control form-control-sm btn-sm">
                                                        <i className="fas fa-file-export" />&nbsp; Export báo cáo</button> */}
                                                <i className="fas fa-download" style={{ color: 'red' }} />&nbsp;&nbsp;
                                                <CSVLink
                                                    filename="Cuoc_chi_tiet.csv"
                                                    data={data} headers={headers}>Download
                                                </CSVLink>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <TableBillingDetail columns={columns} data={data} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* </div > */}
        </div>
    )
}

export default BillingDetails