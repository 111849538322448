import React from 'react'
import BillingByMonths from './Billings/BillingByMonths'
import BillingByDays from './Billings/BillingByDays'
import BillingDetails from './Billings/BillingDetails'
import BillingByPhones from './Billings/BillingByPhones'


const Billings = (props) => {
    return (
        <div>
            <div className="content-wrapper" style={{ padding: '0px', borderRadius: '0px' }}>
                <section style={{ height: '3px' }}>
                </section>
                {/*  */}
                <section className="content" style={{ borderRadius: '1px' }}>
                    <div className="container-fluid" style={{ padding: '10px', borderRadius: '1px' }}>
                        <div className="row">
                            <div className="col-12 col-sm-12" style={{ padding: '0px', borderRadius: '1px' }}>
                                <div className="card card-success card-tabs" style={{ padding: '0px', borderRadius: '1px' }}>
                                    <div className="card-header p-0 pt-1" style={{ padding: '0px', borderRadius: '0px' }}>
                                        <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist" style={{ padding: '0px', borderRadius: '0px' }}>
                                            <li className="pt-2 px-3" style={{ padding: '0px', borderRadius: '0px', backgroundColor: 'red' }}>
                                                <h3 className="card-title"><i className='nav-icon fas fa-edit' style={{ color: 'white' }}></i>&nbsp;Tính cước</h3>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link active" id="custom-tabs-first-billingByMonth-tab"
                                                    data-toggle="pill" href="#custom-tabs-first-billingByMonth" role="tab"
                                                    aria-controls="custom-tabs-first-billingByMonth" aria-selected="true">&nbsp;
                                                    <i className='far fa-calendar-alt'></i>&nbsp;&nbsp;Cước theo tháng</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-two-billingByDay-tab"
                                                    data-toggle="pill" href="#custom-tabs-two-billingByDay" role="tab"
                                                    aria-controls="custom-tabs-two-billingByDay" aria-selected="false">&nbsp;
                                                    <i className='far fa-calendar'></i>&nbsp;&nbsp;Cước theo ngày</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-two-billingByPhones-tab"
                                                    data-toggle="pill" href="#custom-tabs-two-billingByPhones" role="tab"
                                                    aria-controls="custom-tabs-two-billingByPhones" aria-selected="false">&nbsp;
                                                    <i className='far fa-calendar'></i>&nbsp;&nbsp;Cước theo số nội bộ</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-three-billingByDetail-tab"
                                                    data-toggle="pill" href="#custom-tabs-three-billingByDetail" role="tab"
                                                    aria-controls="custom-tabs-three-billingByDetail" aria-selected="false">&nbsp;
                                                    <i className='far fa-calendar-check'></i>&nbsp;&nbsp;Cước chi tiết</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content" id="custom-tabs-first-tabContent">
                                            <div className="tab-pane show active" id="custom-tabs-first-billingByMonth"
                                                role="tabpanel" aria-labelledby="custom-tabs-first-billingByMonth-tab">
                                                <BillingByMonths />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-two-billingByDay"
                                                role="tabpanel" aria-labelledby="custom-tabs-two-billingByDay-tab">
                                                <BillingByDays />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-two-billingByPhones"
                                                role="tabpanel" aria-labelledby="custom-tabs-two-billingByPhones-tab">
                                                <BillingByPhones />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-three-billingByDetail"
                                                role="tabpanel" aria-labelledby="custom-tabs-three-billingByDetail-tab">
                                                <BillingDetails />
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section >
            </div >
        </div >
    )
}

export default Billings