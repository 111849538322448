import React, { useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import TableAccount from './TableAccount'

const Trunks = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API

    const [network, setNetwork] = useState('Vina')
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [popup, setPopup] = useState({
        show: false,
        id: null,
        deleteId: null,
    });
    // const handleEdit = (id) => {
    //     alert('Edit')
    //     console.log('Edit: ', id)
    // }
    const handleOnChangeNetwork = (event) => {
        setNetwork(event.target.value)
    }

    const handleClose = () => {
        setPopup(
            {
                show: false,
                id: null,
                deleteId: null,
            });
    };

    const handleEditTrunk = (row) => {
        // setPopup({ show: true, id: row['row'].original.networkName });
        console.log('row: ', row)
        let port = row['row'].original.port;
        // setUpdateTrunk(true)
        // setNetworkName(row['row'].original.networkName)
        // setIpAddress(row['row'].original.ipAddress)
        // setNotes(row['row'].original.notes)
        // console.log('Edit account: ', row['row'].original.networkName, row['row'].original.roleId)
    }

    const handleDeleteTrunk = (row) => {
        setPopup({ show: true, id: row['row'].original.id, deleteId: row['row'].index });
        console.log('Show Trunk11: ', row['row'].original.id)
        console.log('Show Trunk22: ', row)
        console.log('Show Trunk33 deleteId: ', row['row'].index)
    }

    const handleDeleteYes = async () => {
        const token = localStorage.getItem('accessToken');
        let maloi = '';
        if (popup.show && popup.id) {
            setPopup({
                show: false,
                id: null,
                deleteId: null,
            });
            let tralai = await axios.delete(`${URL_API}/network/deleteNetwork/${popup.id}`, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    console.log(' Da Xoa 1: ', response);
                    const newObj = data.splice(popup.deleteId, 1)
                    console.log(' Xoa 2: ', response.data.errCode)
                    console.log('Xoa New Object: ', newObj)
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' loi 1: ', error);
                });

            setTimeout(() => {
                if (maloi === 0) {
                    toast.success('Đã xóa Trunk thành công ' + popup.id);
                    console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Xóa Trunk không thành công ' + popup.id);
                }
                setLoadingData(true)
                // setAgain(true)
                console.log('Xóa 4 deleteId: ', popup.deleteId)
                console.log('Data new: ', data)
            }, 50);
        }
    }


    const columns = [
        {
            Header: 'Tên Trunk',
            accessor: 'trunkName',
        },
        {
            Header: 'Đầu số',
            accessor: 'dnis',
        },
        {
            Header: 'Nhà mạng',
            accessor: 'network',
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ cell: { value } }) =>
                <>
                    <a href='#top' onClick={() => { handleEditTrunk({ value }) }}>Sửa</a> &nbsp;
                    <a href='#top' onClick={() => { handleDeleteTrunk({ value }) }}>Xóa</a>
                </>,
        },
    ];
    // const data = [
    //     {
    //         trunkName: 'TrunkViettel',
    //         dnis: '18001090',
    //         ipAddress: '132.232.43.232',
    //     },
    //     {
    //         trunkName: 'TrunkMobi',
    //         dnis: '0903948373',
    //         ipAddress: '132.232.43.234',
    //     },
    //     {
    //         trunkName: 'TrunkVina',
    //         dnis: '19001091',
    //         ipAddress: '112.232.43.24',
    //     },
    //     {
    //         trunkName: 'TrunkFPT',
    //         dnis: '19006688',
    //         ipAddress: '122.212.43.37'
    //     },
    // ];



    return (
        <div style={{ padding: '0px' }}>
            {/* Section 2 */}
            <section className="content" >
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card card-success" style={{ padding: '0px', borderRadius: '1px' }}>
                                <div className="card-header">
                                    <h3 className="card-title sm">Thêm mới đầu số {console.log('render in Trunk >>>')}</h3>
                                </div>
                                <form className='needs-validation' noValidate>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="trunkName">Tên Trunk</label>
                                            <input type="text" className="form-control is-valid form-control-sm" id="trunkName" placeholder="Nhập vào tên Trunk" required />
                                            <div className="invalid-feedback">
                                                Tên Trunk không được bỏ trống.
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="dnis">Số(cung cấp bởi nhà mạng)</label>
                                            <input type="text" className="form-control is-valid form-control-sm" id="dnis" placeholder="Nhập vào số cung cấp bởi nhà mạng" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="network">Nhà mạng</label>
                                            <select defaultValue={network} onChange={(event) => { handleOnChangeNetwork(event) }} className="form-control form-control-sm">
                                                <option value='Vina'>Vinaphone</option>
                                                <option value='Viettel'>Viettel</option>
                                                <option value='Mobifone'>Mobifone</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button className="btn btn-success">
                                            <i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* right column */}
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Danh sách Trunking - Kết nối nhà mạng</h3>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* <TableAccount columns={columns} data={data} /> */}
                                        </div>
                                    </div>
                                    {/* /.row */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div >
    )
}

export default Trunks