import React, { useState, useMemo, useEffect } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { CSVLink, CSVDownload } from "react-csv";
import axios from 'axios';
import moment from 'moment';
import { convert12to24 } from '../Utils'
import TableCalls from './TableCalls'

const Calls = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    let startDate = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY HH:mm");
    let endDate = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY 23:59");
    const [data, setData] = useState([]);
    // const [status, setStatus] = useState('');
    const [thoigian, setThoigian] = useState(startDate + ' - ' + endDate);

    const handleCallback = (thoi) => {
        // alert('handlCallback: ');
        setThoigian(thoi);
        console.log("Handle Callback: ", thoigian)
    }
    const handleOnReportCallByDate = async () => {
        const token = localStorage.getItem('accessToken');
        const refreshToken = localStorage.getItem('refreshToken');
        const tokenExpiredAt = localStorage.getItem('tokenExpiredAt');
        if (!thoigian) {
            setThoigian("26/05/2022 08:00 PM - 28/05/2022 04:00 AM");
            let start = startDate;
            let end = endDate;
            console.log('Start: ', start)
            console.log('End: ', end)
        }
        let dates = thoigian.split(" - ");
        let start = convert12to24(dates[0]);
        let end = convert12to24(dates[1]);;
        console.log('Start time: ', start, ' - ', dates[0])
        console.log('End time: ', end, ' - ', dates[1])
        if (Math.floor(new Date().getTime() / 1000) > tokenExpiredAt) {
            console.log('Token đã hết hạn 1');
            const getNewToken = await axios.post(`${URL_API}/refreshToken`, { refreshToken: refreshToken })
                .then(function (response) {
                    console.log('get new token: ', response.data.token)
                    localStorage.setItem('accessToken', response.data.token)
                    localStorage.setItem('tokenExpiredAt', response.data.tokenExpiredAt)
                })
            console.log('Token đã hết hạn 2');
        }

        let startDateTime = { start_time: start, end_time: end }
        if (token && (Math.floor(new Date().getTime() / 1000) < tokenExpiredAt)) {
            // Truyền giá trị vào API để lấy data
            const result = await axios.post(`${URL_API}/reporting/summaryCallByDate`, startDateTime, {
                headers: { 'x-access-token': token }
            })
                .catch(function (error) {
                    if (error.response) {
                        console.log('error.response.data: ', error.response.data);
                        console.log('error.response.status: ', error.response.status);
                        console.log('error.response.headers: ', error.response.headers);
                    }
                });
            setData(result.data)
        } else {
            window.location.reload(false);
        }
    }
    const handleOnChangeThoiGian = (event) => {
        setThoigian(event.target.value);
        console.log("handleChange: ", thoigian)
    };
    const handleExportCallByDate = () => {
        alert('handle Export báo cáo')
    }
    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                Cell: ({ row }) => (
                    <><h6>{row.index + 1}</h6></>
                ),
            },
            {
                Header: 'Ngày tháng',
                accessor: 'ndate',
                Filter: false,
            },
            {
                Header: 'Tổng cuộc gọi',
                accessor: 'TongCuocGoi',
                Filter: false,
            },
            {
                Header: 'Tổng cuộc gọi vào',
                accessor: 'TongCuocGoiVao',
                Filter: false,
            },
            {
                Header: 'Trả lời',
                accessor: 'Traloi',
                Filter: false,
            },
            {
                Header: 'Không trả lời',
                accessor: 'KhongTraloi',
                Filter: false,
            },
            {
                Header: 'Tổng cuộc gọi ra',
                accessor: 'TongGoiRa',
                Filter: false,
            },
            {
                Header: 'Gọi ra có trả lời',
                accessor: 'GoiRaCoTraloi',
                Filter: false,
            },
            {
                Header: 'Gọi ra không trả lời',
                accessor: 'GoiRaKhongTraloi',
                Filter: false,
            },
        ]);

    const headers = [
        {
            label: "Ngày",
            key: "ndate"
        },
        {
            label: "Tổng cuộc gọi",
            key: "TongCuocGoi"
        },
        {
            label: "Tổng cuộc gọi vào",
            key: "TongCuocGoiVao"
        },
        {
            label: "Trả lời",
            key: "Traloi"
        },
        {
            label: "Không trả lời",
            key: "KhongTraloi"
        },
        {
            label: "Tổng gọi ra",
            key: "TongGoiRa"
        },
        {
            label: "Gọi ra có trả lời",
            key: "GoiRaCoTraloi"
        },
        {
            label: "Gọi ra không trả lời",
            key: "GoiRaKhongTraloi"
        }
    ];

    return (
        <div>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Cuộc gọi theo ngày</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                                    <li className="breadcrumb-item active">Cuộc gọi theo ngày</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label className="col-form-label col-form-label-sm">Thời gian</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="far fa-clock" /></span>
                                                        </div>
                                                        <DateRangePicker
                                                            className="form-control form-control-sm float-right"
                                                            onEvent={(event) => handleOnChangeThoiGian(event)} value={thoigian}
                                                            onCallback={(event) => handleCallback(event.timePicker)}
                                                            initialSettings={{
                                                                showDropdowns: true,
                                                                // timePicker: true,
                                                                startDate: startDate,
                                                                endDate: endDate,
                                                                locale: {
                                                                    format: 'MM/DD/YYYY HH:mm:ss',
                                                                },
                                                            }}>
                                                            <input type="text" className="form-control col-12 form-control-sm" />
                                                        </DateRangePicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">&nbsp;</label>
                                                        <button type="submit" onClick={() => { handleOnReportCallByDate() }}
                                                            className="btn btn-success form-control form-control-sm btn-sm">
                                                            <i className="fas fa-search fa-fw" />&nbsp; Xem báo cáo</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div>
                                                    <label className="col-form-label col-form-label-sm">&nbsp;</label><br></br>
                                                    {/* <button type="submit" onClick={() => { handleExportCallDetails() }}
                                                        className="btn btn-success form-control form-control-sm btn-sm">
                                                        <i className="fas fa-file-export" />&nbsp; Export báo cáo</button> */}
                                                    <i className="fas fa-download" style={{ color: 'red' }} />&nbsp;&nbsp;
                                                    <CSVLink
                                                        filename="bao_cao_ngay.csv"
                                                        data={data}>Download báo cáo ngày
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TableCalls columns={columns} data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </div>
    )
}

export default Calls