import { React, useState, useEffect } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';
import Select from 'react-select';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import socket from '../socketConnect'
// import io from 'socket.io-client';
// const socket = io('http://localhost:6600', { transports: ['websocket'] });


const Outbound = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    const hobbyList = useSelector(state => state.hobby.list)
    const dispatch = useDispatch();
    const handleClickView = () => {
        console.log('Agent Outbound get: hobby list: ', hobbyList)
        if (hobbyList && Object.keys(hobbyList).length === 0) {
            console.log('Hobby list is empty')
        } else {
            console.log('Hobby list is: ', hobbyList[hobbyList.length - 1])
        }
    }

    const [thoigian, setThoigian] = useState('');
    const [loadingData, setLoadingData] = useState(true);
    const [queueByAccountId, setQueueByAccountId] = useState([]);
    const [state, setState] = useState([{ value: 'dihop', label: 'Đi họp' }]);
    const [states, setStates] = useState([
        { value: 'sansang', label: 'Sẵn sàng' },
        { value: 'dihop', label: 'Đi họp' },
        { value: 'nghitrua', label: `Nghỉ trưa` },
        { value: 'viecrieng', label: 'Việc riêng' },
        { value: 'antoi', label: 'Ăn tối' },
        { value: 'email', label: 'Email' },
    ]);

    // Lấy danh sách queueName
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        const accountId = localStorage.getItem('username');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getQueueNamesByAccountId() {
            const result = await axios.get(`${URL_API}/queue/getQueueNameByAccountId/${accountId}`, {
                headers: {
                    'x-access-token': token
                }
            });
            setLoadingData(false)
            setQueueByAccountId(result.data);
            console.log('Agents => Outbound.js - get queueName by accountId: ', result.data)
        };
        getQueueNamesByAccountId();
    }, [loadingData]);
    // console.log('QueueByAccountId: ', queueByAccountId)
    const handleEmitData = () => {
        // socket.emit('getAgentEvent', { mota: '8989' }); // Gửi cho tất cả loại trừ mình ra
        socket.emit('getAgentEvent', {
            dateTime: "2022-05-28 11:12:13",
            extension: '3300',
            phoneNumber: '0909876555',
            agentId: 'hanp',
        });
    }
    const handleChangeState = (state) => {
        console.log('Get Agent value: ', state)
        setState(state);
    }
    const handleOpenMenu = () => {
        alert("open menu")
    }
    const handleDateTime = () => {
        alert('Nothing is free !');

        // const valueOfInput = setThoigian(event.target.value);
        // console.log('Time: ', valueOfInput);
        console.log('handleDateTime: ', thoigian);
        let dates = thoigian.split(" - ");
        let start = dates[0];
        let end = dates[1];
        console.log('Start: ', start)
        console.log('End: ', end)
    }
    const handleChange = (event) => {
        // const valueOfInput = setValue(event.target.value);
        setThoigian(event.target.value);
        console.log("handleChange: ", thoigian)
    };
    const handleCallback = (thoi) => {
        // alert('handlCallback: ');
        setThoigian(thoi);
        console.log("Handle Callback: ", thoigian)

    }
    const handleAnswer = () => {
        alert(' Answer');
    }
    const handleCancel = () => {
        alert(' Cancel');
    }
    const handleRemoveQueue = () => {
        alert(' Remove Queue ');
        toast.error('Xóa nhân viên khỏi nhóm thành công ');
    }
    const handleAddQueue = () => {
        alert(' Add Queue ');
        toast.success('Thêm mới nhân viên vào nhóm thành công ');
    }

    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            borderBottom: '1px dotted pink',
            color: state.isSelected ? 'red' : 'blue',
            padding: 10,
        }),
        control: () => ({
            // none of react-select's styles are passed to <Control />
            width: 180,
        }),
        singleValue: (provided, state) => {
            const opacity = state.isDisabled ? 0.5 : 1;
            const transition = 'opacity 300ms';

            return { ...provided, opacity, transition };
        }
    }



    return (
        <div>
            <div className="content-wrapper">
                <section style={{ height: '15px' }}>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            {/* Menu cột thứ 1 */}
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-header" style={{ padding: '10px', borderRadius: '0px' }}>
                                        <h5 className="card-title" style={{ height: '0px', borderRadius: '0px' }}>
                                            <i className='fas fa-phone-alt'></i>&nbsp;&nbsp;Cuộc gọi</h5>
                                        {/* Nút bấm thu nhỏ và đóng */}
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                        {/* End nút bấm thu nhỏ và đóng */}
                                    </div>
                                    <div className="card-body" style={{ padding: '10px', borderRadius: '0px' }}>
                                        <div className="row">
                                            <div className="col-md-12">
                                                <p className="text-center">
                                                    <strong>Thông tin cuộc gọi</strong>
                                                </p>
                                                <p className="text-center">
                                                    <strong>Ringing</strong>
                                                </p>
                                                <p className="text-center">
                                                    <strong>0909654979</strong>
                                                </p>
                                                <p className="text-center">
                                                    <small>00:00:08</small>
                                                </p>
                                                <div>
                                                    <span>
                                                        <button className='btn btn-block btn-success' onClick={() => { handleAnswer() }}>Trả lời</button>
                                                        <button className='btn btn-block btn-danger' onClick={() => { handleCancel() }}>Từ chối</button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" style={{ padding: '10px', borderRadius: '0px' }}>
                                        <h5 className="card-title" style={{ height: '0px', borderRadius: '0px' }}>
                                            <i className='fas fa-users'></i>&nbsp;&nbsp;Trạng thái & Queues</h5>
                                        {/* Nút bấm thu nhỏ và đóng */}
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                        {/* End nút bấm thu nhỏ và đóng */}
                                    </div>
                                    <div className="card-body" style={{ padding: '10px', borderRadius: '0px' }}>
                                        <Select
                                            // menuIsOpen={() => handleOpenMenu()}
                                            // styles={customStyles}
                                            value={state}
                                            onChange={(event) => { handleChangeState(event) }}
                                            options={states}
                                            getOptionValue={(option) => option.value}
                                            getOptionLabel={(option) => option.label}
                                            placeholder='Trạng thái làm việc'
                                        />
                                    </div>
                                    <div className="card-body" style={{ padding: '10px', borderRadius: '0px' }}>
                                        {queueByAccountId.map(item => (
                                            <>
                                                <div className="row" style={{ padding: '10px', borderRadius: '0px' }}>
                                                    <div className="col-md-8">
                                                        {item.queueName}
                                                    </div>
                                                    <div className="col-md-2">
                                                        {item.penalty}
                                                    </div>
                                                    <div className="col-md-1">
                                                        <a href="#top" onClick={() => { handleRemoveQueue() }} style={{ cursor: 'pointer', color: 'red' }} title='Remove Queue'><i className="fas fa-minus" /></a>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <a href="#top" onClick={() => { handleAddQueue() }} style={{ cursor: 'pointer', color: 'green' }} title='Remove Queue'><i className="fas fa-plus" /></a>
                                                    </div>
                                                </div>
                                            </>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            {/* Menu cột thứ 2 */}
                            <div className="col-md-9">
                                <div className="card">
                                    <div className="card-header" style={{ padding: '0px', borderRadius: '0px' }}>
                                        <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist" style={{ padding: '0px', borderRadius: '0px' }}>
                                            <li className="nav-item">
                                                <a className="nav-link active" id="custom-tabs-two-home-tab" data-toggle="pill"
                                                    href="#custom-tabs-two-home" role="tab"
                                                    aria-controls="custom-tabs-two-home" aria-selected="true">
                                                    <i className='far fa-address-book'></i>&nbsp;&nbsp;Danh sách gọi</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-two-profile-tab" data-toggle="pill"
                                                    href="#custom-tabs-two-profile" role="tab"
                                                    aria-controls="custom-tabs-two-profile" aria-selected="false">
                                                    <i className='far fa-calendar-check'></i>&nbsp;&nbsp;Trạng thái làm việc</a>

                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-two-messages-tab" data-toggle="pill"
                                                    href="#custom-tabs-four-history" role="tab"
                                                    aria-controls="custom-tabs-four-history" aria-selected="false">
                                                    <i className='fas fa-history'></i>&nbsp;&nbsp;Lịch sử cuộc gọi</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content" id="custom-tabs-two-tabContent">
                                            <div className="tab-pane show active" id="custom-tabs-two-home" role="tabpanel" aria-labelledby="custom-tabs-two-home-tab">
                                                {/* <button onClick={() => { handleClickView() }}>Nút bấm</button> */}
                                                Danh sách gọi
                                                {/* <button onClick={() => { handleEmitData() }}>Emit data</button> */}
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-two-profile" role="tabpanel"
                                                aria-labelledby="custom-tabs-two-profile-tab">
                                                Trạng thái làm việc
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-four-history" role="tabpanel"
                                                aria-labelledby="custom-tabs-four-history-tab">
                                                Lịch sử cuộc gọi
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/*/. container-fluid */}
                </section >
            </div >
        </div >
    )
}

export default Outbound