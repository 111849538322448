import React, { useEffect, useState, useMemo } from 'react';


const CustomerTemplate = () => {
    const handleAddNewTemplate = () => {
        alert('theem moi template')
    }
    const handleEditTemplate = () => {
        alert('Edit template name')
    }
    const handleDeleteTemplate = () => {
        alert('Delete template name')
    }
    return (
        <div>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header" style={{ padding: 5 }}>
                                    <div className="card-title" style={{ padding: 5 }}>
                                        <h5>Tên mẫu dữ liệu</h5>
                                    </div>
                                    <div className="card-tools" style={{ padding: 0 }}>

                                        <span style={{ color: 'blue', cursor: 'pointer', padding: '0' }} onClick={() => { handleAddNewTemplate() }}>
                                            <i className='fas fa-plus'></i> Thêm mới
                                        </span>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body p-0">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th style={{ width: 5 }}>#</th>
                                                <th>Tên mẫu</th>
                                                <th>Mã</th>
                                                <th style={{ width: 40 }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>1</td>
                                                <td>Mẫu 1</td>
                                                <td>
                                                    M123
                                                </td>
                                                <td>
                                                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleEditTemplate() }}>
                                                        <i className='fas fa-edit'></i>
                                                    </span>
                                                    &nbsp;&nbsp;
                                                    <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { handleDeleteTemplate() }}>
                                                        <i className='fas fa-trash-alt'></i>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>2</td>
                                                <td>Mẫu 2</td>
                                                <td>
                                                    M123
                                                </td>
                                                <td>
                                                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleEditTemplate() }}>
                                                        <i className='fas fa-edit'></i>
                                                    </span>
                                                    &nbsp;&nbsp;
                                                    <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { handleDeleteTemplate() }}>
                                                        <i className='fas fa-trash-alt'></i>
                                                    </span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>3.</td>
                                                <td>Cron job running</td>
                                                <td>
                                                    M123
                                                </td>
                                                <td>
                                                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleEditTemplate() }}>
                                                        <i className='fas fa-edit'></i>
                                                    </span>
                                                    &nbsp;&nbsp;
                                                    <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { handleDeleteTemplate() }}>
                                                        <i className='fas fa-trash-alt'></i>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                {/* /.card-body */}
                                {/* /.card */}

                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* <TableAccount columns={columns} data={data} /> */}
                                        </div>
                                    </div>
                                    {/* /.row */}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"><b>Các trường dữ liệu khách hàng</b></h3>
                                </div>
                                <div className="col-md-8 card-body">
                                    <div className="input-group input-group">
                                        <div className='input-group-prepend'>
                                            <span className='input-group-text'>Tên mẫu:</span>
                                        </div>
                                        <input type="text" className="form-control" />
                                        <span className="input-group-append">
                                            <button type="button" className="btn btn-success btn-flat">Cập nhật</button>
                                        </span>
                                    </div>
                                </div>
                                <p className="card-text">&nbsp;&nbsp;&nbsp;
                                    <i className='fas fa-th mr-1'></i> Các trường thông tin dữ liệu
                                </p>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {/* <TableAccount columns={columns} data={data} /> */}
                                        </div>
                                    </div>
                                    {/* /.row */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default CustomerTemplate