import React, { useState, useEffect, useMemo } from 'react';
import TableExtension from './TableExtension';
import axios from 'axios';
import { toast } from 'react-toastify'
import Select from 'react-select';
import { DefaultColumnFilter } from '../Common/extensionData';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import NavbarCollapse from 'react-bootstrap/esm/NavbarCollapse';


const Extensions = () => {
    // Define a default UI for filtering
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    const [showTopUpBilling, setShowTopUpBilling] = useState({
        show: false,
        extensionId: null,
        currentMoney: null,
        reset: null,
    });

    const [showResetPassword, setShowResetPassword] = useState({
        show: false,
        extensionId: null,
    });

    const [data, setData] = useState([]);
    const [extension, setExtension] = useState('');
    const [extensionMoney, setExtensionMoney] = useState('');
    const [currentMoney, setCurrentMoney] = useState('');
    const [showLineGroup, setShowLineGroup] = useState(false);
    const [money, setMoney] = useState('');
    const [notes, setNotes] = useState('');
    const [extensionTopUp, setExtensionTopUp] = useState('');
    const [workgroup, setWorkgroup] = useState([]);
    const [workgroups, setWorkgroups] = useState([]);
    const [outboundLine, setOutboundLine] = useState([]);
    const [outboundLines, setOutboundLines] = useState([]);
    const [passExtension, setPassExtension] = useState('');
    const [loadingData, setLoadingData] = useState(true);
    const [popup, setPopup] = useState({
        show: false,
        id: null,
    });
    const [popupTopUp, setPopupTopUp] = useState({
        show: false,
        topUp: null,
        deleteId: null,
    })

    const [pass, setPass] = useState({
        show: false,
        passwd: ''
    })


    // Cộng thêm tiền cho số nội bộ
    const handleTopUpMoneyExtension = (row) => {
        let extensionId = row.row.original.username;
        let currentMoney = row.row.original.money;

        setShowTopUpBilling(
            {
                show: true,
                extensionId: extensionId,
                currentMoney: currentMoney,
                reset: false
            });
        setExtensionTopUp(extensionId)
        setCurrentMoney(currentMoney)
        console.log('Show số tiền hiện tại thêm: ', currentMoney)
        console.log('Show money in row: ', row)
    }

    const handleAddNewPrefix = async () => {
        // alert('Add theme mowi 1')
        const result = await axios.get(`https://test.vps.test15ee.goerp.org/vps-api/get_partner_type/?phone=0355554966`);

        console.log('Trunks.js - get result test: ', result.data)
    }

    // Reset tài khoản của số nội bộ.
    const handleResetMoneyExtension = (row) => {
        let extensionId = row.row.original.username;
        let currentMoney = row.row.original.money;
        // let money = row.row.original.money;
        setShowTopUpBilling(
            {
                show: true,
                extensionId: extensionId,
                currentMoney: currentMoney,
                reset: true
            });
        setExtensionTopUp(extensionId)
        setCurrentMoney(currentMoney)
        console.log('Show Reset Money in extensionId: ', extensionId)
        console.log('Show Reset currentMoney: ', currentMoney)
        // console.log('Show Reset money: ', money)
    }

    const handleResetPassword = async (row) => {
        let extensionId = row.row.original.username;
        setShowResetPassword(
            {
                show: true,
                extensionId: extensionId
            });
    }

    const columns = useMemo(() => [
        {
            Header: 'Số thứ tự',
            Cell: ({ row }) => (
                <><h6>{row.index + 1}</h6></>
            ),
            Filter: DefaultColumnFilter,
        },
        {
            Header: 'Số nội bộ',
            accessor: 'username',
        },
        {
            Header: 'Mật khẩu',
            // accessor: 'password',
            Filter: false,
            Cell: ({ row }) => (
                <>
                    {((extension !== row['original'].username) || (pass.show === false)) ? (
                        <span
                        >
                            {/* {(pass.show) ? pass.passwd : ''} */}
                            {/* {(pass.show)} */}
                            ***********************
                            <i className="far fa-eye" onClick={() => { handleShowPassword({ row }) }}
                                style={{ cursor: 'pointer' }} title='Hiển thị mật khẩu'></i>
                        </span>) : (
                        <span>
                            {(pass.passwd)}
                            < i onClick={() => { handleHidePassword({ row }) }} className="far fa-eye-slash" style={{ cursor: 'pointer' }} title='Ẩn mật khẩu'></i>
                        </span>
                    )
                    }

                    {/* {((playFile === true) && (recordingId === row['original'].call_id)) ? (
                        <span>
                            <audio style={{ width: 170, height: 22 }} controls src={recordingURL} autoPlay /></span>

                    ) : (
                        <span onClick={() => { handlePlay({ row }) }}><i className='fas fa-play' style={{ color: 'green' }}></i></span>
                    )
                    } */}



                </>
            ),
        },
        {
            Header: 'Tên nhóm',
            accessor: 'bbassss',
            Filter: false,
        },
        {
            Header: 'Line gọi ra',
            accessor: 'cccass',
        },
        {
            Header: 'Số tiền',
            accessor: 'money',
            Filter: false,
        },
        {
            Header: 'Action',
            Filter: false,
            accessor: 'id',
            Cell: ({ row, id }) =>

                <>
                    <span style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => { handleEdit({ row }) }}>
                        <i className='fas fa-edit'></i></span>&nbsp;&nbsp;
                    {/* <span style={{ cursor: 'pointer', color: 'black' }}
                        onClick={() => { handleResetPassword({ row }) }}>
                        <i className='fas fa-key' title='Reset mật khẩu'></i></span>&nbsp;&nbsp; */}
                    <span style={{ cursor: 'pointer', color: 'red' }}
                        onClick={() => { handleShow({ row }) }}>
                        <i className='fas fa-trash-alt' title='Xóa số nội bộ'></i></span>&nbsp;&nbsp;
                    <span style={{ cursor: 'pointer', color: 'green' }}
                        onClick={() => { handleTopUpMoneyExtension({ row }) }}>
                        <i className='far fa-money-bill-alt' title='Cộng tiền'></i></span>&nbsp;&nbsp;
                    <span style={{ cursor: 'pointer', color: 'green' }}
                        onClick={() => { handleResetMoneyExtension({ row }) }}>
                        <i className="fas fa-undo-alt" title='Reset'></i></span>&nbsp;&nbsp;
                    {/* <span style={{ cursor: 'pointer', color: 'green' }}
                        onClick={() => { handleResetMoneyExtension({ row }) }}>
                        <i className="far fa-clock" style={{ color: 'black' }} title='Lịch sử nạp tiền'></i>
                    </span> */}
                </>,
        },

    ]); // Gọi header 1 lần duy nhất

    const handleShowPassword = (row) => {
        let username = row['row'].original.username;
        let password = row['row'].original.password;
        setPass({
            show: true,
            passwd: password
        })
        setExtension(username)

        console.log('Row: ', row)
        console.log('extension: ', username)
        console.log('Top 1: ', pass.show)

        // toast.success('Password của số ' + extension + ' là ' + password);
    }

    const handleHidePassword = (row) => {
        let username = row['row'].original.username;
        let password = row['row'].original.password;
        setPass({
            show: false,
            passwd: null
        })
        setExtension(username)

        console.log('Row: ', row)
        console.log('Top 2: ', pass.show)
        // toast.success('Password của số ' + extension + ' là ' + password);
    }

    const handleClose = () => {
        setPopup(
            {
                show: false,
                id: null,
            });
    };
    const handleShow = (id) => {
        setPopup({ show: true, id: id.row.original.username });
        console.log('Show: ', id)
        console.log('Show value: ', id.row.original.username)
    }
    const handleCloseModalTopUp = () => {
        setShowTopUpBilling(false)
    };

    const handleCloseModalLineGroup = () => {
        setShowLineGroup(false)
    };

    const handleCloseModalResetPassword = () => {
        setShowResetPassword({
            show: false
        })
    };

    // Lấy danh sách list Extension
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // console.log('Token key2 ', token)
        async function getExtensions() {
            const result = await axios(`${URL_API}/extension/listExtension`, {
                headers: {
                    'x-access-token': token
                }
            });
            setLoadingData(false);
            setData(result.data);
        };
        getExtensions();
    }, [loadingData]);
    // Lấy danh sách workgroups theo extension
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getWorkgroupNames() {
            const result = await axios.get(`${URL_API}/workgroup/listWorkgroup`, {
                headers: {
                    'x-access-token': token
                }
            });
            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i].label = result.data[i]['workgroupName'];
                result.data[i].value = result.data[i]['workgroupCode'];
                delete result.data[i]['workgroupCode'];
                delete result.data[i]['workgroupName'];
            }
            setLoadingData(false)
            setWorkgroups(result.data);
            console.log('Extension.js - get result workgroupCode: ', result.data)
        };
        getWorkgroupNames();
    }, [loadingData]);

    // Lấy danh sách lineGroup dùng cho extension
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getLineGroup() {
            const result = await axios.get(`${URL_API}/billing/getAllLineGroup`, {
                headers: {
                    'x-access-token': token
                }
            });
            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i].label = result.data[i]['lineGroup'];
                result.data[i].value = result.data[i]['lineGroup'];
                delete result.data[i]['lineGroup'];
                delete result.data[i]['lineGroup'];
            }
            setLoadingData(false)
            setOutboundLines(result.data);
            console.log('Extension.js - get result lineGroup: ', result.data)
        };
        getLineGroup();
    }, [loadingData]);

    // Add new Extension and add to workgroup
    const HandleAddNewExtension = async () => {
        const token = localStorage.getItem('accessToken');
        console.log('Extension: ', extension)
        console.log('Password Extension: ', passExtension)
        console.log('Nhóm tài khoản: ', workgroup)
        console.log('Line gọi ra: ', outboundLine)
        // Xử lý để lấy chuỗi theo nhóm tài khoản
        let maloi = '';
        let workgroupMember = '';
        if (workgroup.length > 1) {
            for (let j = 0; j < workgroup.length; j++) {
                workgroupMember = workgroupMember + ',' + workgroup[j].value;
            }
            workgroupMember = workgroupMember.slice(0);
        } else if (workgroup.length == 1) {
            workgroupMember = workgroup[0].value;
            workgroupMember = workgroupMember.slice(0);
        } else if (workgroup.length == 1) {
            workgroupMember = '';
        }
        // Xử lý để lấy chuỗi nhóm line gọi ra
        let outboundLineMember = '';
        if (outboundLine.length > 1) {
            for (let j = 0; j < outboundLine.length; j++) {
                outboundLineMember = outboundLineMember + ',' + outboundLine[j].value;
            }
            outboundLineMember = outboundLineMember.slice(0);
        } else if (outboundLine.length == 1) {
            outboundLineMember = outboundLine[0].value;
            outboundLineMember = outboundLineMember.slice(0);
            // console.log('agent000: ', agent[0].value)
        } else if (outboundLine.length == 0) {
            outboundLineMember = '';
        }
        // ========================================//
        if ((extension !== '') && (passExtension !== '')) {
            // POST request using axios inside useEffect React hook
            const exten = { extension: extension, matkhau: passExtension, workgroupCode: workgroupMember, outboundLine: outboundLineMember };
            await axios.post(`${URL_API}/extension/saveExtension`, exten, {
                headers: { 'x-access-token': token }
            })
                // .then(response => setExtension(response.data.id))
                .then(function (response) {
                    // const newObj = data.push(Object.assign({ id: data[data.length - 1].id + 1 }, network))
                    setExtension(response.data.id)
                    return maloi = response.data.errCode
                })
                .catch(error => {
                    // setStatus({ errorMessage: error.message });
                    console.error('There was an error!', error);
                });
            setTimeout(() => {
                if (maloi === 0) {
                    toast.success('Thêm mới số nội bộ thành công ' + extension);
                    // console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Thêm số nội bộ không thành công, số nội bộ đã sử dụng ' + extension);
                }
                setLoadingData(true)
                setExtension('')
            }, 100);
        } else {
            alert('Số nội bộ hoặc mật khẩu không thể để trống')
        }
    }

    const HandleSaveNewPassword = async () => {
        console.log('Handle save new password: ')
    }

    const HandleSaveNewTopUp = async () => {
        // alert('Cập nhật cước')
        const token = localStorage.getItem('accessToken');
        let resetBilling = showTopUpBilling.reset;
        let updateMoney = '';
        console.log('reset cước is true: ', resetBilling)
        console.log('extensionMoney: ', extensionTopUp)
        console.log('money goc: ', money)
        console.log('money currentMoney: ', currentMoney)
        console.log('notes: ', notes)

        if (resetBilling == true) {
            updateMoney = parseInt(money);
        } else {
            if (currentMoney == null) {
                updateMoney = parseInt(money);
            } else {
                updateMoney = parseInt(currentMoney) + parseInt(money);
            }
        }

        if ((extensionTopUp !== '') && (updateMoney !== '')) {
            // POST request using axios inside useEffect React hook
            const billing_extension = { extensionId: extensionTopUp, money: updateMoney, notes: notes };
            await axios.post(`${URL_API}/billing/updateExtensionMoney`, billing_extension, {
                headers: { 'x-access-token': token }
            })
                .then(response => setExtensionMoney(response.data.extensionMoney))
                .catch(error => {
                    // setStatus({ errorMessage: error.message });
                    console.error('There was an error!', error);
                });
            setTimeout(() => {
                toast.success('Cập nhật cước thành công ' + extensionTopUp + '. Số tiền mới: ' + updateMoney);
                setLoadingData(true);
                // setExtension('')
            }, 500);
        } else {
            alert('Số nội bộ hoặc số tiền không thể để trống')
        }
    }

    const handleEdit = (id) => {
        alert('Edit')
        console.log('Edit: ', id.value)
    }
    const handleDeleteYes = async () => {
        const token = localStorage.getItem('accessToken');
        // const xoa = async () => {
        if (popup.show && popup.id) {
            console.log('Da xoa: ', popup.id)
            setPopup({
                show: false,
                id: null,
            });
            await axios.delete(`${URL_API}/extension/delete/${popup.id}`, {
                headers: {
                    'x-access-token': token
                }
            });

            setTimeout(() => {
                toast.success('Đã xóa số nội bộ thành công ' + popup.id);
                // setStatus('Đã xóa thành công ' + popup.id);
                setLoadingData(true)
                // setUsername('')
            }, 1000);
        }
    }
    const handleOnChangeExtension = (event) => {
        setExtension(event.target.value)
    }

    const handleOnChangePassExtension = (event) => {
        setPassExtension(event.target.value)
    }
    const handleOnChangeWorkgroups = (workgroup) => {
        console.log('Handle On Change Workgroup: ', workgroup)
        setWorkgroup(workgroup)
    }
    const handleOnChangeOutboundLines = (outboundLine) => {
        console.log('Handle On Change OutboundLines: ', outboundLine)
        setOutboundLine(outboundLine)
    }

    const handleOnChangeExtensionMoney = (event) => {
        let extensionMoney = event.target.value;
        console.log('Handle on change extensionMoney ', extensionMoney)
        setExtensionMoney(extensionMoney)
    }

    const handleOnChangeCurrentMoney = (event) => {
        let currentMoney = event.target.value;
        console.log('Handle on change currentMoney ', currentMoney)
        setCurrentMoney(currentMoney)
    }

    const handleOnChangeAddMoney = (event) => {
        let money = event.target.value;
        console.log('Handle on change Add money11 ', money)
        console.log('Handle on change Add money22 ', currentMoney)
        setMoney(money)
    }

    const handleOnChangeResetMoney = (event) => {
        console.log('Handle on change Reset money33 ', event)
        let money = event.target.value;
        console.log('Handle on change Reset money44 ', money)
        setMoney(money)
    }

    const handleOnChangeNotes = (event) => {
        let notes = event.target.value;
        console.log('Handle on change notes ', notes)
        setNotes(notes)
    }

    const handleOnChangeNewPassword = (event) => {
        let newPassword = event.target.value;
        console.log('Handle on change newPassword ', newPassword)
        // setNotes(notes)
    }

    const handleOnChangeRetypePassword = (event) => {
        let retypePassword = event.target.value;
        console.log('Handle on change retypePassword ', retypePassword)
        // setNotes(notes)
    }


    return (
        <div>

            {/* Modal reset mật khẩu số nội bộ */}
            <Modal show={showResetPassword.show} onHide={handleCloseModalResetPassword}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        <span style={{ fontSize: '20px', color: 'red' }}>
                            Reset mật khẩu số nội bộ: {showResetPassword.extensionId}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-2" controlId="ResetPasswordForm.Extension">
                            <Form.Label>Số nội bộ</Form.Label>
                            <Form.Control
                                type="text"
                                value={showResetPassword.extensionId}
                                // value={extensionTopUp}
                                // onChange={(event) => { handleOnChangeExtensionMoney(event) }}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="ResetPasswordForm.newPassword"
                        >
                            <Form.Label>Mật khẩu mới</Form.Label>
                            <Form.Control
                                type="text"
                                value={showTopUpBilling.currentMoney}
                                onChange={(event) => { handleOnChangeNewPassword(event) }}
                                placeholder="Mật khẩu mới"
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="ResetPasswordForm.retypePassword"
                        >
                            <Form.Label>Nhập lại mật khẩu</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={(event) => { handleOnChangeRetypePassword(event) }}
                                placeholder="Nhập lại mật khẩu mới"
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseModalResetPassword}>
                        Đóng
                    </Button>
                    <Button variant="success">
                        <span style={{ cursor: 'pointer' }}
                            onClick={() => { HandleSaveNewPassword() }}>
                            <i className='fas fa-save'></i>&nbsp;&nbsp;Cập nhật mật khẩu mới
                        </span>
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal cộng tiền cước cho số nội bộ */}
            <Modal show={showTopUpBilling.show} onHide={handleCloseModalTopUp}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {(showTopUpBilling.reset == false) ? (
                            <span style={{ fontSize: '20px', color: 'red' }}>
                                Cộng cước gọi ra cho số nội bộ: {showTopUpBilling.extensionId}
                            </span>
                        ) : (
                            <span style={{ color: 'red' }}>
                                Reset cước cho số nội bộ: {showTopUpBilling.extensionId}
                            </span>
                        )}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-2" controlId="topUpForm.topUpExtension">
                            <Form.Label>Số nội bộ</Form.Label>
                            <Form.Control
                                type="text"
                                // value={showTopUpBilling.extensionId}
                                value={extensionTopUp}
                                onChange={(event) => { handleOnChangeExtensionMoney(event) }}
                                autoFocus
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="topUpForm.money"
                        >
                            <Form.Label>Tiền cước hiện tại</Form.Label>
                            <Form.Control
                                type="text"
                                value={showTopUpBilling.currentMoney}
                                onChange={(event) => { handleOnChangeCurrentMoney(event) }}
                                placeholder="Số tiền hiện tại"
                                autoFocus
                            />
                        </Form.Group>
                        {(showTopUpBilling.reset == false) ? (
                            // Cộng thêm cước
                            <Form.Group
                                className="mb-3"
                                controlId="topUpForm.moneyAdd"
                            >
                                <Form.Label>Tiền cước cộng thêm</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(event) => { handleOnChangeAddMoney(event) }}
                                    placeholder="Nhập vào số tiền cộng thêm"
                                    autoFocus
                                />
                            </Form.Group>) : (
                            // Số tiền cước sẽ reset
                            <Form.Group
                                className="mb-3"
                                controlId="topUpForm.moneyReset"
                            >
                                <Form.Label>Tiền cước reset</Form.Label>
                                <Form.Control
                                    type="text"
                                    onChange={(event) => { handleOnChangeResetMoney(event) }}
                                    placeholder="Nhập vào số tiền reset"
                                    autoFocus
                                />
                            </Form.Group>
                        )
                        }
                        <Form.Group
                            className="mb-1"
                            controlId="topUpForm.notes"
                        >
                            <Form.Label>Ghi chú</Form.Label>
                            <Form.Control
                                type="text"
                                onChange={(event) => { handleOnChangeNotes(event) }}
                                placeholder="Nhập vào ghi chú"
                                autoFocus
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseModalTopUp}>
                        Đóng
                    </Button>
                    <Button variant="success">
                        <span style={{ cursor: 'pointer' }}
                            onClick={() => { HandleSaveNewTopUp() }}>
                            <i className='fas fa-save'></i>&nbsp;&nbsp;Cập nhật cước
                        </span>
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Cảnh báo xóa số nội bộ */}
            <Modal show={popup.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cảnh báo xóa số nội bộ</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc muốn xóa số nội bộ này? {popup.id}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteYes}>
                        Có
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Không
                    </Button>
                </Modal.Footer>
            </Modal>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-3">
                            <div className="card card-success">
                                <div className="card-header">
                                    <h3 className="card-title sm">Thêm mới số nội bộ</h3>
                                </div>
                                <form>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="extension">Số nội bộ</label>
                                            <input type="text" className="form-control" id="extension"
                                                value={extension}
                                                onChange={(event) => { handleOnChangeExtension(event) }}
                                                placeholder="Nhập vào số nội bộ" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="password">Mật khẩu</label>
                                            <input type="password" className="form-control" id="passExtension"
                                                value={passExtension}
                                                onChange={(event) => { handleOnChangePassExtension(event) }}
                                                placeholder="Mật khẩu" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="workgroup">Tên nhóm</label>
                                            <Select
                                                value={workgroup}
                                                onChange={(event) => { handleOnChangeWorkgroups(event) }}
                                                options={workgroups}
                                                getOptionValue={(option) => option.value}
                                                getOptionLabel={(option) => option.label}
                                                placeholder='Chọn nhóm cho tài khoản'
                                                noOptionsMessage={() => "Không còn nhóm"}
                                                isMulti />
                                        </div>
                                        <div>
                                            <label htmlFor="workgroup">Nhóm Line gọi ra</label>
                                            <Select
                                                value={outboundLine}
                                                onChange={(event) => { handleOnChangeOutboundLines(event) }}
                                                options={outboundLines}
                                                getOptionValue={(option) => option.value}
                                                getOptionLabel={(option) => option.label}
                                                placeholder='Nhóm line được quyền gọi ra'
                                                noOptionsMessage={() => "Không còn nhóm line"}
                                                isMulti />
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="button" onClick={() => { HandleAddNewExtension() }} className="btn btn-success">
                                            <i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Danh sách số nội bộ</h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            {(loadingData === '') ? (
                                                <p>Loading Please wait...</p>
                                            ) : (
                                                <TableExtension columns={columns} data={data} />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div >
    )
}

export default Extensions