export const addNewExtension = (extension) => {
    return {
        type: 'ADD_EXTENSION',
        payload: extension,
    }
}

export const delLastExtension = (extension) => {
    return {
        type: 'DEL_EXTENSION',
        payload: extension,
    }
}

// export const getListExtension = (extension) => {
//     return {
//         type: 'LIST_EXTENSION',
//         payload: extension,
//     }
// }



