import hobbyReducer from "./hobby";
import userReducer from "./user";
import extensionReducer from "./extension";
import { combineReducers } from "redux";

const rootReducer = combineReducers({
    hobby: hobbyReducer,
    user: userReducer,
    extension: extensionReducer,
})

export default rootReducer

