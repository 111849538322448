import React, { useState } from 'react'
import moment from 'moment';

const Summary = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    const [tongCuocGoi, setTongCuocGoi] = useState('')
    const [tongCuocGoiVao, setTongCuocGoiVao] = useState('')
    const [tongCuocGoiRa, setTongCuocGoiRa] = useState('')
    const [tongGoiVaoNgheMay, setTongGoiVaoNgheMay] = useState('')
    const [tongGoiRaNgheMay, setTongGoiRaNgheMay] = useState('')
    const [tongExtension, setTongExtension] = useState('')
    const [tongExtensionAnswer, setTongExtensionAnswer] = useState('')
    const [dataQueue, setDataQueue] = useState([])
    const [dataExtension, setDataExtension] = useState([])
    const [dataAgent, setDataAgent] = useState([])


    const handleToday = () => {
        let Today = moment().subtract(0, 'days').startOf('day').format("MM/DD/YYYY");
        console.log("Hôm nay: ", Today)
    }
    const handleYesterday = () => {
        let Yesterday = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY");
        console.log("Hôm nay: ", Yesterday)
        setTongCuocGoi('1000');
        setTongCuocGoiVao('800');
        setTongCuocGoiRa('200');
        setTongGoiVaoNgheMay('400');
        setTongGoiRaNgheMay('100');
        setTongExtension('20')
        setTongExtensionAnswer('15')
        const dataQue = [
            { 'queueName': 'kythuat', 'answered': '200' },
            { 'queueName': 'KinhDoanh', 'answered': '210' },
            { 'queueName': 'Tuyen dung', 'answered': '220' },
            { 'queueName': 'nhan su', 'answered': '230' },
        ]
        const dataEx = [
            { 'extension': '8012', 'answered': '200' },
            { 'extension': '8013', 'answered': '210' },
            { 'extension': '8014', 'answered': '220' },
            { 'extension': '8015', 'answered': '230' },
        ]
        const dataAg = [
            { 'agentId': '8012', 'answered': '200' },
            { 'agentId': '8013', 'answered': '210' },
            { 'agentId': '8014', 'answered': '220' },
            { 'agentId': '8015', 'answered': '230' },
        ]
        setDataQueue(dataQue)
        setDataExtension(dataEx)
        setDataAgent(dataAg)
    }
    const handleLast7Days = () => {
        let Last7Days = moment().subtract(6, 'days').startOf('day').format("MM/DD/YYYY");
        console.log("7 ngày trước: ", Last7Days)
        setTongCuocGoi('7200');
        setTongCuocGoiVao('6400');
        setTongCuocGoiRa('800');
        setTongGoiVaoNgheMay('5400');
        setTongGoiRaNgheMay('700');
        setTongExtension('22')
        setTongExtensionAnswer('17')
        const dataEx = [
            { 'extension': '8012', 'answered': '2100' },
            { 'extension': '8013', 'answered': '1800' },
            { 'extension': '8014', 'answered': '1900' },
            { 'extension': '8015', 'answered': '1000' },
        ]
        setDataExtension(dataEx)
    }
    const handleLast30Days = () => {
        let Last30Days = moment().subtract(29, 'days').startOf('day').format("MM/DD/YYYY");
        console.log("30 ngày trước: ", Last30Days)
        setTongCuocGoi('29000');
        setTongCuocGoiVao('24400');
        setTongCuocGoiRa('4600');
        setTongGoiVaoNgheMay('22000');
        setTongGoiRaNgheMay('3700');
        setTongExtension('24')
        setTongExtensionAnswer('19')
        const dataEx = [
            { 'extension': '8012', 'answered': '7100' },
            { 'extension': '8013', 'answered': '5800' },
            { 'extension': '8014', 'answered': '5900' },
            { 'extension': '8015', 'answered': '5000' },
            { 'extension': '8016', 'answered': '3000' },
        ]
        setDataExtension(dataEx)
    }
    const handleThisMonth = () => {
        // let get24Time = moment(getTime, ["h:mm A"]).format("HH:mm");
        // let Today = moment().toDate().format('MM/DD/YYYY')
        let ThisMonth = moment().startOf('month').format("MM/DD/YYYY") + " - " + moment().endOf('month').format("MM/DD/YYYY");
        console.log('Tháng này: ', ThisMonth)
        setTongCuocGoi('22000');
        setTongCuocGoiVao('20400');
        setTongCuocGoiRa('1600');
        setTongGoiVaoNgheMay('19000');
        setTongGoiRaNgheMay('1200');
        setTongExtension('24')
        setTongExtensionAnswer('19')
    }
    return (
        <div>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-7">
                                <ol className="breadcrumb float-sm-left">
                                    {/* <li className="breadcrumb-item"><h1>Báo cáo tổng hợp</h1></li> */}
                                    <li>
                                        &nbsp;&nbsp;<button className='btn btn-success btn-sm' onClick={() => { handleToday() }}>Hôm nay</button>
                                    </li>
                                    <li>
                                        &nbsp;&nbsp;<button className='btn btn-success btn-sm' onClick={() => { handleYesterday() }}>Hôm qua</button>
                                    </li>
                                    <li>
                                        &nbsp;&nbsp;<button className='btn btn-success btn-sm' onClick={() => { handleLast7Days() }}>7 ngày trước</button>
                                    </li>
                                    <li>
                                        &nbsp;&nbsp;<button className='btn btn-success btn-sm' onClick={() => { handleLast30Days() }}>30 ngày trước</button>
                                    </li>
                                    <li>
                                        &nbsp;&nbsp;<button className='btn btn-success btn-sm' onClick={() => { handleThisMonth() }}>Tháng này</button>
                                    </li>
                                </ol>
                            </div>
                            <div className="col-sm-5">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/index.html">Home</a></li>
                                    <li className="breadcrumb-item active">Báo cáo tổng hợp</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Info boxes */}
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                    <span className="info-box-icon bg-info elevation-1"><i className="fas fa-phone" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Cuộc gọi</span>
                                        <span className="info-box-number">
                                            {tongCuocGoi}
                                            <small></small>
                                        </span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            {/* /.col */}
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-danger elevation-1"><i className="fas fa-arrow-down" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text" style={{ width: '100px' }}>Gọi vào</span>
                                        <span className="info-box-text" style={{ width: '100px' }}>Nghe máy</span>
                                    </div>
                                    <div className="info-box-content">
                                        <span className="info-box-number" style={{ marginLeft: '30px' }}>{tongCuocGoiVao}</span>
                                        <span className="info-box-number" style={{ marginLeft: '30px' }}>{tongGoiVaoNgheMay}</span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            {/* /.col */}
                            <div className="clearfix hidden-md-up" />
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-success elevation-1"><i className="fas fa-arrow-up" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text" style={{ width: '100px' }}>Gọi ra</span>
                                        <span className="info-box-text" style={{ width: '100px' }}>Nghe máy</span>
                                    </div>
                                    <div className="info-box-content">
                                        <span className="info-box-number" style={{ marginLeft: '30px' }}>{tongCuocGoiRa}</span>
                                        <span className="info-box-number" style={{ marginLeft: '30px' }}>{tongGoiRaNgheMay}</span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            {/* /.col */}
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-warning elevation-2">
                                        <i className="fas fa-users" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Nhân viên</span>
                                        <span className="info-box-text">Làm việc</span>
                                    </div>
                                    <div className="info-box-content">
                                        <span className="info-box-number" style={{ marginLeft: '30px' }}>{tongExtension}</span>
                                        <span className="info-box-number" style={{ marginLeft: '30px' }}>{tongExtensionAnswer}</span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            {/* /.col */}
                        </div>

                        {/* /.row */}
                        <div className="row">
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title">Nhóm</h5>
                                        {/* Nút bấm thu nhỏ và đóng */}
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                        {/* End nút bấm thu nhỏ và đóng */}
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row">
                                            {/* /.col */}
                                            <div className="col-md-12">
                                                <p className="text-center">
                                                    <strong>Thống kê cuộc gọi trong nhóm</strong>
                                                </p>
                                                <div className="progress-group">
                                                    Kỹ thuật
                                                    <span className="float-right"><b>1000</b></span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-primary" style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                                <div className="progress-group">
                                                    Kinh Doanh
                                                    <span className="float-right"><b>510</b>/1000</span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-danger" style={{ width: '51%' }} />
                                                    </div>
                                                </div>
                                                <div className="progress-group">
                                                    <span className="progress-text">Tuyển dụng</span>
                                                    <span className="float-right"><b>490</b>/1000</span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-success" style={{ width: '49%' }} />
                                                    </div>
                                                </div>
                                                <div className="progress-group">
                                                    Nhân sự
                                                    <span className="float-right"><b>250</b></span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-warning" style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                                <div className="progress-group">
                                                    Gọi ra
                                                    <span className="float-right"><b>120</b></span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-success" style={{ width: '50%' }} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title">Nhân viên</h5>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row">
                                            {/* /.col */}
                                            <div className="col-md-12">
                                                <p className="text-center">
                                                    <strong>Thống kê nhân viên</strong>
                                                </p>
                                                <div className="progress-group">
                                                    toandd
                                                    <span className="float-right"><b>1000</b></span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-primary" style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                                {/* /.progress-group */}
                                                <div className="progress-group">
                                                    hanp
                                                    <span className="float-right"><b>510</b>/1000</span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-danger" style={{ width: '51%' }} />
                                                    </div>
                                                </div>
                                                {/* /.progress-group */}
                                                <div className="progress-group">
                                                    <span className="progress-text">tuannm</span>
                                                    <span className="float-right"><b>490</b>/1000</span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-success" style={{ width: '49%' }} />
                                                    </div>
                                                </div>
                                                {/* /.progress-group */}
                                                <div className="progress-group">
                                                    hoangdx
                                                    <span className="float-right"><b>250</b></span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-warning" style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                                <div className="progress-group">
                                                    tiennd
                                                    <span className="float-right"><b>220</b></span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-success" style={{ width: '90%' }} />
                                                    </div>
                                                </div>
                                                <div className="progress-group">
                                                    datpn
                                                    <span className="float-right"><b>170</b></span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-warning" style={{ width: '70%' }} />
                                                    </div>
                                                </div>
                                                {/* /.progress-group */}
                                            </div>
                                            {/* /.col */}
                                        </div>
                                        {/* /.row */}
                                    </div>
                                </div>
                                {/* /.card */}
                            </div>
                            <div className="col-md-4">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title">Số nội bộ</h5>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row">
                                            {/* /.col */}
                                            <div className="col-md-12">
                                                <p className="text-center">
                                                    <strong>Thống kê theo số nội bộ</strong>
                                                </p>
                                                {
                                                    dataExtension.map((item, i) =>
                                                        <div className="progress-group">
                                                            {item.extension}
                                                            <span className="float-right"><b>{item.answered}/{tongCuocGoi}</b></span>
                                                            <div className="progress progress-sm">
                                                                <div className="progress-bar bg-success" style={{ width: ((item.answered / tongCuocGoi) * 100) + '%' }} />
                                                            </div>
                                                        </div>)
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>{/*/. container-fluid */}
                </section>
                {/* /.content */}
            </div >
        </div >
    )
}

export default Summary