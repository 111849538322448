import React from 'react'
import SuperAgents from './SuperAgents'
import SuperCalls from './SuperCalls'
import SuperQueues from './SuperQueues'
import SuperWorkgroups from './SuperWorkgroups'
import SuperExtensions from './SuperExtensions'
// import { useDispatch } from 'react-redux'
// import { addNewHobby } from '../Actions/hobby'

// const randomNumber = () => {
//     return 1000 * Math.trunc((Math.random() * 9000));
// }
const Supervisor = () => {
    // const dispatch = useDispatch();

    // const handleAddHobbyClick = () => {
    //     const newId = randomNumber()
    //     const newHobby = {
    //         id: newId,
    //         title: `Hobby ${newId}`,
    //         phone: '0909092323',
    //     }
    //     console.log('Add new hobby: ', typeof (newHobby))
    //     //dispatch action to add a new hobby to redux store
    //     const action = addNewHobby(newHobby)
    //     dispatch(action)
    // }

    return (
        <div>
            <div className="content-wrapper">
                <section style={{ height: '15px' }}>
                </section>
                {/*  */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12 col-sm-12">
                                <div className="card card-success card-tabs">
                                    <div className="card-header p-0 pt-1">
                                        <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                                            <li className="pt-2 px-3"><h3 className="card-title">Giám sát</h3></li>
                                            {(localStorage.getItem('role').includes('SUP_CALL')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="custom-tabs-first-call-tab" data-toggle="pill"
                                                        href="#custom-tabs-first-call"
                                                        role="tab" aria-controls="custom-tabs-first-call" aria-selected="true">
                                                        <i className='fas fa-phone-alt'></i>&nbsp;&nbsp;Cuộc gọi</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('SUP_STAFF')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-second-member-tab" data-toggle="pill"
                                                        href="#custom-tabs-second-member"
                                                        role="tab" aria-controls="custom-tabs-second-member" aria-selected="false">
                                                        <i className='fas fa-user-cog'></i>&nbsp;&nbsp;Nhân viên</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('SUP_QUEUES')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-third-queue-tab" data-toggle="pill"
                                                        href="#custom-tabs-third-queue"
                                                        role="tab" aria-controls="custom-tabs-third-queue" aria-selected="false">
                                                        <i className='far fa-snowflake'></i>&nbsp;&nbsp;Queues</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('SUP_GROUP_VIEW')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-fourth-workgroup-tab" data-toggle="pill"
                                                        href="#custom-tabs-fourth-workgroup"
                                                        role="tab" aria-controls="custom-tabs-fourth-workgroup" aria-selected="false">
                                                        <i className='fas fa-users'></i>&nbsp;&nbsp;Nhóm tài khoản</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('SUP_EXT_STATUS')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-fifth-extension-tab" data-toggle="pill"
                                                        href="#custom-tabs-fifth-extension"
                                                        role="tab" aria-controls="custom-tabs-fifth-extension" aria-selected="false">
                                                        <i className='fas fa-phone-square'></i>&nbsp;&nbsp;Số nội bộ</a>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content" id="custom-tabs-two-tabContent">
                                            <div className="tab-pane fade show active" id="custom-tabs-first-call"
                                                role="tabpanel" aria-labelledby="custom-tabs-first-call-tab">
                                                <SuperCalls />
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-second-member"
                                                role="tabpanel" aria-labelledby="custom-tabs-second-member-tab">
                                                <SuperAgents />
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-third-queue"
                                                role="tabpanel" aria-labelledby="custom-tabs-third-queue-tab">
                                                <SuperQueues />
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-fourth-workgroup"
                                                role="tabpanel" aria-labelledby="custom-tabs-fourth-workgroup-tab">
                                                <SuperWorkgroups />
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-fifth-extension"
                                                role="tabpanel" aria-labelledby="custom-tabs-fifth-extension-tab">
                                                <SuperExtensions />
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Supervisor