import React from 'react'

const SuperWorkgroups = () => {
    const handleWorkgroupShow = () => {
        alert('get list workgroup')
    }
    return (
        <div>
            <div className="row mb-1" style={{ padding: '1px' }}>
                <div className="col-sm-6" style={{ padding: '1px' }}>
                    <h5>Giám sát nhóm tài khoản</h5>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right" style={{ padding: '1px', backgroundColor: 'white' }}>
                        <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                        <li className="breadcrumb-item active">Giám sát nhóm tài khoản</li>
                    </ol>
                </div>
            </div>
        </div >
    )
}

export default SuperWorkgroups