import React, { useEffect, useState, useMemo } from 'react';
import TableAccount from './TableAccount';
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DefaultColumnFilter } from '../Common/extensionData';
import { signOut } from '../Utils';


const Accounts = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [isEnableButton, setIsEnableButton] = useState(true);
    // const [status, setStatus] = useState('');
    const [updateAccount, setUpdateAccount] = useState(false);
    const [username, setUsername] = useState('');
    const [fullname, setFullname] = useState('');
    const [extension, setExtension] = useState('');
    const [workgroup, setWorkgroup] = useState([]);
    const [workgroups, setWorkgroups] = useState([]);
    const [queue, setQueue] = useState([]);
    const [queues, setQueues] = useState([]);
    const [passAccount, setPassAccount] = useState('');
    const [roleId, setRoleId] = useState('agent');
    const [roles, setRoles] = useState([]);
    const [popup, setPopup] = useState({
        show: false,
        id: null,
    });


    const handleClose = () => {
        setPopup(
            {
                show: false,
                id: null,
            });
    };
    const handleShow = (row) => {
        setPopup({ show: true, id: row['row'].original.username });
        console.log('Show account11: ', row['row'].original.username)
        console.log('Show account22: ', row)
    }
    const handleEdit = (row) => {
        // setPopup({ show: true, id: row['row'].original.username });
        let vaitro = row['row'].original.roleId;
        setUpdateAccount(true)
        setUsername(row['row'].original.username)
        setFullname(row['row'].original.fullname)
        setExtension(row['row'].original.extension)
        setRoleId(vaitro)
        // (username) ? setIsEnableButton(false) : setIsEnableButton(true);
        // console.log('Edit account: ', row['row'].original.username, row['row'].original.roleId)
    }
    const handleDeleteYes = async () => {
        const token = localStorage.getItem('accessToken');
        // const xoa = async () => {
        if (popup.show && popup.id) {
            console.log('Da xoa: ', popup.id)
            setPopup({
                show: false,
                id: null,
            });
            await axios.delete(`${URL_API}/account/deleteAccount/${popup.id}`, {
                headers: {
                    'x-access-token': token
                }
            });
            setTimeout(() => {
                toast.success('Đã xóa account thành công ' + popup.id);
                // setStatus('Đã xóa thành côg Account: ' + popup.id);
                setLoadingData(true)
            }, 500);
        }
    }
    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                Cell: ({ row }) => (
                    <><h6>{row.index + 1}</h6></>
                ),
            },
            {
                Header: 'Tài khoản',
                accessor: 'username',
                Filter: DefaultColumnFilter,
            },
            {
                Header: 'Mật khẩu',
                accessor: 'password',
                Filter: false,
                Cell: ({ row, id }) =>
                    <>
                        **************
                    </>,
            },
            {
                Header: 'Họ và tên',
                accessor: 'fullname',
            },
            {
                Header: 'Số nội bộ',
                accessor: 'extension',
            },
            {
                Header: 'Vai trò',
                accessor: 'roleId',
            },
            {
                Header: 'Action',
                accessor: 'action',
                Filter: false,
                Cell: ({ row, id }) =>
                    <>{row.header}
                        <span style={{ cursor: 'pointer', color: 'blue' }}
                            onClick={() => { handleEdit({ row }) }}>
                            <i className='fas fa-edit' title='Sửa đổi tài khoản'></i>
                        </span> &nbsp;
                        <span style={{ cursor: 'pointer', color: 'red' }}
                            onClick={() => { handleShow({ row }) }}>
                            <i className='fas fa-trash-alt' title='Xóa tài khoản'></i>
                        </span>
                    </>,
            },
        ]);
    // Lấy danh sách queueName
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getQueueNames() {
            const result = await axios.get(`${URL_API}/queue/getAllQueueName`, {
                headers: {
                    'x-access-token': token
                }
            });
            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i]['label'] = result.data[i]['name'];
                result.data[i].value = result.data[i].name;
                delete result.data[i]['name'];
            }
            setLoadingData(false)
            setQueues(result.data);
            console.log('Accounts.js - get result queueName: ', result.data)
        };
        getQueueNames();
    }, [loadingData]);
    // Lấy danh sách workgroups
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getWorkgroupNames() {
            const result = await axios.get(`${URL_API}/workgroup/listWorkgroup`, {
                headers: {
                    'x-access-token': token
                }
            });
            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i].label = result.data[i]['workgroupName'];
                result.data[i].value = result.data[i]['workgroupCode'];
                delete result.data[i]['workgroupCode'];
                delete result.data[i]['workgroupName'];
            }
            setLoadingData(false)
            setWorkgroups(result.data);
            console.log('Accounts.js - get result workgroupCode: ', result.data)
        };
        getWorkgroupNames();
    }, [loadingData]);

    // Lấy danh sách tất cả accounts
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        try {
            async function getAccounts() {
                const result = await axios.get(`${URL_API}/account/getAccounts`, {
                    headers: {
                        'x-access-token': token
                    }
                });
                setLoadingData(false);
                setData(result.data);
            };
            getAccounts();
        } catch (err) {
            console.log('Get error: ', err)
            console.log('Get error response: ', err.response)
            console.log('Get error response.data: ', err.response.data)
            console.log('Get error response.data.errMsg: ', err.response.data.errMsg)
            if (err.response.data.errMsg === 'jwt expired') {
                console.log('Token was expired')
                signOut();
            } else {
                console.log('Recordings.js Error in 266: ', err)
            }
        }
    }, [loadingData]);
    //  Lấy danh sách tất cả Roles
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        async function getRoles() {
            const result = await axios.get(`${URL_API}/role/getAllRoles`, {
                headers: {
                    'x-access-token': token
                }
            });
            setRoles(result.data);
            setLoadingData(false);
            console.log('refresh No API')
        };
        getRoles();
        // }
    }, [loadingData]);


    const handleOnChangeUserName = (event) => {
        setUsername(event.target.value)
        setIsEnableButton(false);
    }
    const handleOnChangePassword = (event) => {
        // console.log('handleOnChangePassword: ', event.target.value)
        setPassAccount(event.target.value)
    }
    const handleOnChangeFullName = (event) => {
        setFullname(event.target.value)
    }
    const handleOnChangeExtension = (event) => {
        setExtension(event.target.value)
    }
    const handleOnChangeWorkgroups = (workgroup) => {
        console.log('Handle On Change Workgroup: ', workgroup)
        setWorkgroup(workgroup)
    }
    const handleOnChangeQueues = (queue) => {
        console.log('Get queue value: ', queue)
        setQueue(queue);
    }
    const handleOnChangeRole = (event) => {
        // console.log('RoleId: ', event.target.value)
        setRoleId(event.target.value);
    }
    // Add new Account
    const HandleAddNewAccount = async () => {
        const token = localStorage.getItem('accessToken');
        let maloi = '';
        if ((username !== '') && (passAccount !== '')) {
            let queueName = '';
            if (queue.length > 1) {
                for (let j = 0; j < queue.length; j++) {
                    queueName = queueName + ',' + queue[j].value;
                }
            } else {
                queueName = queue[0].value;
                // console.log('agent000: ', agent[0].value)
            }
            queueName = queueName.slice(1);  // lay tat ca gia tri tu vi tri 1 -> cuoi
            console.log('Queues queueName: ', queueName)
            console.log('Queues Split 1: ', queueName.split(','))
            console.log('Queues Split 2: ', queueName.split(',').length)

            // POST request using axios inside useEffect React hook
            let account = { username: username, password: passAccount, fullname: fullname, extension: extension, queueName: queueName, roleId: roleId }
            let tralai = await axios.post(`${URL_API}/account/saveAccount`, account, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    console.log(response);
                    console.log(response.data.errCode)
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(error);
                });

            setTimeout(() => {
                if (maloi === 0) {
                    toast.success('Thêm mới tài khoản thành công ' + username);
                } else {
                    toast.warning('Thêm mới tài khoản không thành công, tài khoản đã tồn tại ' + username);
                }
                setLoadingData(true)
                setUsername('')
            }, 50);


        } else {
            alert("Username hoặc mật khẩu không được để trống!")
        }
    }
    const HandleUpdateAccount = () => {
        const token = localStorage.getItem('accessToken');
        // POST request using axios inside useEffect React hook
        let updateAccount = { username: username, password: passAccount, fullname: fullname, extension: extension, roleId: roleId }
        axios.post(`${URL_API}/account/updateAccount/${username}`, updateAccount, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });

        setTimeout(() => {
            toast.success('Cập nhật Account thành công ' + username);
            // setStatus('Cập nhật thành công Account: ' + username);
            setLoadingData(true)
            setUsername('')
            setFullname('')
            setExtension('')
        }, 1000);
    }

    const handleAddNew = () => {
        setUpdateAccount(false)
        setUsername('')
        setPassAccount('')
        setFullname('')
        setExtension('')
        setIsEnableButton(true)
    }
    return (
        <div>
            <Modal show={popup.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cảnh báo xóa tài khoản</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc muốn xóa tài khoản này? {popup.id}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteYes}>
                        Có
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Không
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Section 2 */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-3">
                            {/* general form elements */}
                            <div className="card card-success">
                                <div className="card-header">
                                    <h3 className="card-title sm">
                                        {(updateAccount === false) ? (
                                            <span><i className='fas fa-user-plus'></i>&nbsp;&nbsp;Thêm mới tài khoản</span>
                                        ) : (
                                            <span>Cập nhật tài khoản</span>
                                        )}
                                    </h3>
                                </div>
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body" style={{ padding: '10px' }}>
                                        <div>
                                            <label htmlFor="userName">Tài khoản</label>
                                            <input type="text" disabled={updateAccount}
                                                onChange={(event) => { handleOnChangeUserName(event) }} value={username}
                                                className="form-control form-control-sm is-valid" id="username"
                                                placeholder="Nhập vào tài khoản" required />
                                        </div>
                                        <div>
                                            <label htmlFor="password">Mật khẩu</label>
                                            <input type="password" onChange={(event) => { handleOnChangePassword(event) }}
                                                value={passAccount} className="form-control form-control-sm is-valid" id="passAccount"
                                                placeholder="Mật khẩu" />
                                        </div>
                                        <div>
                                            <label htmlFor="fullName">Họ và tên</label>
                                            <input type="text" onChange={(event) => { handleOnChangeFullName(event) }} value={fullname} className="form-control form-control-sm is-valid" id="fullname" placeholder="Nhập vào họ và tên" />
                                        </div>
                                        <div>
                                            <label htmlFor="extension">Số nội bộ</label>
                                            <input type="text" onChange={(event) => { handleOnChangeExtension(event) }} value={extension} className="form-control form-control-sm is-valid" id="extension" placeholder="Số nội bộ" />
                                        </div>
                                        <div>
                                            <label htmlFor="workgroup">Tên nhóm</label>
                                            <Select
                                                value={workgroup}
                                                onChange={(event) => { handleOnChangeWorkgroups(event) }}
                                                options={workgroups}
                                                getOptionValue={(option) => option.value}
                                                getOptionLabel={(option) => option.label}
                                                placeholder='Chọn nhóm cho tài khoản'
                                                noOptionsMessage={() => "Không còn nhóm"}
                                                isMulti />
                                        </div>
                                        <div>
                                            <label htmlFor="queues">Queues</label>
                                            <Select
                                                value={queue}
                                                onChange={(event) => { handleOnChangeQueues(event) }}
                                                options={queues}
                                                getOptionValue={(option) => option.value}
                                                getOptionLabel={(option) => option.value}
                                                placeholder='Chọn queues cho tài khoản'
                                                noOptionsMessage={() => "Không còn queues"}
                                                isMulti />
                                        </div>
                                        <div>
                                            <label htmlFor="role">Vai trò</label>
                                            <select defaultValue={roleId} value={roleId} onChange={(event) => { handleOnChangeRole(event) }} className="form-control form-control-sm">
                                                {/* <option value='agent'>Nhân viên</option>
                                                <option value='supervisor'>Giám sát</option>
                                                <option value='reporting'>Báo cáo</option>
                                                <option value='recording'>Ghi âm</option>
                                                <option value='billing'>Xem cước</option>
                                                <option value='admin'>Quản trị</option> */}
                                                {
                                                    roles.map((item, i) => <option key={i} value={item.role_id}>{item.role_name}</option>)
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="button" onClick={() => {
                                            (updateAccount === false) ? HandleAddNewAccount() : HandleUpdateAccount()
                                        }}
                                            className="btn btn-success">
                                            {(updateAccount === false) ? (
                                                <span><i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</span>
                                            ) : (
                                                <span><i className='fas fa-sync'></i>&nbsp;&nbsp;Cập nhật</span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (left) */}
                        {/* right column */}

                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"><b>Danh sách tài khoản</b></h3>
                                    <div className="card-tools">
                                        <a href='#top' onClick={() => { handleAddNew() }}>
                                            {(updateAccount === true) ? (
                                                <span style={{ color: 'green' }}>
                                                    <i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </a>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <TableAccount columns={columns} data={data} />
                                        </div>
                                    </div>
                                    {/* /.row */}
                                </div>
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div >
    )
}

export default Accounts