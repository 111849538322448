import React, { useState, useMemo, useEffect } from 'react'
import TableSuperAgents from './TableSuperAgents';
import axios from 'axios';
// import { useDispatch, useSelector } from 'react-redux'
// import { delLastHobby } from '../Actions/hobby'


const SuperAgents = () => {

    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API

    const [agents, setAgents] = useState([]);

    // const hobbyList = useSelector(state => state.hobby.list)
    // const dispatch = useDispatch();
    // const handleDelHobbyClick = () => {
    //     alert('get new hobby')
    //     //dispatch action to add a new hobby to redux store
    //     const action = delLastHobby()
    //     dispatch(action)
    // }

    // Lay danh sach tat ca Extension khi nạp trang
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // console.log('Get extension list: ', extensions)
        async function getAllAgents() {
            const result1 = await axios.get(`${URL_API}/online/getOnlineAgentsList`, {
                headers: {
                    'x-access-token': token
                }
            });
            console.log('Lay danh sach agents qua API. useEffect 1: ', result1)
            console.log('Agents refresh API. useEffect 1 1: ', result1.data)
            setAgents(result1.data);
        };
        getAllAgents();
        // console.log('Show data 1: ', agents)
    }, []);
    const handleChat = () => {
        alert('Chatting ...')
    }
    const handleCall = () => {
        alert('Calling ...')
    }
    const headerData = [
        {
            Header: 'STT',
            accessor: 'id',
            Filter: false,
            Cell: ({ row }) => (
                <>
                    <span>{row.index + 1}</span>
                </>
            ),
        },
        {
            Header: 'AgentId',
            accessor: 'userId',
        },
        {
            Header: 'Đăng nhập',
            accessor: 'isLogin',
        },
        {
            Header: 'Số nội bộ',
            accessor: 'extension',
        },
        {
            Header: 'Queues',
            // accessor: 'extension',
        },
        {
            Header: 'Nhóm tài khoản',
            // accessor: 'extension',
        },
        {
            Header: 'Action',
            Filter: false,
            // accessor: 'call_id',
            Cell: ({ row }) =>
                <>
                    <span style={{ cursor: 'pointer' }} onClick={() => handleChat()}><button><i className="far fa-comments" title='Gửi tin Chat' style={{ color: 'green' }} /></button></span>&nbsp;
                    <span style={{ cursor: 'pointer' }} onClick={() => handleCall()}><button><i className="fas fa-phone" title='Gọi trực tiếp' style={{ color: 'red' }} /></button></span>
                    {/* <span className='badge badge-success' style={{ cursor: 'pointer' }} onClick={() => handleCoach(row.original)}>nhắc</span>, */}
                    {/*<span style={{ cursor: 'pointer' }} onClick={() => handleConf(row.original)}>hội</span>
                    <span style={{ cursor: 'pointer' }} onClick={() => handleDisconnect(row.original)}>Ngắt</span> */}
                </>,
        },
    ]

    const columns = useMemo(
        () => headerData);

    return (
        <div>
            <div className="row mb-1" style={{ padding: '1px' }}>
                <div className="col-sm-6" style={{ padding: '1px' }}>
                    <h5>Giám sát nhân viên</h5>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right" style={{ padding: '1px', backgroundColor: 'white' }}>
                        <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                        <li className="breadcrumb-item active">Giám sát nhân viên</li>
                    </ol>
                </div>
            </div>
            <section className="content">
                <div className="row mb-2">
                    <div className="col-sm-12">
                        <TableSuperAgents columns={columns} data={agents} />
                    </div>
                </div>
            </section>

        </div >
    )
}

export default SuperAgents