import React, { useState, useEffect } from 'react'
import Select from 'react-select';
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TableQueue from './TableQueue'
import TableQueuePenalty from './TableQueuePenalty'

const Queues = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API

    const [selectQueue, setSelectQueue] = useState('');
    const [queuename, setQueuename] = useState('');
    const [displayTable, setDisplayTable] = useState(false);
    const [strategy, setStrategy] = useState('rrmemory');
    const [agent, setAgent] = useState([]);
    const [agents, setAgents] = useState([]);
    const [data, setData] = useState([]);
    const [dataPenalty, setDataPenalty] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [updateQueue, setUpdateQueue] = useState(false);
    const [popup, setPopup] = useState({
        show: false,
        queueName: null,
        deleteId: null,
    });

    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        // setSkipPageReset(true)
        setDataPenalty(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }
    // Create an editable cell renderer
    const EditableCell = ({
        value: initialValue,
        row: { index },
        column: { id },
        updateMyData, // This is a custom function that we supplied to our table instance
    }) => {
        // We need to keep and update the state of the cell normally
        const [value, setValue] = React.useState(initialValue)

        const onChange = e => {
            setValue(e.target.value)
        }

        // We'll only update the external data when the input is blurred
        const onBlur = () => {
            console.log('onBlur: ', value)
            updateMyData(index, id, value)
        }

        // If the initialValue is changed external, sync it up with our state
        React.useEffect(() => {
            setValue(initialValue)
        }, [initialValue])

        return <input style={{
            fontSize: '1rem',
            padding: '0',
            margin: '0',
            border: '0',
            width: '80px',
            textAlign: 'center',
            background: '#fffff',
        }
        } type='text' value={value} onChange={onChange} onBlur={onBlur} />
    }

    // Set our editable cell renderer as the default Cell renderer
    const defaultColumn = {
        Cell: EditableCell,
    }

    const handleToggle = () => {
        // alert('toggle: ')
    }

    const handleClose = () => {
        setPopup(
            {
                show: false,
                queueName: null,
                deleteId: null,
            });
    };
    // Lấy danh sách account username
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getUsernames() {
            const result = await axios.get(`${URL_API}/account/getAllAccountUsername`, {
                headers: {
                    'x-access-token': token
                }
            });

            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i]['label'] = result.data[i]['username'];
                result.data[i].value = result.data[i].username;
                delete result.data[i]['username'];
            }
            setLoadingData(false)
            setAgents(result.data);
            console.log('Queues - get result username in Queues1: ', result.data)
        };
        getUsernames();
    }, [loadingData]);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getInfoQueues() {
            const result = await axios.get(`${URL_API}/queue/getInfoQueues`, {
                headers: {
                    'x-access-token': token
                }
            });
            setData(result.data);
            setDisplayTable(false);
        };
        getInfoQueues();
        // }
    }, [agents]);

    const handleOnChangeQueueName = (e) => {
        console.log('get QueueName: ', e.target.value)
        setQueuename(e.target.value);
    }
    const handleChangeAgents = (agent) => {
        console.log('Get Agent value: ', agent)
        setAgent(agent);
    }
    const handleEdit = (row) => {
        console.log('row: ', row)
        let queueName = row['row'].original.queueName;
        setUpdateQueue(true)
        console.log('show queueName: ', queueName)
        console.log('show queueName: ', row.original.queueMember)
    }
    const handleOnChangeStrategy = (event) => {
        // console.log('RoleId: ', event.target.value)
        setStrategy(event.target.value);
    }
    const handleConfirm = (row) => {
        setPopup({ show: true, queueName: row['row'].original.queueName, deleteId: row['row'].index });
        console.log('Show queue11: ', row['row'].original.queueName)
        console.log('Show queue22: ', row)
        console.log('Show queue33 deleteId: ', row['row'].index)
    }
    const handleUpdatePenalty = async (row) => {
        const token = localStorage.getItem('accessToken');
        let queueName = row.original.queueName;
        let accountId = row.original.accountId;
        let penalty = row.original.penalty;
        console.log('Event update Penalty >> >>: ', row)
        console.log('Event original queueName >> >>: ', row.original.queueName)
        console.log('Event original accountId >> >>: ', row.original.accountId)
        console.log('Event original penalty >> >>: ', row.original.penalty)
        let accountPenalty = { queueName: queueName, accountId: accountId, penalty: penalty }
        let tralai2 = await axios.post(`${URL_API}/queue/updatePenalty`, accountPenalty, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                toast.success('Update Penalty account: ' + accountId + ' thành công');
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Queues.js thong bao loi1: ', error);
            });

    }
    const handleShowMembersInQueue = async (row) => {
        const token = localStorage.getItem('accessToken');
        let queueName = row['row'].original.queueName;
        // alert('Show members in Queue')
        console.log('Show members in Queue: ', row['row'].original.queueName)
        setSelectQueue(row['row'].original.queueName)

        // POST request using axios inside useEffect React hook
        let queueNameObj = { queueName: queueName }
        let tralai = await axios.post(`${URL_API}/queue/getMembersInQueue`, queueNameObj, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                // toast.success('Lấy tên members in Queue ' + queueName + ' thành công');
                console.log('Lay ten members in Queue: ', response.data)
                // setListAgent(response.data)
                setDataPenalty(response.data)
                // setDisplayTable(true);
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Queues.js thông báo lỗi: ', error);
            });

    }
    const handleDeleteYes = async () => {
        const token = localStorage.getItem('accessToken');
        let maloi = '';
        if (popup.show && popup.queueName) {
            setPopup({
                show: false,
                queueName: null,
                deleteId: null,
            });
            let tralai = await axios.delete(`${URL_API}/queue/deleteQueueName/${popup.queueName}`, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    console.log(' Da Xoa 1: ', response, ' DeleteId: ', popup.deleteId);
                    const newObj = data.splice(popup.deleteId, 1)
                    console.log(' Xoa 2: ', response.data.errCode)
                    console.log('Xoa New Object: ', newObj)
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' loi 1: ', error);
                });

            setTimeout(() => {
                if (!maloi) {
                    toast.success('Đã xóa queue thành công ' + popup.queueName);
                    console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Xóa queue không thành công ' + popup.queueName);
                }
                setLoadingData(true)
                // setAgain(true)
                console.log('Xóa 4 deleteId: ', popup.deleteId)
                console.log('Data new: ', data)
            }, 50);
        }
    }

    const handleAddQueue = async () => {
        const token = localStorage.getItem('accessToken');
        let queueMember = '';
        if (agent.length > 1) {
            for (let j = 0; j < agent.length; j++) {
                queueMember = queueMember + ',' + agent[j].value;
            }
        } else {
            queueMember = agent[0].value;
            // console.log('agent000: ', agent[0].value)
        }
        queueMember = queueMember.slice(1);  // lay tat ca gia tri tu vi tri 1 -> cuoi
        console.log('QueueMember: ', queueMember)
        console.log('Split 1: ', queueMember.split(','))
        console.log('Split 2: ', queueMember.split(',').length)

        // POST request using axios inside useEffect React hook
        let queueMemberObj = { queuename: queuename, strategy: strategy, queueMember: queueMember }
        let tralai = await axios.post(`${URL_API}/queue/saveQueue`, queueMemberObj, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                toast.success('Thêm mới queue ' + queuename + ' thành công');
                console.log('Split 3 length: ', queueMember.split(',').length)
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Queues.js thong bao loi: ', error);
            });

    }
    const columns = [
        {
            Header: 'STT',
            Cell: ({ row }) => (
                <><h6>{row.index + 1}</h6></>
            ),
        },
        {
            Header: 'Tên Queue',
            accessor: 'queueName',
        },
        {
            Header: 'Số Agents',
            accessor: 'accountInQueue',
            Cell: ({ value, row }) =>
                <>
                    {(value)}&nbsp;&nbsp;&nbsp;
                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleShowMembersInQueue({ row }) }}>
                        <i className='fas fa-user-edit' style={{ color: '#32a871' }} title='Cập nhật kỹ năng nhân viên'></i>
                    </span>
                </>,
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row, id }) =>
                <>
                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleEdit({ row }) }}><i className='fas fa-edit'></i></span> &nbsp;
                    <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { handleConfirm({ row }) }}><i className='fas fa-trash-alt'></i></span>
                </>,
        },
    ];
    const columnsPenalty = [
        {
            Header: 'STT',
            Cell: ({ row }) => (
                <><h6>{row.index + 1}</h6></>
            ),
        },
        {
            Header: 'QueueName',
            accessor: 'queueName',
        },
        {
            Header: 'Tài khoản',
            accessor: 'accountId',
        },
        {
            Header: 'Kỹ năng',
            accessor: 'penalty',
            // Cell: ({ value, row }) =>
            //     <>
            //         <input style={{ width: '30px' }} type='text' value={(value)} onBlur={() => { handleUpdatePenalty({ row }) }} />&nbsp;
            //     </>,
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row, id }) =>
                <>
                    <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => handleUpdatePenalty(row)}>Update</span> &nbsp;
                </>,
        },
    ];
    return (
        <div>
            <Modal show={popup.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cảnh báo xóa thông tin queue</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc muốn xóa queue này? {popup.id}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteYes}>
                        Có
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Không
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Section 2 */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-3">
                            <div className="card card-success">
                                <div className="card-header">
                                    <h3 className="card-title sm">Thêm mới Queue</h3>
                                </div>
                                <form>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="queueName">Tên Queue</label>
                                            <input type="text" onChange={(event) => { handleOnChangeQueueName(event) }} className="form-control" id="queueName" placeholder="Nhập vào tên Queue" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="strategy">Cơ chế đổ chuông</label>
                                            <select defaultValue={strategy}
                                                value={strategy}
                                                onChange={(event) => { handleOnChangeStrategy(event) }}
                                                className="form-control form-control-sm">
                                                <option value='rrmemory'>Xoay vòng</option>
                                                <option value='ringall'>Đổ chuông tất cả</option>
                                                <option value='leastrecent'>Rảnh lâu nhất</option>
                                                <option value='fewestcalls'>Ít cuộc gọi nhất</option>
                                                <option value='random'>Ngẫu nhiên</option>
                                                <option value='linear'>Tuần tự</option>
                                            </select>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="agents">Nhân viên</label>
                                            <Select
                                                value={agent}
                                                onChange={(event) => { handleChangeAgents(event) }}
                                                options={agents}
                                                getOptionValue={(option) => option.value}
                                                getOptionLabel={(option) => option.value}
                                                isMulti />
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type='button' onClick={() => { handleAddQueue() }} className="btn btn-success">
                                            <i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        {/* right column */}
                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"><b>Danh sách Queues</b></h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <TableQueue columns={columns} data={data} />
                                        </div>
                                        <div className="col-md-6">
                                            {console.log('log, render >>>>: ')}
                                            <div style={{ height: '32px' }}>
                                                <h3 className="card-title">Tên Queues: <b>{selectQueue}</b></h3>
                                            </div>
                                            <TableQueuePenalty columns={columnsPenalty} data={dataPenalty} defaultColumn={defaultColumn} updateMyData={updateMyData} />
                                            {/* <table
                                                className="table table-bordered"
                                                style={{ display: (displayTable) ? ('display') : ('display') }}
                                            // style={{ display: 'none' }}
                                            >
                                                {/* {(updateAccount === false) ? (
                                                    <span>Thêm mới tài khoản</span>
                                                ) : (
                                                    <span>Cập nhật tài khoản</span>
                                                )} */}
                                            {/* <thead>
                                                <tr>
                                                    <th style={{ width: 10 }}>#</th>
                                                    <th style={{ textAlign: 'center' }}><span> {`${selectQueue}`} </span></th>
                                                    <th style={{ textAlign: 'center' }}>Kỹ năng</th>
                                                    <th style={{ textAlign: 'center' }}>Hành động</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {listAgent.map(item => (
                                                    <tr>
                                                        <td style={{ textAlign: 'center', width: '20px' }}>{item.index}</td>
                                                        <td style={{ textAlign: 'center', width: '150px' }}><span id='accountId' onChange={e => setAccountId(e.target.value)}>{item.accountId}</span></td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            <input style={{ width: '50px' }}
                                                                type='text'
                                                                id='penaltyNumber'
                                                                onChange={(event) => { handleOnChangePenalty(event) }}
                                                                value={item.penalty}>
                                                            </input></td>
                                                        <td style={{ textAlign: 'center' }}>
                                                            <span
                                                                style={{ cursor: 'pointer', color: 'green' }}
                                                                onSubmit={(event) => { handleUpdatePenalty(event) }}
                                                            >
                                                                Cập nhật</span>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody> */}
                                            {/* </table> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default Queues