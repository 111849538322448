import React from 'react'
import Accounts from './Settings/Accounts'
import Workgroups from './Settings/Workgroups'
import Permissions from './SettingAccounts/Permissions'
import Roles from './SettingAccounts/Roles'

const SettingAccounts = (props) => {
    // const giatri = props.Trunks;
    return (
        <div>
            <div className="content-wrapper" style={{ padding: '0px', borderRadius: '0px' }}>
                <section style={{ height: '3px' }}>
                </section>
                {/*  */}
                <section className="content" style={{ borderRadius: '1px' }}>
                    <div className="container-fluid" style={{ padding: '5px', borderRadius: '1px' }}>
                        <div className="row">
                            <div className="col-12 col-sm-12" style={{ padding: '0px', borderRadius: '1px' }}>
                                <div className="card card-success card-tabs" style={{ padding: '0px', borderRadius: '1px' }}>
                                    <div className="card-header p-0 pt-1" style={{ padding: '0px', borderRadius: '0px' }}>
                                        <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist" style={{ padding: '0px', borderRadius: '0px' }}>
                                            <li className="pt-2 px-3" style={{ padding: '0px', borderRadius: '0px', backgroundColor: 'gray' }}>
                                                <h3 className="card-title"><i className='nav-icon fas fa-wrench' style={{ color: 'yellow' }}></i>&nbsp;Cấu hình người dùng</h3>
                                            </li>
                                            {(localStorage.getItem('role').includes('CONFIG_ACCOUNT_ACCOUNT')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-second-taikhoan-tab"
                                                        data-toggle="pill" href="#custom-tabs-second-taikhoan" role="tab"
                                                        aria-controls="custom-tabs-second-profile" aria-selected="false">
                                                        <i className='fas fa-user-cog'></i>&nbsp;&nbsp;Tài khoản</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('CONFIG_ACCOUNT_GROUP')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-third-workgroup-tab"
                                                        data-toggle="pill" href="#custom-tabs-third-workgroup" role="tab"
                                                        aria-controls="custom-tabs-third-workgroup" aria-selected="false">
                                                        <i className='fas fa-users-cog'></i>&nbsp;&nbsp;Nhóm tài khoản</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('CONFIG_ACCOUNT_PERMISSION')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-fourth-permission-tab"
                                                        data-toggle="pill" href="#custom-tabs-fourth-permission" role="tab"
                                                        aria-controls="custom-tabs-fourth-permission" aria-selected="false">
                                                        <i className='fas fa-user-secret'></i>&nbsp;&nbsp;Phân quyền</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('CONFIG_ACCOUNT_ROLE')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-fifth-role-tab"
                                                        data-toggle="pill" href="#custom-tabs-fifth-role" role="tab"
                                                        aria-controls="custom-tabs-fifth-role" aria-selected="false">
                                                        <i className='fas fa-users'></i>&nbsp;&nbsp;Vai trò</a>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                    <div className="card-body" style={{ padding: '10px' }}>
                                        <div className="tab-content" id="custom-tabs-five-tabContent">
                                            <div className="tab-pane show active" id="custom-tabs-second-taikhoan"
                                                role="tabpanel" aria-labelledby="custom-tabs-second-taikhoan-tab">
                                                <Accounts />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-third-workgroup"
                                                role="tabpanel" aria-labelledby="custom-tabs-third-workgroup-tab">
                                                <Workgroups />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-fourth-permission"
                                                role="tabpanel" aria-labelledby="custom-tabs-fourth-permission-tab">
                                                <Permissions />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-fifth-role"
                                                role="tabpanel" aria-labelledby="custom-tabs-fifth-role-tab">
                                                <Roles />
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default SettingAccounts