function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input style={{ width: '80px' }}
            className='form-control form-control-sm'
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`${count} ...`}
        />
    )
}

const bodyData = [

    { "id": 994, "recordingDate": "17/05/2021", "phoneNumber": "759 611 4571", "duration": 27, "agentId": "Hardy", "queueName": "Rubber", "direction": false, "disconnect": true },
    { "id": 995, "recordingDate": "14/11/2021", "phoneNumber": "955 409 8776", "duration": 1, "agentId": "Zeb", "queueName": "Brass", "direction": false, "disconnect": false },
    { "id": 996, "recordingDate": "17/01/2022", "phoneNumber": "951 491 5445", "duration": 95, "agentId": "Eduino", "queueName": "Rubber", "direction": false, "disconnect": false },
    { "id": 997, "recordingDate": "21/12/2021", "phoneNumber": "857 461 2856", "duration": 8, "agentId": "Duff", "queueName": "Aluminum", "direction": true, "disconnect": true },
    { "id": 998, "recordingDate": "18/02/2022", "phoneNumber": "457 168 2776", "duration": 33, "agentId": "Barret", "queueName": "Plastic", "direction": true, "disconnect": true },
    { "id": 999, "recordingDate": "02/02/2022", "phoneNumber": "272 651 1777", "duration": 69, "agentId": "Laurie", "queueName": "Aluminum", "direction": true, "disconnect": true },
    { "id": 1000, "recordingDate": "18/05/2021", "phoneNumber": "235 148 3407", "duration": 86, "agentId": "Caspar", "queueName": "Vinyl", "direction": true, "disconnect": true }
]

export { bodyData, DefaultColumnFilter }