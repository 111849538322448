import React from 'react'

const Dashboard = () => {
    return (
        <div>
            {/* Content Wrapper. Contains page content */}
            <div className="content-wrapper">
                <section style={{ height: '15px' }}>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* Info boxes */}
                        <div className="row">
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box">
                                    <span className="info-box-icon bg-info elevation-1"><i className="fas fa-phone" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Cuộc gọi</span>
                                        <span className="info-box-number">
                                            100
                                            <small></small>
                                        </span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            {/* /.col */}
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-danger elevation-1"><i className="fas fa-arrow-down" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text" style={{ width: '90px' }}>Gọi vào</span>
                                        <span className="info-box-text">Chờ</span>
                                    </div>
                                    <div className="info-box-content">
                                        <span className="info-box-text">41</span>
                                        <span className="info-box-number">12</span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            {/* /.col */}
                            {/* fix for small devices only */}
                            <div className="clearfix hidden-md-up" />
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-success elevation-1"><i className="fas fa-arrow-up" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Gọi ra</span>
                                        <span className="info-box-text"><i className="fas fa-phone" style={{ color: 'green' }} /></span>
                                    </div>
                                    <div className="info-box-content">
                                        <span className="info-box-text">59</span>
                                        <span className="info-box-number">20</span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            {/* /.col */}
                            <div className="col-12 col-sm-6 col-md-3">
                                <div className="info-box mb-3">
                                    <span className="info-box-icon bg-warning elevation-2">
                                        <i className="fas fa-users" /></span>
                                    <div className="info-box-content">
                                        <span className="info-box-text">Nhân viên</span>
                                        <span className="info-box-text"><i className="fas fa-user" style={{ color: 'green' }} /></span>
                                    </div>
                                    <div className="info-box-content">
                                        <span className="info-box-text">100</span>
                                        <span className="info-box-number">0</span>
                                    </div>
                                    {/* /.info-box-content */}
                                </div>
                                {/* /.info-box */}
                            </div>
                            {/* /.col */}
                        </div>

                        {/* /.row */}
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h5 className="card-title">Dữ liệu tháng</h5>
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-8">
                                                <p className="text-center">
                                                    <strong>Sales: 1 Jan, 2014 - 30 Jul, 2014</strong>
                                                </p>
                                                <div className="chart">
                                                    {/* Sales Chart Canvas */}
                                                    <canvas id="salesChart" height={180} style={{ height: 180 }} />
                                                </div>
                                                {/* /.chart-responsive */}
                                            </div>
                                            {/* /.col */}
                                            <div className="col-md-4">
                                                <p className="text-center">
                                                    <strong>Thống kê</strong>
                                                </p>
                                                <div className="progress-group">
                                                    Tổng cuộc gọi
                                                    <span className="float-right"><b>1000</b></span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-primary" style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                                {/* /.progress-group */}
                                                <div className="progress-group">
                                                    Gọi vào
                                                    <span className="float-right"><b>510</b>/1000</span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-danger" style={{ width: '51%' }} />
                                                    </div>
                                                </div>
                                                {/* /.progress-group */}
                                                <div className="progress-group">
                                                    <span className="progress-text">Gọi ra</span>
                                                    <span className="float-right"><b>490</b>/1000</span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-success" style={{ width: '49%' }} />
                                                    </div>
                                                </div>
                                                {/* /.progress-group */}
                                                <div className="progress-group">
                                                    Nhân viên
                                                    <span className="float-right"><b>250</b></span>
                                                    <div className="progress progress-sm">
                                                        <div className="progress-bar bg-warning" style={{ width: '100%' }} />
                                                    </div>
                                                </div>
                                                {/* /.progress-group */}
                                            </div>
                                            {/* /.col */}
                                        </div>
                                        {/* /.row */}
                                    </div>
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}

                    </div>{/*/. container-fluid */}
                </section>
                {/* /.content */}
            </div >
            {/* /.content-wrapper */}
        </div >
    )
}

export default Dashboard