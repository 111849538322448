import React, { useEffect, useState, useMemo } from 'react';
// import TableAccount from './TableAccount';
import CheckboxTree from 'react-checkbox-tree';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DualListBox from 'react-dual-listbox';
import TableRole from './TableRole'
import TableRoleDetail from './TableRoleDetail'
import slugify from 'slugify'
import { DefaultColumnFilter } from '../Common/extensionData';

import 'react-dual-listbox/lib/react-dual-listbox.css';
import Agents from '../Reportings/Agents';

const Roles = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API

    const [showAccount, setShowAccount] = useState(false);
    const [showExtension, setShowExtension] = useState(false);
    const [data, setData] = useState([]);
    const [loadingDataMembers, setLoadingDataMembers] = useState(true);
    const [isAccount, setIsAccount] = useState(false);
    const [dataAccount, setDataAccount] = useState([]);
    const [dataExtension, setDataExtension] = useState([]);
    const [selected, setSelected] = useState('')
    const [selectedExtension, setSelectedExtension] = useState('')
    const [loadingData, setLoadingData] = useState(true);
    const [updateAccount, setUpdateAccount] = useState(false);
    const [updateRole, setUpdateRole] = useState(false);
    const [roleName, setRoleName] = useState('');
    const [roleCode, setRoleCode] = useState('');
    const [roleNotes, setRoleNotes] = useState('');
    const [roleDes, setRoleDes] = useState('');
    const [selectWorkgroup, setSelectWorkgroup] = useState('');
    const [selectWorkgroupName, setSelectWorkgroupName] = useState('');
    const [agent, setAgent] = useState([]);
    const [agents, setAgents] = useState([]);
    const [accounts, setAccounts] = useState([]);
    const [extension, setExtension] = useState([]);
    const [extensions, setExtensions] = useState([]);
    const [roleId, setRoleId] = useState('agent');
    const [isOpen, setIsOpen] = useState(false);

    const [popup, setPopup] = useState({
        show: false,
        id: null,
    });
    const handleCloseModalExtension = () => {
        setShowExtension(false);
    }
    const handleCloseModalAccount = () => {
        setShowAccount(false);
    }
    const handleShowModalExtension = async (row) => {
        let roleCode = row['row'].original.roleCode;
        console.log('handleShowModalExtension: ', row)
        console.log('handleShowModalExtension roleCode: ', row['row'].original.roleCode)
        console.log('Show extension: ', extensions)
        // Get AccountId in workgroupCode
        const token = localStorage.getItem('accessToken');
    }
    const handleShowModalAccount = async (row) => {
        let roleCode = row.row.original.role_id;
        console.log('show value role_name: ', row.row.original.role_name)
        console.log('handleShowModalAccount role_id: ', roleCode)
        // console.log('Show accounts: ', agents)

        // Get AccountId in workgroupCode
        const token = localStorage.getItem('accessToken');
        // POST request using axios inside useEffect React hook
        // let roleCodeObj = { roleCode: roleCode }
        let agentsInRole = await axios.get(`${URL_API}/role/getAgentInRoles/${roleCode}`, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                console.log('isAccount: ', isAccount)
                setRoleCode(roleCode)
                console.log('Show selected: ', typeof (response))
                console.log('Show response.data: ', response.data)
                console.log('Show response.data JSON: ', JSON.stringify(response.data))
                console.log('Show response.data JSON 1: ', agentsInRole)
                // setIsAccount(true)

                setAccounts(response.data)
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Roles.js line 129 thông báo lỗi: ', error);
            });

        setShowAccount(true);
        setRoleName(row.row.original.role_name);
    }

    const handleClose = () => {
        setPopup(
            {
                show: false,
                id: null,
            });
    };
    const handleDeleteYes = async () => {
        // const xoa = async () => {
        if (popup.show && popup.id) {
            console.log('Da xoa: ', popup.id)
            setPopup({
                show: false,
                id: null,
            });
            await axios.delete(`${URL_API}/role/deleteRole/${popup.id}`);
            setTimeout(() => {
                toast.success('Đã xóa vai trò thành công ' + popup.id);
                // setStatus('Đã xóa thành côg Account: ' + popup.id);
                setLoadingData(popup.id)
            }, 500);
        }
    }

    const handleOnChangeRoleCode = (event) => {
        if (updateRole === false) {
            setRoleCode(slugify(event.target.value, { locale: 'vi' }))
        }
    }

    function convertCheckPermission(permiss) {
        if (permiss.length > 0) {
            let giatri = JSON.stringify(checked);
            let permission = giatri.replace(/]/g, "");
            permiss = permission.slice(1)
        }
        return permiss;
    }

    const HandleAddNewRole = async () => {

        // let giatri = JSON.stringify(checked);
        // let permission = giatri.replace(/]/g, "");
        // // let permission = giatri.replace(/("|])/g, "");
        // let permiss = permission.slice(1)
        convertCheckPermission(checked);  // return permiss
        // console.log('show get values Role: ', giatri.value)
        console.log('show get values Role1: ', JSON.stringify(checked))
        console.log('show get values Role2: ', convertCheckPermission(checked))
        console.log('show get roleName : ', roleName)
        console.log('show get roleCode : ', roleCode)
        console.log('show get roleDes : ', roleDes)
        // const modifiedData = data.slice(0, -1)
        const token = localStorage.getItem('accessToken');
        console.log('roleName :', roleName, ' roleCode: ', roleCode, 'roleDes: ', roleDes, ' permission: ', convertCheckPermission(checked))
        let maloi = '';
        if ((roleName !== '') && (roleCode !== '')) {
            // POST request using axios inside useEffect React hook
            // let network = { networkName: networkName, ipAddress: ipAddress, port: port, username: username, password: password, notes: notes }
            let role = { role_id: roleCode, role_name: roleName, role_des: roleDes, permission: convertCheckPermission(checked) }
            let tralai = await axios.post(`${URL_API}/role/saveRole`, role, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' loi 1: ', error);
                });

            setTimeout(() => {
                if (maloi === 0) {
                    toast.success('Thêm mới vai trò thành công ' + roleName);
                    // console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Thêm mới vai trò không thành công, roleCode đã sử dụng ' + roleCode);
                }
                setLoadingData(true)
                setRoleName('')
            }, 50);

        } else {
            alert("RoleName hoặc RoleCode không được để trống!")
        }
    }
    const HandleUpdateRole = () => {
        const token = localStorage.getItem('accessToken');
        // POST request using axios inside useEffect React hook
        let updateRole = {
            role_id: roleCode,
            role_name: roleName,
            role_des: roleDes,
            permission: convertCheckPermission(checked),
        }
        axios.post(`${URL_API}/role/getUpdateRole`, updateRole, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                console.log("Update response: ", response);
            })
            .catch(function (error) {
                console.log(error);
            });

        setTimeout(() => {
            toast.success('Cập nhật vai trò thành công ' + roleName);
            // setStatus('Cập nhật thành công Account: ' + username);
            setLoadingData(true)
            setRoleName('')
            setRoleCode('')
        }, 500);
    }

    // Lấy danh sách account
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getUsernames() {
            const result = await axios.get(`${URL_API}/account/getAllAccountUsername`, {
                headers: {
                    'x-access-token': token
                }
            });

            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i]['label'] = result.data[i]['username'];
                result.data[i].value = result.data[i].username;
                delete result.data[i]['username'];
            }
            setLoadingData(false)
            setAgents(result.data);
            console.log('Queues - get result username in Queues1: ', result.data)
        };
        getUsernames();
    }, [loadingData]);
    // Lấy danh sách extensions
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getExtensions() {
            const result = await axios.get(`${URL_API}/extension/listExten`, {
                headers: {
                    'x-access-token': token
                }
            });

            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i]['label'] = result.data[i]['username'];
                result.data[i].value = result.data[i].username;
                delete result.data[i]['username'];
            }
            // setLoadingData(false)
            // setExtensions(result.data);
            // console.log('Queues - get result extesions in Queues1: ', result.data)
        };
        getExtensions();
    }, [loadingData]);

    // Lấy danh sách Members in Workgroup
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getMembersInWorkgroup() {
            const result = await axios.post(`${URL_API}/workgroup/getMembersInWorkgroup`, {
                headers: {
                    'x-access-token': token
                }
            });
            console.log('set data for Members in Workgroup: ', result.data)
            setLoadingData(false)
            setData(result.data);
        };
        // getMembersInWorkgroup();
    }, [loadingDataMembers]);
    //  Lấy danh sách tất cả Roles
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        async function getRoles() {
            const result = await axios.get(`${URL_API}/role/getAllRoles`, {
                headers: {
                    'x-access-token': token
                }
            });
            setData(result.data);
            setLoadingData(false);
            console.log('refresh No API')
        };
        getRoles();
        // }
    }, [loadingData]);

    const handleOnChangeRoleName = (event) => {
        setRoleName(event.target.value);
        if (updateRole === false) {
            setRoleCode(slugify(event.target.value, { replacement: '_', locale: 'vi' }))
        }
    }
    const handleOnChangeRoleDes = (event) => {
        // setRoleNotes(event.target.value);
        setRoleDes(event.target.value);
    }
    const handleShowAccountsInRole = async (row) => {
        let workgroupCode = row['row'].original.workgroupCode;
        console.log('Show roleCode in Workgroup: ', row['row'].original.roleCode)
        console.log('Show roleName in Workgroup: ', row['row'].original.roleName)
        setSelectWorkgroup(row['row'].original.roleCode)
        setSelectWorkgroupName(row['row'].original.roleName)

        const token = localStorage.getItem('accessToken');
        // POST request using axios inside useEffect React hook
        let workgroupCodeObj = { workgroupCode: workgroupCode }
        let reAccountsInWorkgroup = await axios.post(`${URL_API}/workgroup/getAccountsInWorkgroup`, workgroupCodeObj, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                // toast.success('Lấy tên members in Queue ' + queueName + ' thành công');
                console.log('Lay ten Accounts in Workgroup: ', response.data)
                console.log('isAccount: ', isAccount)
                setDataExtension([])
                setDataAccount(response.data)
                setIsAccount(true)
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Workgroups.js thông báo lỗi: ', error);
            });
    }

    const handleRemoveAccountInWorkgroup = (accountId) => {
        // alert('remove account in Workgroup', accountId)
        console.log('remove account in Workgroup', accountId)
        console.log('remove accountId in Workgroup', accountId['row'].original.accountId)
    }
    const handleRemoveExtensionInWorkgroup = (extension) => {
        // alert('remove extension in Workgroup', accountId)
        console.log('remove extension in Workgroup', extension)
    }

    // Edit Role
    const handleEditRole = (event) => {
        // window.alert('Alert hello');
        console.log('Edit Role: ', event.row)
        console.log('Edit Role 1: ', event.row.original)
        console.log('Check set: ', event.row.original.permission)
        let getPermission = event.row.original.permission;
        let getPermiss = [];
        getPermission = "[" + getPermission + "]";
        getPermiss = JSON.parse(getPermission)
        console.log('Get Permission : ', getPermiss)
        console.log('Get Permission 1 : ', typeof (getPermission))
        console.log('Get Permission 2 : ', typeof (getPermiss))
        // let vaitro = row['row'].original.roleId;
        // setUpdateAccount(true)
        setUpdateRole(true)
        setRoleName(event.row.original.role_name)
        setRoleCode(event.row.original.role_id)
        setRoleDes(event.row.original.role_des)
        setChecked(getPermiss)
        // setRoleId(vaitro)
    }
    // Delete role
    const handleDeleteRole = (event) => {
        alert('Tạm thời chưa cho xóa Vai trò, cần kiểm tra những account đã nằm trong Role trước. Chỉ hỗ trợ xóa vai trò sau khi không có Account nào có vai trò này')
        // check account or extension in Role. Notify if the role is not empty
    }

    const handleShowFullPermission = (row) => {
        console.log('show full permission :', row)
        console.log('show full permission 1 :', row.row.permission)
    }

    function truncateText(truncated, maxLength) {
        if (truncated.length > maxLength) {
            truncated = truncated.substr(0, maxLength) + '...';
        }
        return truncated;
    }

    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                Cell: ({ row }) => (
                    <><h6>{row.index + 1}</h6></>
                ),
            },
            {
                Header: 'Tên Role',
                accessor: 'role_name',
                Filter: DefaultColumnFilter,
            },
            {
                Header: 'Mã Role',
                accessor: 'role_id',
                Filter: DefaultColumnFilter,
            },
            {
                Header: 'Tên quyền',
                // accessor: 'permission',
                // accessor: row => convertDate(row.started_time),
                Filter: DefaultColumnFilter,
                accessor: row =>
                    <>
                        {/* {truncateText(row.permission, 15)} */}
                        <span style={{ cursor: 'pointer', color: 'green', fontSize: '9pt' }} onClick={() => { handleShowFullPermission({ row }) }}>
                            {truncateText(row.permission, 15)}
                            {/* <i className='fas fa-user-plus' style={{ color: '#32a871' }} ></i> */}
                        </span>
                    </>,
            },
            {
                Header: 'Tài khoản',
                accessor: 'accountIdInRole',
                Cell: ({ value, row }) =>
                    <>
                        &nbsp; &nbsp;
                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleShowModalAccount({ row }) }}>
                            <i className='fas fa-user-alt' style={{ color: '#32a871' }} title='Xem thông tin account trong vai trò'></i>
                        </span>
                    </>,
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row, id }) =>
                    <>{row.header}
                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleEditRole({ row }) }}>
                            <i className='fas fa-edit' title='Thay đổi thông tin role'></i>
                        </span>&nbsp;
                        <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { handleDeleteRole({ row }) }}>
                            <i className='fas fa-trash-alt' title='Xóa role'></i>
                        </span>&nbsp;

                    </>,
            },
        ]);

    const columnsWorkgroupDetail = useMemo(
        () => [
            {
                Header: 'STT',
                Cell: ({ row }) => (
                    <><h6>{row.index + 1}</h6></>
                ),
            },
            {
                Header: (isAccount === false ? 'Số nội bộ' : 'Tài khoản'),
                accessor: (isAccount === false ? 'extension' : 'accountId'),
                // accessor: 'extension'
            },
            {
                Header: 'Action',
                // accessor: 'workgroupCode',
                Cell: ({ value, row }) =>
                    <>
                        {/* <a href='#top' onClick={() => { handleShowAccountsInWorkgroup({ row }) }}>{(value)}</a> &nbsp; */}
                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { (isAccount === false) ? handleRemoveExtensionInWorkgroup({ row }) : handleRemoveAccountInWorkgroup({ row }) }}>
                            <i className='fas fa-user-minus' style={{ color: 'red' }} title='Xóa khỏi nhóm'></i>
                        </span>
                    </>,


            },
        ]);

    const handleSaveExtension = async () => {
        // alert("Đã thêm các số nội bộ vào nhóm: ")
        toast.success('Đã thay đổi số nội bộ trong nhóm thành công')
        console.log('Show selectedExtension: ', selectedExtension, ' roleCode: ', roleCode)

        const token = localStorage.getItem('accessToken');
        // POST update accountId in Workgroup
        let roleCodeObj = { roleCode: roleCode, workgroupExtension: selectedExtension }
        let returnExtensionsInWorkgroup = await axios.post(`${URL_API}/workgroup/addExtensionsToWorkgroup`, roleCodeObj, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                // toast.success('Lấy tên members in Queue ' + queueName + ' thành công');

                // setIsAccount(false)
                if (loadingDataMembers === true) {
                    setLoadingDataMembers(false)
                } else {
                    setLoadingDataMembers(true)
                }
                console.log('isAccount: ', isAccount)
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Workgroup.js thông báo lỗi: ', error);
            });
    }
    const handleSaveAccount = async () => {
        toast.success('Đã thay đổi tài khoản trong nhóm thành công')
        console.log('Show selected: ', selected, ' roleCode: ', roleCode)

        const token = localStorage.getItem('accessToken');
    }

    const options = agents;
    const optionsExtension = extensions;

    const handleOnChangeDualListBox = (chon) => {
        console.log('DualListBox selected AccountId variable state: chon ', typeof (chon), '_', chon)
        setSelected(chon)
    }

    const handleOnChangeDualListBoxExtension = (selected) => {
        console.log('DualListBox selected Extension variable state: chon ', typeof (selected), '_', selected)
        setSelectedExtension(selected)
    }

    const [checked, setChecked] = useState([]);
    const [expanded, setExpanded] = useState([]);

    const nodes = [{
        value: 'DASHBOARD_ALLOW',
        label: 'Dashboard',
        icon: <i className='nav-icon fas fa-tachometer-alt' style={{ color: 'green' }} />,
        children: [
            { value: 'DASHBOARD_VIEW', label: 'Xem Dashboard' },
        ],
    },
    {
        value: 'AGENT_ALLOW',
        label: 'Nhóm quyền nhân viên',
        icon: <i className='nav-icon fas fa-user-cog' style={{ color: 'green' }} />,
        children: [
            {
                value: 'AGENT_ALLOW_HISTORY',
                label: 'Lịch sử cuộc gọi',
                icon: <i className="far fa-file-image" />,
                children: [
                    { value: 'AGENT_HISTORY', label: 'Xem lịch sử cuộc gọi', icon: <i className="far fa-file-image" style={{ color: 'green' }} /> },
                ]
            },
            {
                value: 'AGENT_ALLOW_STATUS',
                label: 'Trạng thái làm việc',
                icon: <i className="far fa-file-image" />,
                children: [
                    { value: 'AGENT_STATUS', label: 'Xem trạng thái làm việc', icon: <i className="far fa-file-image" style={{ color: 'green' }} /> },
                ]
            },
            {
                value: 'AGENT_ALLOW_MISSED',
                label: 'Cuộc gọi nhỡ',
                icon: <i className="far fa-file-image" />,
                children: [
                    { value: 'AGENT_MISSED', label: 'Xem cuộc gọi nhỡ', icon: <i className="far fa-file-image" /> },
                    { value: 'AGENT_MISSED_CALLBACK', label: 'Gọi lại cuộc gọi nhỡ', icon: <i className="far fa-file-image" style={{ color: 'green' }} /> },
                ]
            },
            {
                value: 'AGENT_ALLOW_CHANGE',
                label: 'Can thiệp vào hoặc rời Queue',
                icon: <i className="far fa-file-image" />,
                children: [
                    { value: 'AGENT_ADD_REMOVE_QUEUES', label: 'Thêm hoặc rời Queue', icon: <i className="far fa-file-image" style={{ color: 'green' }} /> },
                ]
            },
        ],
    },
    {
        value: 'SUP_ALLOW',
        label: 'Nhóm quyền giám sát',
        icon: <i className='nav-icon fas fa-desktop' style={{ color: 'green' }} />,
        children: [
            {
                value: 'SUP_CALL',
                label: 'Giám sát cuộc gọi',
                icon: <i className="far fa-file-image" />,
                children: [
                    { value: 'SUP_CALL_LISTEN', label: 'Nghe xen', icon: <i className="far fa-file-image" style={{ color: 'green' }} /> },
                    { value: 'SUP_CALL_COACH', label: 'Nhắc nhở', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                    { value: 'SUP_CALL_CONF', label: 'Hội thoại', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                    { value: 'SUP_CALL_DIS', label: 'Ngắt kết nối', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                ]
            },
            {
                value: 'SUP_STAFF',
                label: 'Giám sát nhân viên',
                icon: <i className="far fa-file-image" />,
                children: [
                    { value: 'SUP_STAFF_CHAT', label: 'Chat với nhân viên', icon: <i className="far fa-file-image" style={{ color: 'green' }} /> },
                    { value: 'SUP_STAFF_CALL', label: 'Call với nhân viên', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                ]
            },
            {
                value: 'SUP_QUEUES',
                label: 'Giám sát Queues',
                icon: <i className="far fa-file-image" />,
                children: [
                    { value: 'SUP_QUEUES_LOGOUT', label: 'Đăng xuất nhân viên khỏi Queue', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                    { value: 'SUP_QUEUES_ADD', label: 'Thêm nhân viên vào Queue', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                    { value: 'SUP_QUEUES_REMOVE', label: 'Xóa nhân viên khỏi Queue', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                    { value: 'SUP_QUEUES_CHANGE_STATUS', label: 'Chuyển trạng thái làm việc của nhân viên', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                ]
            },
            {
                value: 'SUP_GROUP',
                label: 'Giám sát nhóm nhân viên',
                icon: <i className="far fa-file-image" />,
                children: [
                    { value: 'SUP_GROUP_VIEW', label: 'Xem nhóm nhân viên', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                ]
            },
            {
                value: 'SUP_EXT',
                label: 'Giám sát số nội bộ',
                icon: <i className="far fa-file-image" />,
                children: [
                    { value: 'SUP_EXT_STATUS', label: 'Xem trạng thái các số nội bộ', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                    { value: 'SUP_EXT_CALL', label: 'Gọi trực tiếp đến số nội bộ', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                    { value: 'SUP_EXT_DIS', label: 'Ngắt cuộc gọi của số nội bộ', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                    { value: 'SUP_EXT_LISTEN', label: 'Nghe xen cuộc gọi của số nội bộ', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
                ]
            },
        ],
    },
    {
        value: 'REPORT_ALLOW',
        label: 'Nhóm quyền Báo cáo',
        icon: <i className='nav-icon fas fa-book' style={{ color: 'green' }} />,
        children: [
            { value: 'REPORT_SUMMARY', label: 'Xem báo cáo tổng hợp', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'REPORT_CALL_BY_DATE', label: 'Xem cuộc gọi theo ngày', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'REPORT_CALL_BY_HOUR', label: 'Xem cuộc gọi theo múi giờ', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'REPORT_CALL_PER_ACCOUNT', label: 'Năng suất tài khoản', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'REPORT_CALL_PER_EXTENSION', label: 'Năng suất số nội bộ', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'REPORT_CALL_DETAIL', label: 'Chi tiết cuộc gọi', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'REPORT_CALL_DETAIL_OWNER', label: 'Chi tiết cuộc gọi owner', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
        ],
    },
    {
        value: 'RECORDING_ALLOW',
        label: 'Nhóm quyền ghi âm',
        icon: <i className='nav-icon fas fa-circle red-text' style={{ color: 'red' }} />,
        children: [
            { value: 'RECORDING_PLAY', label: 'Nghe file ghi âm', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'RECORDING_DOWNLOAD', label: 'Download file ghi âm', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'RECORDING_ALLOW_OWNER', label: 'Nghe file ghi âm owner', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
        ],
    },
    {
        value: 'CONFIG_SYSTEM_ALLOW',
        label: 'Nhóm quyền cấu hình hệ thống',
        icon: <i className='nav-icon fas fa-wrench' style={{ color: 'green' }} />,
        children: [
            { value: 'CONFIG_SYSTEM_TRUNK', label: 'Cấu hình số & trunk', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'CONFIG_SYSTEM_QUEUE', label: 'Cấu hình Queues', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'CONFIG_SYSTEM_EXTENSION', label: 'Cấu hình Số nội bộ', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'CONFIG_SYSTEM_NETWORK', label: 'Cấu hình nhà mạng', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'CONFIG_SYSTEM_BILLING', label: 'Cấu hình tính cước', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'CONFIG_SYSTEM_VIPBLACK', label: 'Cấu hình vip/blacklist', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
        ],
    },
    {
        value: 'CONFIG_ACCOUNT_ALLOW',
        label: 'Nhóm quyền cấu hình người dùng',
        icon: <i className='nav-icon fas fa-user-cog' style={{ color: 'green' }} />,
        children: [
            { value: 'CONFIG_ACCOUNT_ACCOUNT', label: 'Cấu hình Tài khoản', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'CONFIG_ACCOUNT_GROUP', label: 'Cấu hình Nhóm tài khoản', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'CONFIG_ACCOUNT_PERMISSION', label: 'Cấu hình phân quyền', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
            { value: 'CONFIG_ACCOUNT_ROLE', label: 'Cấu hình vai trò', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
        ],
    },
    {
        value: 'BILLING_ALLOW',
        label: 'Nhóm quyền Billing',
        icon: <i className='nav-icon far fa-money-bill-alt' style={{ color: 'green' }} />,
        children: [
            { value: 'BILLING_VIEW', label: 'Xem Billing', icon: <i className="far fa-file-pdf" style={{ color: 'green' }} /> },
        ],
    },
    ];

    const handleCheckPermission = (checked) => {
        console.log('Đã checked ', checked)
        setChecked(checked)
    }

    return (
        <div>
            {/* Modal show tài khoản trong nhóm */}
            <Modal show={showAccount} onHide={handleCloseModalAccount}>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ fontSize: '20px' }}>Thông tin các account trong Role:</span>
                        <br></br>
                        <span style={{ color: 'red' }}>
                            {roleName}
                        </span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Select aria-label="Accounts" multiple="yes" style={{ width: '300pt', height: '130pt' }}>
                            {
                                accounts.map((item, i) => <option key={i} value={item.username}>{item.username}</option>)
                            }

                        </Form.Select>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseModalAccount}>
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Section 2 */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="card card-success">
                                <div className="card-header">
                                    <h3 className="card-title sm">
                                        {(updateRole === false) ? (
                                            <span><i className='fas fa-users'></i>&nbsp;&nbsp;Thêm mới vai trò</span>
                                        ) : (
                                            <span><i className='fas fa-users'></i>&nbsp;&nbsp;Cập nhật vai trò</span>
                                        )}
                                    </h3>
                                </div>
                                <form>
                                    <div className="card-body" style={{ padding: '10px' }}>
                                        <div className="row">
                                            <div className="col-md-4">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group" style={{ padding: '0px' }}>
                                                            <label htmlFor="roleName">Tên vai trò</label>
                                                            <input type="text"
                                                                onChange={(event) => { handleOnChangeRoleName(event) }}
                                                                value={roleName} className="form-control form-control-sm is-valid"
                                                                id="roleName" placeholder="Nhập vào tên vai trò" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="form-group" style={{ padding: '0px' }}>
                                                            <label htmlFor="roleCode">Mã vai trò</label>
                                                            <input type="text"
                                                                onChange={(event) => { handleOnChangeRoleCode(event) }}
                                                                value={roleCode} className="form-control form-control-sm is-valid"
                                                                id="roleCode" placeholder="Nhập vào mã vai trò" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-12">

                                                        <div className="form-group" style={{ padding: '0px' }}>
                                                            <label htmlFor="roleNotes">Mô tả vai trò</label>
                                                            <input type="text" onChange={(event) => { handleOnChangeRoleDes(event) }} value={roleDes} className="form-control form-control-sm is-valid" id="roleDes" placeholder="ví dụ: quản lý nhóm" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-8'>
                                                <CheckboxTree
                                                    nodes={nodes}
                                                    checked={checked}
                                                    expanded={expanded}
                                                    checkModel='all'
                                                    iconsClass="fa5"
                                                    showNodeIcon="true"
                                                    nativeCheckboxes="true"
                                                    // noCascade="true"
                                                    // onCheck={checked => setChecked(checked)}
                                                    onCheck={(chon) => { handleCheckPermission(chon) }}
                                                    onExpand={expanded => setExpanded(expanded)}
                                                    showExpandAll="true"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="button" onClick={() => {
                                            (updateRole === false) ? HandleAddNewRole() : HandleUpdateRole()
                                        }}
                                            className="btn btn-success">
                                            {(updateRole === false) ? (
                                                <span><i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</span>
                                            ) : (
                                                <span><i className='fas fa-sync'></i>&nbsp;&nbsp;Cập nhật</span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div >
                        <div className="col-md-6">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"><b>Danh sách vai trò</b></h3>
                                </div>
                                <div className="card-body p-0">
                                    <TableRole columns={columns} data={data} />
                                </div>
                            </div>
                        </div>
                    </div >
                </div > {/* /.container-fluid */}
            </section >
            {/* /.content */}
            {/* onCheck={checked => this.setState({ checked })} */}
        </div >
    )
}

export default Roles