import React, { useState, useEffect } from 'react'
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Form from 'react-bootstrap/Form';
import DualListBox from 'react-dual-listbox';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import TableBilling from './TableBilling'
// import TableQueuePenalty from './TableQueuePenalty'

const Billings = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API

    // const [selectQueue, setSelectQueue] = useState('');
    const [showLineGroup, setShowLineGroup] = useState(false);
    const [prefix, setPrefix] = useState('');
    const [prefixMoney, setPrefixMoney] = useState('');
    const [prefixNote, setPrefixNote] = useState('');
    const [lineGroup, setLineGroup] = useState('');
    const [selected, setSelected] = useState('')
    const [prefixes, setPrefixes] = useState([]);
    const [line, setLine] = useState([]);
    const [lines, setLines] = useState([]);
    const [data, setData] = useState([]);
    const [dataLineGroup, setDataLineGroup] = useState([]);
    const [dataPenalty, setDataPenalty] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [updatePrefix, setUpdatePrefix] = useState(false);
    const [popup, setPopup] = useState({
        show: false,
        queueName: null,
        deleteId: null,
    });
    const [popupLineGroup, setPopupLineGroup] = useState({
        show: false,
        lineGroup: null,
        deleteId: null,
    });
    const options = prefixes;


    const updateMyData = (rowIndex, columnId, value) => {
        // We also turn on the flag to not reset the page
        // setSkipPageReset(true)
        setDataPenalty(old =>
            old.map((row, index) => {
                if (index === rowIndex) {
                    return {
                        ...old[rowIndex],
                        [columnId]: value,
                    }
                }
                return row
            })
        )
    }
    // Create an editable cell renderer
    const EditableCell = ({
        value: initialValue,
        row: { index },
        column: { id },
        updateMyData, // This is a custom function that we supplied to our table instance
    }) => {
        // We need to keep and update the state of the cell normally
        const [value, setValue] = React.useState(initialValue)

        const onChange = e => {
            setValue(e.target.value)
        }

        // We'll only update the external data when the input is blurred
        const onBlur = () => {
            updateMyData(index, id, value)
        }

        // If the initialValue is changed external, sync it up with our state
        React.useEffect(() => {
            setValue(initialValue)
        }, [initialValue])

        return <input style={{
            fontSize: '1rem',
            padding: '0',
            margin: '0',
            border: '0',
            width: '80px',
            textAlign: 'center',
            background: '#fffff',
        }
        } type='text' value={value} onChange={onChange} onBlur={onBlur} />
    }

    // Set our editable cell renderer as the default Cell renderer
    const defaultColumn = {
        Cell: EditableCell,
    }

    const handleClose = () => {
        setPopup(
            {
                show: false,
                queueName: null,
                deleteId: null,
            });
    };
    // Đóng lineGroup modal
    const handleCloseLineGroup = () => {
        setPopupLineGroup(
            {
                show: false,
                lineGroup: null,
                deleteId: null,
            });
    };

    // Lấy danh sách billing Prefix
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getAllPrefix() {
            const result = await axios.get(`${URL_API}/billing/getAllPrefix`, {
                headers: {
                    'x-access-token': token
                }
            });

            setLoadingData(false)
            setData(result.data);
            // console.log('Queues - get result username in Queues1: ', result.data)
        };
        getAllPrefix();
    }, [loadingData]);

    // Lấy danh sách billing Line group và Prefix trên mỗi lineGroup
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getAllLineGroup() {
            const result = await axios.get(`${URL_API}/billing/getAllLineGroup`, {
                headers: {
                    'x-access-token': token
                }
            });

            setLoadingData(false)
            setDataLineGroup(result.data);
            // console.log('Queues - get result username in Queues1: ', result.data)
        };
        getAllLineGroup();
    }, [loadingData]);

    // Lấy danh sách billing prefix Only
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getOnlyPrefix() {
            const result = await axios.get(`${URL_API}/billing/getAllPrefix`, {
                headers: {
                    'x-access-token': token
                }
            });

            // change key name from prefix to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i]['label'] = result.data[i]['prefix'];
                result.data[i].value = result.data[i].prefix;
                delete result.data[i]['prefix'];
            }
            setLoadingData(false)
            setLines(result.data);
            console.log('Billings.js - get result prefix in Billings: ', result.data)
        };
        getOnlyPrefix();
    }, [loadingData]);


    const handleOnChangePrefix = (event) => {
        setPrefix(event.target.value);
    }
    const handleOnChangePrefixMoney = (event) => {
        setPrefixMoney(event.target.value);
    }
    const handleOnChangePrefixNote = (event) => {
        setPrefixNote(event.target.value);
    }

    const handleOnChangeLineGroup = (e) => {
        console.log('get lineGroup: ', e.target.value)
        setLineGroup(e.target.value);
    }

    const handleOnChangeLines = (line) => {
        console.log('Get Line value: ', line)
        setLine(line);
    }

    function showNotification() {
        const notification = new Notification("Một thông báo mới được gửi đến", {
            body: "Chào bạn, bạn được nhận một chương trình khueyesn mại mới"
        });
    }

    const handleEdit = (row) => {
        console.log('row: ', row)
        let prefix = row['row'].original.prefix;
        // setUpdateQueue(true)
        setUpdatePrefix(true)
        setPrefix(row['row'].original.prefix)
        setPrefixMoney(row['row'].original.prefixMoney)
        setPrefixNote(row['row'].original.prefixNote)
        console.log('get prefix: ', prefix)
        // console.log("log Notification permission: ", Notification.permission)
        // Notification.requestPermission().then(permission => {
        //     if (permission === "granted") {
        //         showNotification();
        //     }
        // })
        // if (Notification.permission === 'default') {
        //     showNotification();
        // }
    }

    const handleOnChangeDualListBoxLineGroup = (chon) => {
        console.log('DualListBox selected prefix variable state: chon ', typeof (chon), '_', chon)
        setSelected(chon)
    }

    // const handleSaveLineGroup = async () => {
    //     toast.success('Đã thay đổi tài khoản trong nhóm thành công')
    //     console.log('Show selected: ', selected, ' workgroupCode: ', workgroupCode)

    //     const token = localStorage.getItem('accessToken');
    //     // POST update accountId in Workgroup
    //     let workgroupCodeObj = { workgroupCode: workgroupCode, workgroupAccountId: selected }
    //     let returnAccountsInWorkgroup = await axios.post(`${URL_API}/workgroup/addAccountsToWorkgroup`, workgroupCodeObj, {
    //         headers: {
    //             'x-access-token': token
    //         }
    //     })
    //         .then(function (response) {
    //             // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
    //             // toast.success('Lấy tên members in Queue ' + queueName + ' thành công');

    //             // setIsAccount(false)
    //             if (loadingDataMembers === true) {
    //                 setLoadingDataMembers(false)
    //             } else {
    //                 setLoadingDataMembers(true)
    //             }
    //             console.log('isAccount: ', isAccount)
    //             return response.data.errCode
    //         })
    //         .catch(function (error) {
    //             console.log('Workgroup.js thông báo lỗi: ', error);
    //         });
    // }
    const handleSaveLineGroup = async () => {
        alert('handleSaveLineGroup')
    }

    const handleEditLineGroup = (row) => {
        console.log('row: ', row)
        let lineGroup = row['row'].original.lineGroup;
        // setUpdateQueue(true)
        setUpdatePrefix(true)
        setPrefix(row['row'].original.prefix)
        setPrefixMoney(row['row'].original.prefixMoney)
        setPrefixNote(row['row'].original.prefixNote)
        console.log('get prefix: ', lineGroup)
        // console.log("log Notification permission: ", Notification.permission)
        // Notification.requestPermission().then(permission => {
        //     if (permission === "granted") {
        //         showNotification();
        //     }
        // })
        // if (Notification.permission === 'default') {
        //     showNotification();
        // }
    }

    const HandleAddNewPrefix = async () => {
        const token = localStorage.getItem('accessToken');
        console.log('Prefix prefix, prefixMoney, prefixNote: ', prefix, prefixMoney, prefixNote)
        let maloi = '';
        if ((prefix !== '') && (prefixMoney !== '')) {
            console.log('data: ', data)
            console.log('data id: ', data[data.length - 1].id)
            // POST request using axios inside useEffect React hook
            let prefixParam = { prefix: prefix, prefixMoney: prefixMoney, prefixNote: prefixNote }
            let tralai = await axios.post(`${URL_API}/billing/savePrefix`, prefixParam, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    const newObj = data.push(Object.assign({ id: data[data.length - 1].id + 1 }, prefixParam))
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' loi 1: ', error);
                });
            setTimeout(() => {
                if (maloi === 0) {
                    toast.success('Thêm mới đầu số tính cước thành công ' + prefix);
                    // console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Thêm mới đầu số tính cước không thành công, đầu số đã tồn tại ' + prefix);
                }
                setLoadingData(true)
                setPrefix('')
            }, 50);

        } else {
            alert("Đầu số hoặc số tiền không được để trống!")
        }
    }
    const HandleUpdatePrefix = () => {
        const token = localStorage.getItem('accessToken');
        // POST request using axios inside useEffect React hook
        let updatePrefix = { prefix: prefix, prefixMoney: prefixMoney, prefixNote: prefixNote }
        console.log('updatePrefix: ', updatePrefix)
        let tralaix = axios.post(`${URL_API}/billing/updatePrefix/${prefix}`, updatePrefix, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // console.log(response);
                console.log('response: ', response, tralaix)
            })
            .catch(function (error) {
                // console.log(error);
            });

        setTimeout(() => {
            toast.success('Cập nhật đầu số tính cước thành công ' + prefix);
            // setStatus('Cập nhật thành công Account: ' + networkName);
            setLoadingData(prefix)
            setPrefix('')
        }, 1000);
    }

    const handleConfirm = (row) => {
        setPopup({ show: true, prefix: row['row'].original.prefix, deleteId: row['row'].index });
        console.log('Show prefix11: ', row['row'].original.prefix)
        console.log('Show prefix22: ', row)
        console.log('Show prefix33 deleteId: ', row['row'].index)
    }
    // Xác nhận xóa nhóm line
    const handleConfirmDeleteLinGroup = (row) => {
        setPopupLineGroup({ show: true, lineGroup: row['row'].original.lineGroup, deleteId: row['row'].index });
        console.log('Show prefix11: ', row['row'].original.prefix)
        console.log('Show prefix22: ', row)
        console.log('Show prefix33 deleteId: ', row['row'].index)
    }

    const handleUpdatePenalty = async (row) => {
        const token = localStorage.getItem('accessToken');
        let queueName = row.original.queueName;
        let accountId = row.original.accountId;
        let penalty = row.original.penalty;
        console.log('Event update Penalty >> >>: ', row)
        console.log('Event original queueName >> >>: ', row.original.queueName)
        console.log('Event original accountId >> >>: ', row.original.accountId)
        console.log('Event original penalty >> >>: ', row.original.penalty)
        // console.log('Update Penalty: ', penalty)
        // console.log('AccountId : ', accountId)
        // POST request using axios inside useEffect React hook
        let accountPenalty = { queueName: queueName, accountId: accountId, penalty: penalty }
        let tralai2 = await axios.post(`${URL_API}/queue/updatePenalty`, accountPenalty, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                toast.success('Update Penalty account: ' + accountId + ' thành công _ ', tralai2);
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Billings.js thong bao loi1: ', error);
            });

    }
    const handleShowMoneyInPrefix = async (row) => {
        alert('Số tiền trên giây của đầu số')
    }
    // Add new line group gọi ra
    const handleAddNewLineGroup = () => {
        alert('Thêm mới line group gọi ra')
        // setShowLineGroup(true);
    }
    // Handle Save Add new lineGroup
    const HandleSaveNewLineGroup = async () => {
        const token = localStorage.getItem('accessToken');
        let lineMember = '';
        if (line.length > 1) {
            for (let j = 0; j < line.length; j++) {
                lineMember = lineMember + ',' + line[j].value;
            }
        } else {
            lineMember = line[0].value;
            // console.log('agent000: ', agent[0].value)
        }
        lineMember = lineMember.slice(1);  // lay tat ca gia tri tu vi tri 1 -> cuoi
        console.log('LineMember: ', lineMember)
        console.log('Split 1: ', lineMember.split(','))
        console.log('Split 2: ', lineMember.split(',').length)

        // POST request using axios inside useEffect React hook
        let lineMemberObj = { lineGroup: lineGroup, prefixInLineGroup: lineMember }
        let tralai = await axios.post(`${URL_API}/billing/saveLineGroup`, lineMemberObj, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                data.push(Object.assign({ lineGroup: lineGroup, lineInGroup: lineMember.split(',').length }));
                toast.success('Thêm mới line Group ' + lineGroup + ' thành công');
                setLoadingData(true)
                console.log('Split 3 length: ', lineMember.split(',').length)
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Settings/Billings.js thong bao loi: ', error);
            });

    }

    const handleCloseModalLineGroup = () => {
        setShowLineGroup(false)
    }

    const handleDeleteYes = async () => {
        const token = localStorage.getItem('accessToken');
        let maloi = '';
        if (popup.show && popup.prefix) {
            setPopup({
                show: false,
                prefix: null,
                deleteId: null,
            });
            let tralaiDelete = await axios.delete(`${URL_API}/billing/deletePrefix/${popup.prefix}`, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    console.log(' Da Xoa 1: ', response, ' DeleteId: ', popup.deleteId, '_ ');
                    const newObj = data.splice(popup.deleteId, 1)
                    console.log(' Xoa 2: ', response.data.errCode)
                    console.log('Xoa New Object: ', newObj)
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' Goi Delete in Axios loi1: ', error);
                });

            setTimeout(() => {
                if (!maloi) {
                    toast.success('Đã xóa đầu số tính cước thành công ' + popup.prefix);
                    console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Xóa đầu số tính cước không thành công ' + popup.prefix);
                }
                setLoadingData(true)
                // setAgain(true)
                console.log('Xóa 4 deleteId: ', popup.deleteId)
                console.log('Data new: ', data)
            }, 50);
        }
    }

    // Handle Xóa line Group
    const handleDeleteLineGroupYes = async () => {
        const token = localStorage.getItem('accessToken');
        let maloiLineGroup = '';
        if (popupLineGroup.show && popupLineGroup.lineGroup) {
            setPopupLineGroup({
                show: false,
                lineGroup: null,
                deleteId: null,
            });
            let tralaiDelete = await axios.delete(`${URL_API}/billing/deleteLineGroup/${popupLineGroup.lineGroup}`, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    console.log(' Da Xoa lineGroup 1: ', response, ' DeleteId: ', popupLineGroup.deleteId, '_ ');
                    const newObj = data.splice(popupLineGroup.deleteId, 1)
                    console.log(' Xoa lineGroup 2: ', response.data.errCode)
                    console.log('Xoa New Object: ', newObj)
                    return maloiLineGroup = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' Goi Delete in Axios loi1: ', error);
                });

            setTimeout(() => {
                if (!maloiLineGroup) {
                    toast.success('Đã xóa nhóm Line thành công ' + popupLineGroup.lineGroup);
                    console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Xóa nhóm Line không thành công ' + popupLineGroup.lineGroup);
                }
                setLoadingData(true)
                // setAgain(true)
                console.log('Đã Xóa deleteId: ', popupLineGroup.deleteId)
                console.log('Data new: ', data)
            }, 50);
        }
    }

    const columns = [
        {
            Header: 'STT',
            Cell: ({ row }) => (
                <><h6>{row.index + 1}</h6></>
            ),
        },
        {
            Header: 'Đầu số',
            accessor: 'prefix',
        },
        {
            Header: 'Số tiền/giây',
            accessor: 'prefixMoney',
            // Cell: ({ value, row }) =>
            //     <>
            //         <a href='#top' onClick={() => { handleShowMoneyInPrefix({ row }) }}>{(value)}</a> &nbsp;
            //     </>,
        },
        {
            Header: 'Ghi chú',
            accessor: 'prefixNote',
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row, id }) =>
                <>
                    <span style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => { handleEditLineGroup({ row }) }}><i className='fas fa-edit'></i>
                    </span> &nbsp;
                    <span style={{ cursor: 'pointer', color: 'red' }}
                        onClick={() => { handleConfirm({ row }) }}><i className='fas fa-trash-alt'></i>
                    </span>
                </>,
        },
    ];

    const columnsLineGroup = [
        {
            Header: 'STT',
            Cell: ({ row }) => (
                <><h6>{row.index + 1}</h6></>
            ),
        },
        {
            Header: 'Nhóm Line',
            accessor: 'lineGroup',
        },
        {
            Header: 'Đầu số',
            accessor: 'prefixes',
        },
        {
            Header: 'Action',
            accessor: 'action',
            Cell: ({ row, id }) =>
                <>
                    <span style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => { handleEditLineGroup({ row }) }}><i className='fas fa-edit'></i>
                    </span> &nbsp;
                    <span style={{ cursor: 'pointer', color: 'red' }}
                        onClick={() => { handleConfirmDeleteLinGroup({ row }) }}><i className='fas fa-trash-alt'></i>
                    </span>
                </>,
        },
    ];
    return (
        <div>
            {/* Modal show các đầu số gọi ra */}
            <Modal show={showLineGroup} onHide={handleCloseModalLineGroup}>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ fontSize: '20px' }}>Thay đổi đầu số gọi ra:</span>
                        <span style={{ color: 'red' }}> Nhóm đầu số</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <DualListBox
                                // showNoOptionsText={true}
                                showOrderButtons={true}
                                showHeaderLabels={true}
                                filterPlaceholder={'Chọn'}
                                canFilter={true}
                                options={options}
                                selected={selected}
                                // onChange={this.onChange}
                                // onChange={(event) => { handleDualListBox(event) }}
                                onChange={(selected) => { handleOnChangeDualListBoxLineGroup(selected) }}
                            />
                        </Form.Group>
                        {/* <Form.Group>
                            <Button variant="secondary" onClick={() => { handleSaveAccount() }}>
                                Lưu
                            </Button>
                        </Form.Group> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseModalLineGroup}>
                        Đóng
                    </Button>
                    <Button variant="success" onClick={handleSaveLineGroup}>
                        <i className='fas fa-save'></i>&nbsp;&nbsp;Cập nhật Line group
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal thêm nhóm Line gọi ra */}
            <Modal show={showLineGroup} onHide={handleCloseModalLineGroup}>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ fontSize: '20px' }}>Thêm nhóm line gọi ra: </span>
                        <span style={{ color: 'red' }}></span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group className="mb-3" controlId="lineGroupForm.lineGroupName">
                            <Form.Label>Tên nhóm Line</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Nhập vào tên nhóm Line"
                                autoFocus
                                onChange={(event) => { handleOnChangeLineGroup(event) }}
                            />
                        </Form.Group>
                        <Form.Group
                            className="mb-3"
                            controlId="lineGroupForm.prefix"
                        >
                            <div className="form-group">
                                <label htmlFor="agents">Đầu số</label>
                                <Select
                                    value={line}
                                    onChange={(event) => { handleOnChangeLines(event) }}
                                    options={lines}
                                    getOptionValue={(option) => option.value}
                                    getOptionLabel={(option) => option.value}
                                    placeholder='Chọn đầu số cho nhóm Line'
                                    noOptionsMessage={() => "Không còn line"}
                                    isMulti />
                            </div>
                        </Form.Group>
                        {/* <Form.Group>
                            <Button variant="secondary" onClick={() => { handleSaveExtension() }}>
                                Lưu
                            </Button>
                        </Form.Group> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseModalLineGroup}>
                        {/* <Button variant="danger" onClick={handleCloseModalExtension}> */}
                        Đóng
                    </Button>
                    <Button variant="success">
                        {/* <Button variant="success" onClick={HandleSaveNewLineGroup}> */}
                        {/* <i className='fas fa-save'></i>&nbsp;&nbsp;Thêm mới nhóm Lines */}

                        <span style={{ cursor: 'pointer' }}
                            onClick={() => { HandleSaveNewLineGroup() }}>
                            <i className='fas fa-save'></i>&nbsp;&nbsp;Thêm mới nhóm Lines
                        </span>

                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Cảnh báo xóa đầu số tính cước */}
            <Modal show={popup.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cảnh báo xóa thông tin đầu số tính cước</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc muốn xóa đầu số tính cước này? {popup.id}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteYes}>
                        Có
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Không
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Hết cảnh báo xóa đầu số tính cước */}
            {/* Cảnh báo xóa lineGroup */}
            <Modal show={popupLineGroup.show} onHide={handleCloseLineGroup}>
                <Modal.Header closeButton>
                    <Modal.Title>Cảnh báo xóa nhóm Line</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc muốn xóa nhóm Line này? {popupLineGroup.id}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteLineGroupYes}>
                        Có
                    </Button>
                    <Button variant="primary" onClick={handleCloseLineGroup}>
                        Không
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Hết cảnh báo lineGroup */}
            {/* Section 2 */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-2">
                            <div className="card card-success">
                                <div className="card-header">
                                    <h3 className="card-title sm">Thêm mới số tính cước</h3>
                                </div>
                                <form>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="prefix">Đầu số</label>
                                            <input type="text" onChange={(event) => { handleOnChangePrefix(event) }} value={prefix}
                                                className="form-control" id="prefix"
                                                placeholder="091 hoặc 090 hoặc 3 ký tự" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="prefixMoney">Số tiền/giây theo VND</label>
                                            <input type="text" onChange={(event) => { handleOnChangePrefixMoney(event) }} value={prefixMoney}
                                                className="form-control" id="prefixMoney"
                                                placeholder="số tiền / giây. Ví dụ: 13" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="prefixNote">Ghi chú</label>
                                            <input type="text" onChange={(event) => { handleOnChangePrefixNote(event) }} value={prefixNote}
                                                className="form-control" id="prefixNote"
                                                placeholder="Ví dụ: Viettel" />
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="button" onClick={() => {
                                            (updatePrefix === false) ? HandleAddNewPrefix() : HandleUpdatePrefix()
                                        }}
                                            className="btn btn-success">
                                            {(updatePrefix === false) ? (
                                                <span><i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</span>
                                            ) : (
                                                <span><i className='fas fa-sync'></i>&nbsp;&nbsp;Cập nhật</span>
                                            )}
                                        </button>
                                    </div>

                                </form>
                            </div>
                        </div>
                        {/* right column */}
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"><b>Danh sách đầu số</b></h3>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <TableBilling columns={columns} data={data} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"><b>Line gọi ra</b></h3>
                                    <div className="card-tools">
                                        {/* <a href='#top'> */}
                                        <span style={{ cursor: 'pointer', color: 'green', height: '22' }} onClick={() => { handleAddNewLineGroup() }}>
                                            <i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới nhóm Line</span>
                                        {/* </a> */}

                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12" style={{ paddingSize: '0px' }}>
                                            <TableBilling columns={columnsLineGroup} data={dataLineGroup} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default Billings