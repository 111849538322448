import moment from 'moment';
import axios from 'axios';


const convert12to24 = (gio) => {
    let getDate = gio.slice(0, 10)
    let getTime = gio.slice(11, 19);
    let get24Time = moment(getTime, ["h:mm A"]).format("HH:mm");
    let fullTime = getDate + " " + get24Time + ":00";
    let convertDate = new Date(fullTime);
    const unixTimestamp = Math.floor(convertDate.getTime() / 1000);
    return unixTimestamp;
}

function convertUnixTimeToDateTime(unix_timestamp) {
    const milliseconds = unix_timestamp * 1000
    const dateObject = new Date(milliseconds)
    const startDate = dateObject.toLocaleString('')
    //  .toLocaleString()
    let getDate = startDate.slice(0, 10)
    let getTime = startDate.slice(11, 20);
    let get24Time = moment(getTime, ["hh:mm:ss A"]).format("HH:mm:ss");
    let fullTime = getDate + " " + get24Time;

    return fullTime;
}

const convertComplete = (data) => {
    if (data !== "") {
        if (data === 'COMPLETEAGENT') {
            return 'Nhân viên'
        } else {
            return 'Khách hàng'
        }
    }
}

function convertDate(unix_timestamp) {

    const milliseconds = unix_timestamp * 1000
    const dateObject = new Date(milliseconds)
    const startDate = dateObject.toLocaleString()
    return startDate;
}


function signOut() {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API

    const token = localStorage.getItem('accessToken');
    async function signOff() {
        let username = { username: localStorage.getItem('username') }
        const result1 = await axios.post(`${URL_API}/signout`, username, {
            headers: {
                'x-access-token': token
            }
        });
        console.log('SignOut 1', result1)
    };
    signOff();
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiredAt');
    localStorage.removeItem('username');
    localStorage.removeItem('fullname');
    localStorage.removeItem('extension');
    localStorage.removeItem('role');
    localStorage.removeItem('isAuth');
    window.location.reload(false);

    return 'signOut is successful!';
}

export {
    convertComplete,
    convert12to24,
    convertUnixTimeToDateTime,
    convertDate,
    signOut,
}