import React, { useState, useMemo, useEffect } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { CSVLink, CSVDownload } from "react-csv";
import axios from 'axios';
import moment from 'moment';
import { signOut, convertDate } from '../Utils'
import TablePerformanceByExtensions from './TablePerformanceByExtensions'
import Select from 'react-select';


const PerformanceByExtensions = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    // console.log('>>> Báo cáo năng suất: ')

    let startDate = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY HH:mm");
    let endDate = moment().subtract(0, 'days').startOf('day').format("MM/DD/YYYY HH:mm");
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [startTime, setStartTime] = useState('')
    const [endTime, setEndTime] = useState('')
    // const [status, setStatus] = useState('');
    const [thoigian, setThoigian] = useState(startDate + ' - ' + endDate);
    const [queue, setQueue] = useState('all')
    const [extension, setExtension] = useState([])
    const [extensions, setExtensions] = useState([])
    const [workgroup, setWorkgroup] = useState('all');
    const [workgroups, setWorkgroups] = useState([]);
    const [exten, setExten] = useState('all')


    useEffect(() => {
        async function getExtensions() {
            // const result = await axios('http://localhost:6600/api/v1/extension/listExten');
            // setExten(result.data);
        };
        getExtensions();
    }, []);

    useEffect(() => {
        async function getUsername() {
            // const result = await axios('http://localhost:6600/api/v1/account/getAllAccountUsername');
            // setUsername(result.data);
        };
        getUsername();
    }, []);
    // Lấy danh sách workgroups
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getWorkgroupNames() {
            const result = await axios.get(`${URL_API}/workgroup/listWorkgroup`, {
                headers: {
                    'x-access-token': token
                }
            });
            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i].label = result.data[i]['workgroupName'];
                result.data[i].value = result.data[i]['workgroupCode'];
                delete result.data[i]['workgroupCode'];
                delete result.data[i]['workgroupName'];
            }
            setLoadingData(false)
            setWorkgroups(result.data);
            console.log('Accounts.js - get result workgroupCode: ', result.data)
        };
        getWorkgroupNames();
    }, [loadingData]);

    const handleOnChangeWorkgroups = async (workgroup) => {
        console.log('Handle On Change Workgroup ok: ', workgroup)
        console.log('Handle On Change Workgroup value: ', workgroup.value)
        const token = localStorage.getItem('accessToken');
        let workgroupCode = { workgroupCode: workgroup.value }
        const result = await axios.post(`${URL_API}/workgroup/getExtensionsInWorkgroup`, workgroupCode, {
            headers: {
                'x-access-token': token
            }
        });
        // change key name from username to label and add key name is value
        for (let i = 0; i < result.data.length; i++) {
            result.data[i].label = result.data[i]['extension'];
            result.data[i].value = result.data[i]['extension'];
            delete result.data[i]['extension'];
            delete result.data[i]['workgroupCode'];
            // delete result.data[i]['extension'];
        }
        console.log('Handle on change Workgroup Result.data: ', result.data)
        setExtension(result.data)
        console.log('Handle change Workgroup result.data.length: ', result.data.length)

        let ext = '';
        for (let i = 0; i < result.data.length; i++) {
            ext = ext + ',' + result.data[i].value;
        }
        if (ext === '') {
            setExten(exten);
        } else {
            setExten(ext.slice(1));
        }
        setWorkgroup(workgroup)
    }
    const handleCallback = (thoi) => {
        // alert('handlCallback: ');
        setThoigian(thoi);
        console.log("Handle Callback: ", thoigian)
    }
    const handleOnSearchPerformanceExt = async () => {
        const token = localStorage.getItem('accessToken');
        // setRecordingURL('')
        // setPlayFile(false)
        if (!thoigian) {
            setThoigian("26/04/2022 08:00 PM - 28/04/2022 04:00 AM");
            let start = startDate;
            let end = endDate;
            console.log('Start: ', start)
            console.log('End: ', end)
        }
        function convert12to24(gio) {
            let getDate = gio.slice(0, 10)
            let getTime = gio.slice(11, 19);
            let get24Time = moment(getTime, ["h:mm A"]).format("HH:mm");
            let fullTime = getDate + " " + get24Time + ":00";
            let convertDate = new Date(fullTime);
            const unixTimestamp = Math.floor(convertDate.getTime() / 1000);
            // console.log(unixTimestamp); // 👉️ 1664000732
            return unixTimestamp;
        }
        let dates = thoigian.split(" - ");
        let start = convert12to24(dates[0]);
        let end = convert12to24(dates[1]);;
        console.log('Start time: ', start)
        setStartTime(start)
        console.log('End time: ', end)
        setEndTime(end)
        // console.log('phone: ', phone);
        // console.log('agentId: ', agentId);
        // console.log('queue: ', queue);
        let ext = '';
        for (let i = 0; i < extension.length; i++) {
            ext = ext + ',' + extension[i].value;
        }
        if (ext === '') {
            setExten(exten);
        } else {
            setExten(ext.slice(1));
        }
        console.log('Submit workgroup: ', workgroup.label);
        // console.log('Submit extension: ', extension)
        console.log('Submit exten: ', exten)
        console.log('Submit extension length: ', extension.length)
        // console.log('direction: ', direction)
        // console.log('handleDateTime _thoi gian: ', thoigian);
        try {
            let searchRecordingsPerformance = { start_time: start, end_time: end, extension: exten }
            const resultPerformance = await axios.post(`${URL_API}/reporting/rPerformanceByExtension`, searchRecordingsPerformance, {
                headers: { 'x-access-token': token }
            })
            setData(resultPerformance.data)
            console.log('PerformanceByExtensions.js Reporting list Data: ', resultPerformance.data)
        } catch (err) {
            console.log('Get error: ', err)
            console.log('Get error response: ', err.response)
            console.log('Get error response.data: ', err.response.data)
            console.log('Get error response.data.errMsg: ', err.response.data.errMsg)
            if (err.response.data.errMsg === 'jwt expired') {
                console.log('Token was expired')
                signOut();
            } else {
                console.log('Recordings.js Error in 158: ', err)
            }
        }
    }
    const handleOnChangeThoiGian = (event) => {
        setThoigian(event.target.value);
        console.log("handleChange: ", thoigian)
    };
    const handleOnChangeExtension = (event) => {

        if (event.target.value === 'all') {

        } else {
            setExten(event.target.value)
        }
        console.log("handleOnChangeExtension : ", event.target.value)
        console.log("handleOnChangeExtension workgroup: ", workgroup)
        console.log("handleOnChangeExtension exten: ", exten)
    }

    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                accessor: 'id',
                Filter: false,
                Cell: ({ row }) => (
                    <>
                        <span>{row.index + 1}</span>
                    </>
                ),
            },
            {
                Header: 'Thời gian bắt đầu',
                // accessor: row => convertDate(row.started_time),
                // accessor: 'started_time',
                Filter: false,
                Cell: () => (
                    <>
                        <span>{convertDate(startTime)}</span>
                    </>
                )
            },
            {
                Header: 'Thời gian kết thúc',
                // accessor: row => convertDate(row.started_time),
                // accessor: 'started_time',
                Filter: false,
                Cell: () => (
                    <>
                        <span>{convertDate(endTime)}</span>
                    </>
                )
            },
            {
                Header: 'AgentID',
                accessor: 'agent_id',
            },
            {
                Header: 'Số nội bộ',
                accessor: 'extension',
            },

            {
                Header: 'Tổng cuộc gọi',
                // accessor: 'call_status',
                accessor: row => (row.TotalGoiVao) + (row.TotalGoiRa),

                // Cell: (row) => (
                //     <>
                //         <span>{row.TotalGoiVao}11</span>
                //     </>
                // )
            },
            {
                Header: 'Tổng gọi vào',
                accessor: 'TotalGoiVao',
            },
            {
                Header: 'Tổng gọi ra',
                accessor: 'TotalGoiRa',
            },
            {
                Header: 'Tổng gọi vào trả lời',
                accessor: 'TongGoiVaoTraLoi',
            },
            {
                Header: 'Tổng gọi ra trả lời',
                accessor: 'TongGoiRaTraLoi',
            },
        ]);

    return (
        <div>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Năng suất số nội bộ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/index.html">Home</a></li>
                                    <li className="breadcrumb-item active">Năng suất số nội bộ</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* /.card */}
                                <div className="card">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label className="col-form-label col-form-label-sm">Thời gian</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="far fa-clock" /></span>
                                                        </div>
                                                        {/* <input type="text" className="form-control form-control-sm float-right" id="reservationtime" /> */}
                                                        <DateRangePicker
                                                            className="form-control form-control-lg float-right"
                                                            onEvent={(event) => handleOnChangeThoiGian(event)} value={thoigian}
                                                            onCallback={(event) => handleCallback(event.timePicker)}
                                                            initialSettings={{
                                                                timePicker: true,
                                                                startDate: startDate,
                                                                endDate: endDate,
                                                                locale: {
                                                                    format: 'MM/DD/YYYY hh:mm A',
                                                                },
                                                            }}
                                                        >
                                                            <input type="text" className="form-control col-12  form-control-md" />
                                                        </DateRangePicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <label htmlFor="workgroup" className="col-form-label col-form-label-sm">Tên nhóm</label>
                                                <Select
                                                    defaultValue={(workgroup)}
                                                    value={workgroup}
                                                    onChange={(event) => { handleOnChangeWorkgroups(event) }}
                                                    options={workgroups}
                                                    getOptionValue={(option) => option.value}
                                                    getOptionLabel={(option) => option.label}
                                                    placeholder='Chọn nhóm'
                                                    noOptionsMessage={() => "Không còn nhóm"}
                                                />
                                            </div>
                                            <div className="col-1">
                                                <div className="form-group">
                                                    <label className="col-form-label col-form-label-sm">Extension</label>
                                                    <select defaultValue='all' onChange={(event) => { handleOnChangeExtension(event) }}
                                                        className="form-control form-control-md">
                                                        <option value='all'>Tất cả</option>
                                                        {
                                                            extension.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">&nbsp;</label>
                                                        <button type="submit" onClick={() => { handleOnSearchPerformanceExt() }}
                                                            className="btn btn-success form-control form-control-md btn-md">
                                                            <i className="fas fa-search fa-fw" />&nbsp; Xem báo cáo</button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div>
                                                    <label className="col-form-label col-form-label-md">&nbsp;</label><br></br>
                                                    {/* <button type="submit" onClick={() => { handleExportCallDetails() }}
                                                        className="btn btn-success form-control form-control-sm btn-sm">
                                                        <i className="fas fa-file-export" />&nbsp; Export báo cáo</button> */}
                                                    <i className="fas fa-download" style={{ color: 'red' }} />&nbsp;&nbsp;
                                                    <CSVLink
                                                        filename="Nang_suat_so_noi_bo.csv"
                                                        data={data}>Download báo cáo
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TablePerformanceByExtensions columns={columns} data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </div>
    )
}

export default PerformanceByExtensions