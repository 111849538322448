import React, { useEffect, useState, useMemo } from 'react';
// import TableAccount from './TableAccount';
import axios from 'axios';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import DualListBox from 'react-dual-listbox';
import TableWorkgroup from './TableWorkgroup'
import TableWorkgroupDetail from './TableWorkgroupDetail'
import slugify from 'slugify'
import { DefaultColumnFilter } from '../Common/extensionData';

import 'react-dual-listbox/lib/react-dual-listbox.css';

const Workgroups = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API

    // const [show, setShow] = useState(false);
    const [showAccount, setShowAccount] = useState(false);
    const [showExtension, setShowExtension] = useState(false);
    const [data, setData] = useState([]);
    const [loadingDataMembers, setLoadingDataMembers] = useState(true);
    const [isAccount, setIsAccount] = useState(false);
    const [dataAccount, setDataAccount] = useState([]);
    const [dataExtension, setDataExtension] = useState([]);
    const [selected, setSelected] = useState('')
    const [selectedExtension, setSelectedExtension] = useState('')
    const [loadingData, setLoadingData] = useState(true);
    const [updateAccount, setUpdateAccount] = useState(false);
    const [workgroupName, setWorkgroupName] = useState('');
    const [workgroupCode, setWorkgroupCode] = useState('');
    const [workgroupNotes, setWorkgroupNotes] = useState('');
    // const [workgroupMember, setWorkgroupMember] = useState('');
    const [selectWorkgroup, setSelectWorkgroup] = useState('');
    const [selectWorkgroupName, setSelectWorkgroupName] = useState('');
    const [workgroupExtension, setWorkgroupExtension] = useState('');
    const [agent, setAgent] = useState([]);
    const [agents, setAgents] = useState([]);
    const [extension, setExtension] = useState([]);
    const [extensions, setExtensions] = useState([]);
    const [roleId, setRoleId] = useState('agent');
    const [popup, setPopup] = useState({
        show: false,
        id: null,
    });
    const handleCloseModalExtension = () => {
        setShowExtension(false);
    }
    const handleCloseModalAccount = () => {
        setShowAccount(false);
    }
    const handleShowModalExtension = async (row) => {
        let workgroupCode = row['row'].original.workgroupCode;
        console.log('handleShowModalExtension: ', row)
        console.log('handleShowModalExtension workgroupCode: ', workgroupCode)
        console.log('Show all extension: ', extensions)
        // Get AccountId in workgroupCode
        const token = localStorage.getItem('accessToken');
        // POST request using axios inside useEffect React hook
        let workgroupCodeObj = { workgroupCode: workgroupCode }
        let reExtensionsInWorkgroup = await axios.post(`${URL_API}/workgroup/getExtensionsInWorkgroup`, workgroupCodeObj, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                // toast.success('Lấy tên members in Queue ' + queueName + ' thành công');
                //Code : get information in accountId list and add to selected list in DualListBox
                let outputExtensionsSelected = [];
                for (let item of response.data) {
                    outputExtensionsSelected.push(item.extension);
                    console.log('Extension insert: ', item.extension)
                }
                console.log('Show selectedExtension: ', typeof (selectedExtension), ' _ ', selectedExtension)
                console.log('Show outputExtensionsSelected: ', outputExtensionsSelected)

                setSelectedExtension(outputExtensionsSelected)
                setWorkgroupCode(workgroupCode)
                // setIsAccount(true)
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Workgroups.js line 91 thông báo lỗi: ', error);
            });
        setShowExtension(true);
        setWorkgroupName(row['row'].original.workgroupName);
    }

    const handleShowModalAccount = async (row) => {
        let workgroupCode = row['row'].original.workgroupCode;
        console.log('show value: ', row)
        console.log('handleShowModalAccount workgroupCode: ', workgroupCode)
        console.log('show workgroupName: ', row['row'].original.workgroupName)
        console.log('Show accounts: ', agents)
        // Get AccountId in workgroupCode
        const token = localStorage.getItem('accessToken');
        // POST request using axios inside useEffect React hook
        let workgroupCodeObj = { workgroupCode: workgroupCode }
        let reAccountsInWorkgroup = await axios.post(`${URL_API}/workgroup/getAccountsInWorkgroup`, workgroupCodeObj, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                // toast.success('Lấy tên members in Queue ' + queueName + ' thành công');
                //Code : get information in accountId list and add to selected list in DualListBox
                let outputSelected = [];
                for (let item of response.data) {
                    outputSelected.push(item.accountId);
                }
                // console.log('Lay ten Accounts line 78 in Workgroup: ', response.data)
                // console.log('Lay ten Accounts line 79 in Workgroup: ', Object.values(response.data))
                console.log('isAccount: ', isAccount)
                setSelected(outputSelected)
                setWorkgroupCode(workgroupCode)
                console.log('Show selected: ', typeof (selected), ' _ ', selected)

                // setIsAccount(true)
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Workgroups.js line 126 thông báo lỗi: ', error);
            });

        setShowAccount(true);
        setWorkgroupName(row['row'].original.workgroupName);
    }

    const handleClose = () => {
        setPopup(
            {
                show: false,
                id: null,
            });
    };
    const handleDeleteYes = async () => {
        // const xoa = async () => {
        if (popup.show && popup.id) {
            console.log('Da xoa: ', popup.id)
            setPopup({
                show: false,
                id: null,
            });
            await axios.delete(`${URL_API}/account/deleteAccount/${popup.id}`);
            setTimeout(() => {
                toast.success('Đã xóa nhóm tài khoản thành công ' + popup.id);
                // setStatus('Đã xóa thành côg Account: ' + popup.id);
                setLoadingData(popup.id)
            }, 1000);
        }
    }

    const handleOnChangeWorkgroupCode = (event) => {
        setWorkgroupCode(slugify(event.target.value, { locale: 'vi' }))
    }

    const HandleAddNewWorkgroup = () => {
        let workgroupAccountId = '';
        if (agent.length > 1) {
            for (let j = 0; j < agent.length; j++) {
                workgroupAccountId = workgroupAccountId + ',' + agent[j].value;
            }
            workgroupAccountId = workgroupAccountId.slice(1);  // lay tat ca gia tri tu vi tri 1 -> cuoi
        } else {
            // workgroupAccountId = agent[0].value;
            workgroupAccountId = [];
        }

        // Get list Extension in Workgroup
        let workgroupExtension = '';
        if (extension.length > 1) {
            for (let j = 0; j < extension.length; j++) {
                workgroupExtension = workgroupExtension + ',' + extension[j].value;
            }
        } else {
            // workgroupExtension = extension[0].value;
            workgroupExtension = [];
        }
        workgroupExtension = workgroupExtension.slice(1);  // lay tat ca gia tri tu vi tri 1 -> cuoi
        // Lập object workgroup information and add to Database
        let workgroup = {
            workgroupname: workgroupName, workgroupcode: workgroupCode, notes: workgroupNotes,
            workgroupAccountId: workgroupAccountId, workgroupExtension: workgroupExtension
        }
        let result = axios.post(`${URL_API}/workgroup/saveWorkgroup`, workgroup)
            .then(function (response) {
                console.log(response);
                setTimeout(() => {
                    toast.success('Thêm mới nhóm tài khoản thành công ' + workgroupName);
                    // setStatus('Thêm mới thành công Account: ' + username);
                    setLoadingData(true)
                    setLoadingDataMembers(false)
                    setWorkgroupName('')
                    setWorkgroupCode('')
                }, 500);
            })
            .catch(function (error) {
                console.log('Axios post response: ', error);
                console.log('Axios post response1: ', error.response.data);
                toast.warning(error.response.data.message + workgroupName);

            });
    }
    const HandleUpdateWorkgroup = () => {
        // POST request using axios inside useEffect React hook
        let updateWorkgroup = {
            workgroupname: workgroupName, workgroupcode: workgroupCode,
            notes: workgroupNotes,
        }
        axios.post(`${URL_API}/workgroup/updateWorkgroup/${workgroupCode}`, updateWorkgroup)
            .then(function (response) {
                console.log(response);
            })
            .catch(function (error) {
                console.log(error);
            });

        setTimeout(() => {
            toast.success('Cập nhật nhóm tài khoản thành công ' + workgroupName);
            // setStatus('Cập nhật thành công Account: ' + username);
            setLoadingData(true)
            setWorkgroupName('')
            setWorkgroupCode('')
        }, 1000);
    }
    const handleChangeAgentsInWorkgroup = (agent) => {
        console.log('Get Agent value: ', agent)
        setAgent(agent);
    }
    const handleChangeExtensionsInWorkgroup = (extension) => {
        console.log('Get extension value: ', extension)
        setExtension(extension)
    }
    // Lấy danh sách account
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getUsernames() {
            const result = await axios.get(`${URL_API}/account/getAllAccountUsername`, {
                headers: {
                    'x-access-token': token
                }
            });

            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i]['label'] = result.data[i]['username'];
                result.data[i].value = result.data[i].username;
                delete result.data[i]['username'];
            }
            setLoadingData(false)
            setAgents(result.data);
            console.log('Queues - get result username in Queues1: ', result.data)
        };
        getUsernames();
    }, [loadingData]);

    // Lấy danh sách extensions
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getExtensions() {
            const result = await axios.get(`${URL_API}/extension/listExten`, {
                headers: {
                    'x-access-token': token
                }
            });

            // change key name from username to label and add key name is value
            for (let i = 0; i < result.data.length; i++) {
                result.data[i]['label'] = result.data[i]['username'];
                result.data[i].value = result.data[i].username;
                delete result.data[i]['username'];
            }
            setLoadingData(false)
            setExtensions(result.data);
            // console.log('Queues - get result extesions in Queues1: ', result.data)
        };
        getExtensions();
    }, [loadingData]);

    // Lấy danh sách Members in Workgroup
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // const URL_API = process.env.REACT_APP_URL_API;
        // console.log('URL_API: ', URL_API);
        async function getMembersInWorkgroup() {
            const result = await axios.post(`${URL_API}/workgroup/getMembersInWorkgroup`, {
                headers: {
                    'x-access-token': token
                }
            });
            console.log('set data for Members in Workgroup: ', result.data)
            setLoadingData(false)
            setData(result.data);
        };
        getMembersInWorkgroup();
    }, [loadingDataMembers]);

    const handleOnChangeWorkgroupName = (event) => {
        setWorkgroupName(event.target.value);
        setWorkgroupCode(slugify(event.target.value, { replacement: '_', locale: 'vi' }))
    }
    const handleOnChangeWorkgroupNotes = (event) => {
        setWorkgroupNotes(event.target.value);
    }
    const handleShowAccountsInWorkgroup = async (row) => {
        let workgroupCode = row['row'].original.workgroupCode;
        console.log('Show workgroupCode in Workgroup: ', row['row'].original.workgroupCode)
        console.log('Show workgroupName in Workgroup: ', row['row'].original.workgroupName)
        setSelectWorkgroup(row['row'].original.workgroupCode)
        setSelectWorkgroupName(row['row'].original.workgroupName)

        const token = localStorage.getItem('accessToken');
        // POST request using axios inside useEffect React hook
        let workgroupCodeObj = { workgroupCode: workgroupCode }
        let reAccountsInWorkgroup = await axios.post(`${URL_API}/workgroup/getAccountsInWorkgroup`, workgroupCodeObj, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                // toast.success('Lấy tên members in Queue ' + queueName + ' thành công');
                console.log('Lay ten Accounts in Workgroup: ', response.data)
                console.log('isAccount: ', isAccount)
                setDataExtension([])
                setDataAccount(response.data)
                setIsAccount(true)
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Workgroups.js thông báo lỗi: ', error);
            });
    }

    const handleShowExtensionsInWorkgroup = async (row) => {
        const token = localStorage.getItem('accessToken');
        let workgroupCode = row['row'].original.workgroupCode;
        console.log('Show extensions in Workgroup: ', row['row'].original.workgroupCode)
        setSelectWorkgroup(row['row'].original.workgroupCode)
        setSelectWorkgroupName(row['row'].original.workgroupName)

        // POST request using axios inside useEffect React hook
        let workgroupCodeObj = { workgroupCode: workgroupCode }
        let reExtensionsInWorkgroup = await axios.post(`${URL_API}/workgroup/getExtensionsInWorkgroup`, workgroupCodeObj, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                // toast.success('Lấy tên members in Queue ' + queueName + ' thành công');
                console.log('Lay ten Extensions in Workgroup: ', response.data)
                console.log('Lay ten Accounts in Workgroup1: ', dataAccount)

                setIsAccount(false)
                setDataAccount([])
                console.log('Lay ten Accounts in Workgroup2: ', dataAccount)
                console.log('isAccount: ', isAccount)
                setDataExtension(response.data)
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Workgroup.js thông báo lỗi: ', error);
            });
    }

    const handleRemoveAccountInWorkgroup = (accountId) => {
        // alert('remove account in Workgroup', accountId)
        console.log('remove account in Workgroup', accountId)
        console.log('remove accountId in Workgroup', accountId['row'].original.accountId)
    }
    const handleRemoveExtensionInWorkgroup = (extension) => {
        // alert('remove extension in Workgroup', accountId)
        console.log('remove extension in Workgroup', extension)
    }
    //  add Accounts to Workgroup
    const handleAddAccountToWorkgroup = () => {
        alert('Thêm tài khoản vào nhóm')
    }
    //  add Extensions to Workgroup
    const handleAddExtensionToWorkgroup = () => {
        alert('Thêm số nội bộ vào nhóm')
    }
    // Edit Workgroup
    const handleEditWorkgroup = (event) => {
        alert('Edit workgroup')
    }
    // Delete Workgroup
    const handleDeleteWorkgroup = (event) => {
        alert('Delete workgroup')
    }
    const handleTopUpMoneyWorkgroup = (event) => {
        alert('Cộng tiền cho tất cả thành viên, số nội bộ trong Nhóm')
    }

    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                Cell: ({ row }) => (
                    <><h6>{row.index + 1}</h6></>
                ),
            },
            {
                Header: 'Tên nhóm',
                accessor: 'workgroupName',
                Filter: DefaultColumnFilter,
            },
            {
                Header: 'Nhân viên',
                accessor: 'accountIdInWorkgroup',
                Cell: ({ value, row }) =>
                    <>
                        <a href='#top' onClick={() => { handleShowAccountsInWorkgroup({ row }) }}>{(value)}</a>
                        &nbsp; &nbsp;
                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleShowModalAccount({ row }) }}>
                            <i className='fas fa-user-plus' style={{ color: '#32a871' }} title='Thêm tài khoản vào nhóm'></i>
                        </span>
                    </>,
            },
            {
                Header: 'Số nội bộ',
                accessor: 'extensionInWorkgroup',
                Cell: ({ value, row }) =>
                    <>
                        <a href='#top' onClick={() => { handleShowExtensionsInWorkgroup({ row }) }}>{(value)}</a> &nbsp; &nbsp;
                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleShowModalExtension({ row }) }}>
                            <i className='fas fa-plus' style={{ color: '#32a871' }} title='Thêm số nội bộ vào nhóm'></i>
                        </span>
                    </>,
            },
            {
                Header: 'Action',
                accessor: 'action',
                Cell: ({ row, id }) =>
                    <>{row.header}
                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleEditWorkgroup({ row }) }}>
                            <i className='fas fa-edit' title='Thay đổi thông tin nhóm'></i>
                        </span>&nbsp;
                        <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { handleDeleteWorkgroup({ row }) }}>
                            <i className='fas fa-trash-alt' title='Xóa nhóm'></i>
                        </span>&nbsp;&nbsp;
                        <span style={{ cursor: 'pointer', color: 'green' }} onClick={() => { handleTopUpMoneyWorkgroup({ row }) }}>
                            <i className='far fa-money-bill-alt' title='Cộng tiền'></i>
                        </span>
                    </>,
            },
        ]);

    const columnsWorkgroupDetail = useMemo(
        () => [
            {
                Header: 'STT',
                Cell: ({ row }) => (
                    <><h6>{row.index + 1}</h6></>
                ),
            },
            {
                Header: (isAccount === false ? 'Số nội bộ' : 'Tài khoản'),
                accessor: (isAccount === false ? 'extension' : 'accountId'),
                // accessor: 'extension'
            },
            {
                Header: 'Action',
                // accessor: 'workgroupCode',
                Cell: ({ value, row }) =>
                    <>
                        {/* <a href='#top' onClick={() => { handleShowAccountsInWorkgroup({ row }) }}>{(value)}</a> &nbsp; */}
                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { (isAccount === false) ? handleRemoveExtensionInWorkgroup({ row }) : handleRemoveAccountInWorkgroup({ row }) }}>
                            <i className='fas fa-user-minus' style={{ color: 'red' }} title='Xóa khỏi nhóm'></i>
                        </span>
                    </>,
            },
        ]);

    const handleSaveExtension = async () => {
        // alert("Đã thêm các số nội bộ vào nhóm: ")
        toast.success('Đã thay đổi số nội bộ trong nhóm thành công')
        console.log('Show selectedExtension: ', selectedExtension, ' workgroupCode: ', workgroupCode)

        const token = localStorage.getItem('accessToken');
        // POST update accountId in Workgroup
        let workgroupCodeObj = { workgroupCode: workgroupCode, workgroupExtension: selectedExtension }
        let returnExtensionsInWorkgroup = await axios.post(`${URL_API}/workgroup/addExtensionsToWorkgroup`, workgroupCodeObj, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                // toast.success('Lấy tên members in Queue ' + queueName + ' thành công');

                // setIsAccount(false)
                if (loadingDataMembers === true) {
                    setLoadingDataMembers(false)
                } else {
                    setLoadingDataMembers(true)
                }
                console.log('isAccount: ', isAccount)
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Workgroup.js thông báo lỗi: ', error);
            });

    }
    const handleSaveAccount = async () => {
        toast.success('Đã thay đổi tài khoản trong nhóm thành công')
        console.log('Show selected: ', selected, ' workgroupCode: ', workgroupCode)

        const token = localStorage.getItem('accessToken');
        // POST update accountId in Workgroup
        let workgroupCodeObj = { workgroupCode: workgroupCode, workgroupAccountId: selected }
        let returnAccountsInWorkgroup = await axios.post(`${URL_API}/workgroup/addAccountsToWorkgroup`, workgroupCodeObj, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // data.push(Object.assign({ queueName: queuename, accountInQueue: queueMember.split(',').length }));
                // toast.success('Lấy tên members in Queue ' + queueName + ' thành công');

                // setIsAccount(false)
                if (loadingDataMembers === true) {
                    setLoadingDataMembers(false)
                } else {
                    setLoadingDataMembers(true)
                }
                console.log('isAccount: ', isAccount)
                return response.data.errCode
            })
            .catch(function (error) {
                console.log('Workgroup.js thông báo lỗi: ', error);
            });
    }


    const options = agents;
    const optionsExtension = extensions;
    console.log('extensions: ', extensions)

    const handleDualListBox = (event) => {
        // alert('DualListBox: ')
        console.log('DualListBox: ', event.target.value)
    }
    const handleOnChangeDualListBox = (chon) => {
        console.log('DualListBox selected AccountId variable state: chon ', typeof (chon), '_', chon)
        setSelected(chon)
    }

    const handleOnChangeDualListBoxExtension = (selected) => {
        console.log('DualListBox selected Extension variable state: chon ', typeof (selected), '_', selected)
        setSelectedExtension(selected)
    }
    return (
        <div>
            {/* Modal show số nội bộ trong nhóm */}
            <Modal show={showExtension} onHide={handleCloseModalExtension}>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ fontSize: '20px' }}>Thêm số nội bộ vào nhóm: </span>
                        <span style={{ color: 'red' }}>{workgroupName}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <DualListBox
                                style={{ height: '300pt' }}
                                // showNoOptionsText={true}
                                showOrderButtons={true}
                                showHeaderLabels={true}
                                filterPlaceholder={'Chọn'}
                                canFilter={true}
                                options={optionsExtension}
                                selected={selectedExtension}
                                // onChange={this.onChange}
                                // onChange={(event) => { handleDualListBox(event) }}
                                onChange={(selected) => { handleOnChangeDualListBoxExtension(selected) }}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseModalExtension}>
                        Đóng
                    </Button>
                    <Button variant="success" onClick={handleSaveExtension}>
                        <i className='fas fa-save'></i>&nbsp;&nbsp;Lưu vào nhóm
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Modal show tài khoản trong nhóm */}
            <Modal show={showAccount} onHide={handleCloseModalAccount}>
                <Modal.Header closeButton>
                    <Modal.Title><span style={{ fontSize: '20px' }}>Thêm tài khoản vào nhóm:</span>
                        <span style={{ color: 'red' }}>{workgroupName}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form>
                        <Form.Group>
                            <DualListBox
                                // showNoOptionsText={true}
                                showOrderButtons={true}
                                showHeaderLabels={true}
                                filterPlaceholder={'Chọn'}
                                canFilter={true}
                                options={options}
                                selected={selected}
                                // onChange={this.onChange}
                                // onChange={(event) => { handleDualListBox(event) }}
                                onChange={(selected) => { handleOnChangeDualListBox(selected) }}
                            />
                        </Form.Group>
                        {/* <Form.Group>
                            <Button variant="secondary" onClick={() => { handleSaveAccount() }}>
                                Lưu
                            </Button>
                        </Form.Group> */}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleCloseModalAccount}>
                        Đóng
                    </Button>
                    <Button variant="success" onClick={handleSaveAccount}>
                        <i className='fas fa-save'></i>&nbsp;&nbsp;Lưu vào nhóm
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Section 2 */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-3">
                            {/* general form elements */}
                            <div className="card card-success">
                                <div className="card-header">
                                    <h3 className="card-title sm">
                                        {(updateAccount === false) ? (
                                            <span><i className='fas fa-user-plus'></i>&nbsp;&nbsp;Thêm mới nhóm tài khoản</span>
                                        ) : (
                                            <span>Cập nhật nhóm tài khoản</span>
                                        )}
                                    </h3>
                                </div>
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body" style={{ padding: '10px' }}>
                                        <div className="form-group" style={{ padding: '0px' }}>
                                            <label htmlFor="workgroupName">Tên nhóm tài khoản</label>
                                            <input type="text"
                                                onChange={(event) => { handleOnChangeWorkgroupName(event) }}
                                                value={workgroupName} className="form-control form-control-sm is-valid"
                                                id="workgroupName" placeholder="Nhập vào tên nhóm" />
                                        </div>
                                        <div className="form-group" style={{ padding: '0px' }}>
                                            <label htmlFor="workgroupCode">Mã nhóm tài khoản</label>
                                            <input type="text"
                                                onChange={(event) => { handleOnChangeWorkgroupCode(event) }}
                                                value={workgroupCode} className="form-control form-control-sm is-valid"
                                                id="workgroupCode" placeholder="Nhập vào mã nhóm" />
                                        </div>
                                        <div className="form-group" style={{ padding: '0px' }}>
                                            <label htmlFor="workgroupNotes">Mô tả nhóm tài khoản</label>
                                            <input type="text" onChange={(event) => { handleOnChangeWorkgroupNotes(event) }} value={workgroupNotes} className="form-control form-control-sm is-valid" id="workgroupNotes" placeholder="ví dụ: nhóm dự án mới" />
                                        </div>
                                        <div className="form-group" style={{ padding: '0px' }}>
                                            <label htmlFor="role">Nhân viên(Lựa chọn nhân viên vào nhóm)</label>
                                            {/* <div className="card card-success"> */}
                                            <Select
                                                value={agent}
                                                onChange={(event) => { handleChangeAgentsInWorkgroup(event) }}
                                                options={agents}
                                                getOptionValue={(option) => option.value}
                                                getOptionLabel={(option) => option.value}
                                                placeholder='Chọn nhân viên cho nhóm'
                                                noOptionsMessage={() => "Không còn nhân viên"}
                                                isClearable={false}
                                                isMulti={true} />
                                            {/* </div> */}
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="role">Số nội bộ</label>
                                            {/* <div className="card card-success"> */}
                                            <Select
                                                value={extension}
                                                onChange={(event) => { handleChangeExtensionsInWorkgroup(event) }}
                                                options={extensions}
                                                getOptionValue={(option) => option.value}
                                                getOptionLabel={(option) => option.value}
                                                placeholder='Chọn số nội bộ cho nhóm'
                                                noOptionsMessage={() => "Không còn số nội bộ"}
                                                isClearable={false}
                                                isMulti={true} />
                                            {/* </div> */}
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="button" onClick={() => {
                                            (updateAccount === false) ? HandleAddNewWorkgroup() : HandleUpdateWorkgroup()
                                        }}
                                            className="btn btn-success">
                                            {(updateAccount === false) ? (
                                                <span><i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</span>
                                            ) : (
                                                <span><i className='fas fa-sync'></i>&nbsp;&nbsp;Cập nhật</span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (left) */}
                        {/* right column */}
                        <div className="col-md-5">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"><b>Danh sách nhóm tài khoản</b></h3>
                                </div>
                                <div className="card-body p-0">
                                    <TableWorkgroup columns={columns} data={data} />
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title"><b>Nhóm tài khoản: {selectWorkgroupName}</b></h3>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body p-0">
                                    <TableWorkgroupDetail columns={columnsWorkgroupDetail} data={(isAccount === false ? dataExtension : dataAccount)} />
                                </div>
                                {/* /.card-body */}
                            </div>
                            {/* /.card */}

                        </div>
                    </div>
                </div > {/* /.container-fluid */}
            </section >
            {/* /.content */}
        </div >
    )
}

export default Workgroups