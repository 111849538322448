import React, { useState } from 'react'
import axios from 'axios';
import { toast } from 'react-toastify';
import { Navigate } from "react-router-dom";

const Login = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    // const URL_API = process.env.REACT_APP_URL_API;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async () => {
        // localStorage.setItem('accessToken', 'keykey')
        // navigate('/Dashboard');
        // window.location.reload(false);
        console.log('Username: ', username)
        // console.log('Password: ', password)

        let URL_API_SIGNIN = URL_API + '/signin'
        // console.log('URL_API/signin: ', URL_API_SIGNIN)
        if (username && password) {
            // POST request using axios inside useEffect React hook
            // console.log('Inside username && password: ', URL_API_SIGNIN)
            const account = { username: username, password: password };
            // console.log('let show:', URL_API_SIGNIN)
            let result = await axios.post(`${URL_API_SIGNIN}`, account)
                // let result = await axios.post(`${URL_API_SIGNIN}`, account, {
                //     headers: {
                //         'Accept': '*/*',
                //         'Accept-Encoding': 'gzip, deflate, br',
                //         'User-Agent': 'PostmanRuntime/7.29.0',
                //         'Connection': 'keep-alive',
                //     }
                // })
                // console.log('Result: ', response)
                .then(function (response) {
                    // alert('Role: ', response.data.role)
                    let accessToken = response.data.accessToken;
                    let refreshToken = response.data.refreshToken;
                    let tokenExpiredAt = response.data.tokenExpiredAt;
                    let username = response.data.username;
                    let fullname = response.data.fullname;
                    let extension = response.data.extension;
                    let role = response.data.role;
                    if (response) {
                        console.log('1: access Token: ', response.data.accessToken);
                        console.log('2: username: ', response.data.username)
                        console.log('3: fullname: ', response.data.fullname)
                        console.log('4: role: ', response.data.role)
                        localStorage.setItem('accessToken', accessToken)
                        localStorage.setItem('refreshToken', refreshToken)
                        localStorage.setItem('tokenExpiredAt', tokenExpiredAt)
                        localStorage.setItem('username', username)
                        localStorage.setItem('fullname', fullname)
                        localStorage.setItem('extension', extension)
                        localStorage.setItem('isAuth', 'true')
                        localStorage.setItem('role', role);

                        // return console.log('role: ', response.data.role)
                        // setTimeout(() => {
                        // setLoadingData(extension)
                        // <Navigate to="/Dashboard" replace={true} />
                        toast.success('Đăng nhập thành công');

                        window.location.reload(false);
                        // }, 10500);
                    }
                })
                .catch(function (error) {
                    // alert('Username hoặc mật khẩu không đúng')
                    toast.error('Username hoặc mật khẩu không đúng');
                });
            setTimeout(() => {
                // setLoadingData(extension)
            }, 500);
        } else {
            // alert('Username hoặc mật khẩu không thể để trống')
            toast.warning('Username hoặc mật khẩu không thể để trống');
        }

    }
    const handleOnChangeUsername = (event) => {
        setUsername(event.target.value)
        console.log('username', event.target.value)
    }
    const handleOnChangePassword = (event) => {
        setPassword(event.target.value)
        // console.log('password', event.target.value)
    }
    return (
        <div className="login-page">
            <div className='login-box'>
                <section>
                    <div className="card card-outline card-success">
                        <div className="card-header text-center">
                            <a href="../../index2.html" className="h1"><b>Quản trị</b></a>
                        </div>
                        <div className="card-body">
                            <p className="login-box-msg">Đăng nhập để bắt đầu phiên làm việc</p>
                            <div className="input-group mb-3">
                                <input type="text"
                                    className="form-control"
                                    onChange={(event) => { handleOnChangeUsername(event) }}
                                    placeholder="Username" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-user" />
                                    </div>
                                </div>
                            </div>
                            <div className="input-group mb-3">
                                <input type="password"
                                    className="form-control"
                                    onChange={(event) => { handleOnChangePassword(event) }}
                                    onKeyPress={(event) => { event.key === "Enter" && handleLogin() }}
                                    placeholder="Password" />
                                <div className="input-group-append">
                                    <div className="input-group-text">
                                        <span className="fas fa-lock" />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-7">
                                    <div className="icheck-success">
                                        <input type="checkbox" id="remember" />
                                        <label htmlFor="remember">&nbsp;
                                            Ghi nhớ
                                        </label>
                                    </div>
                                </div>
                                {/* /.col */}
                                <div className="col-5">
                                    <button onClick={() => { handleLogin() }}
                                        className="btn btn-success btn-block">
                                        Đăng nhập
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            {/* /.card */}
        </div >
    )
}

export default Login