import React, { useEffect, useState, useMemo } from 'react';
import DstGroup from './DstGroup';


const DistributeGroup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState('');
    const handleIsOpen = (event) => {
        // alert('Event: ', event)
        (isOpen) ? (
            // console.log(isOpen)
            // setIsOpen(false) && 
            console.log('Nothing', event)
        ) : (
            // setIsOpen(true) && setData('40') && 
            console.log('isisiis', event)
        )
    }
    return (
        <div>
            <div className="container-fluid" style={{ padding: 0 }}>
                <div className="row" style={{ padding: 0 }}>
                    <div className="col-md-6" style={{ padding: 0, paddingRight: 10 }}>
                        <div className="row" style={{ padding: 0 }}>
                            <div className="col-md-12">
                                {/* <TableAccount columns={columns} data={data} /> */}
                                <div className="card" style={{ padding: 0 }}>
                                    <div className="card-header">
                                        <h3 className="card-title"><b>Phân công dữ liệu đến nhóm</b></h3>
                                    </div>
                                    {/* ./card-header */}
                                    <div className="card-body" style={{ padding: 5 }}>
                                        <div className='form-group'>
                                            <div className="col-md-4" style={{ padding: 0 }}>
                                                <label style={{ fontSize: 14 }}>Tên chiến dịch</label>
                                                <select defaultValue={'agent'} value='tên chiến dịch' className="form-control form-control-sm">
                                                    <option value='Chiendich1'>Chiến dịch 1</option>
                                                    <option value='Chiendich2'>Chiến dịch 2</option>
                                                    <option value='Chiendich3'>Chiến dịch 3</option>
                                                </select>
                                            </div>
                                        </div>
                                        <table className="table table-bordered table-hover">
                                            <thead>
                                                <tr style={{ fontSize: 13 }}>
                                                    <th style={{ width: 10 }}>#</th>
                                                    <th style={{ width: 250 }}>Datasource</th>
                                                    <th style={{ width: 60, textAlign: 'center' }}>Tổng dữ liệu</th>
                                                    <th style={{ width: 60, textAlign: 'center' }}>Đã gọi</th>
                                                    <th style={{ width: 60, textAlign: 'center' }}>Chưa gọi</th>
                                                    <th style={{ width: 60, textAlign: 'center' }}>Chưa phân nhóm</th>
                                                    <th style={{ width: 40 }}>AC</th>
                                                </tr>
                                            </thead>
                                            <tbody style={{ fontSize: 13 }}>
                                                <tr onClick={(event) => { handleIsOpen(event) }} data-widget="expandable-table" aria-expanded="false" style={{ fontSize: 13 }}>
                                                    <td>1</td>
                                                    <td>Dữ liệu 1</td>
                                                    <td>300</td>
                                                    <td>200</td>
                                                    <td>60</td>
                                                    <td id='200'>100</td>
                                                    <td>
                                                        {(isOpen) ?
                                                            ( // (<i className='fa fa-fw fa-chevron-up'></i>
                                                                console.log('nothing1', isOpen)
                                                            ) : (
                                                                // <i className='fa fa-fw fa-chevron-down'></i>
                                                                console.log('nothing2', isOpen)
                                                            )
                                                        }
                                                    </td>
                                                </tr>
                                                <tr className="expandable-body">
                                                    <td colSpan={7}>
                                                        <DstGroup dataFromParent={data} />
                                                    </td>
                                                </tr>
                                                <tr data-widget="expandable-table" aria-expanded="false">
                                                    <td>2</td>
                                                    <td>Dữ liệu 2</td>
                                                    <td>4000</td>
                                                    <td>3000</td>
                                                    <td>500</td>
                                                    <td>500</td>
                                                    <td><span><i className='fa fa-fw fa-chevron-down'></i></span></td>
                                                </tr>
                                                <tr className="expandable-body">
                                                    <td colSpan={7}>
                                                        <p>
                                                            Lorem Ip bdummyok a galley survived not only five centuries,
                                                            but also the leap into electronic typesetting,
                                                            remaining essentially unchanged. It was popularised in the 1960s
                                                            with the release of Letraset sheets containing Lorem Ipsum passages,
                                                            and more recently with desktop publishing software like Aldus PageMaker
                                                            including versions of Lorem Ipsum.
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr data-widget="expandable-table" aria-expanded="false">
                                                    <td>3</td>
                                                    <td>Dữ liệu 3</td>
                                                    <td>10000</td>
                                                    <td>8000</td>
                                                    <td>1800</td>
                                                    <td>200</td>
                                                    <td><span><i className='fa fa-fw fa-chevron-down'></i></span></td>
                                                </tr>
                                                <tr className="expandable-body">
                                                    <td colSpan={7}>
                                                        <p>
                                                            Lorem Istry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr data-widget="expandable-table" aria-expanded="false">
                                                    <td>4</td>
                                                    <td>Dữ liệu 4</td>
                                                    <td>20000</td>
                                                    <td>17000</td>
                                                    <td>3000</td>
                                                    <td>0</td>
                                                    <td><span><i className='fa fa-fw fa-chevron-down'></i></span></td>
                                                </tr>
                                                <tr className="expandable-body">
                                                    <td colSpan={7}>
                                                        <p>
                                                            Lorem Ipsum isustry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr data-widget="expandable-table" aria-expanded="false">
                                                    <td>5</td>
                                                    <td>Jim Doe</td>
                                                    <td>5000</td>
                                                    <td>3000</td>
                                                    <td>2000</td>
                                                    <td>0</td>
                                                    <td><span><i className='fa fa-fw fa-chevron-down'></i></span></td>

                                                </tr>
                                                <tr className="expandable-body">
                                                    <td colSpan={7}>
                                                        <p>
                                                            Lorem Ipsum of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr data-widget="expandable-table" aria-expanded="false">
                                                    <td>6</td>
                                                    <td>Dữ liệu 6</td>
                                                    <td>10000</td>
                                                    <td>4000</td>
                                                    <td>3000</td>
                                                    <td style={{ textAlign: 'center' }}>3000</td>
                                                    <td><span><i className='fas fa-arrow-circle-right'></i></span></td>
                                                </tr>
                                                <tr className="expandable-body">
                                                    <td colSpan={7}>
                                                        <p>
                                                            Lorem Ipsutry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                                        </p>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <h3 className="card-title"><b>Phân công dữ liệu đến nhân viên</b></h3>
                                <div className="card-tools">
                                </div>
                            </div>
                            {/* /.card-header */}
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <TableAccount columns={columns} data={data} /> */}
                                    </div>
                                </div>
                                {/* /.row */}
                            </div>
                        </div>
                        {/* /.card */}
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DistributeGroup