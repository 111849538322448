function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
}) {
    const count = preFilteredRows.length

    return (
        <input style={{ width: '80px', textAlign: 'center' }}
            className='form-control form-control-sm'
            value={filterValue || ''}
            onChange={e => {
                setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
            }}
            placeholder={`Tìm ${count}`}
        />
    )
}

// const bodyData = getListExtensions;
const bodyData = [
    {}
]


export { bodyData, DefaultColumnFilter }