import React from 'react'
import Trunks from './Settings/Trunks'
import Accounts from './Settings/Accounts'
import Queues from './Settings/Queues'
import Extensions from './Settings/Extensions'
import Workgroups from './Settings/Workgroups'
import Networks from './Settings/Networks'
import Billings from './Settings/Billings'
import VIP_BlackLists from './Settings/VIP_BlackLists'


const Settings = (props) => {
    const giatri = props.Trunks;
    // console.log('Test: ', giatri)
    return (
        <div>
            <div className="content-wrapper" style={{ padding: '0px', borderRadius: '0px' }}>
                <section style={{ height: '3px' }}>
                </section>
                {/*  */}
                <section className="content" style={{ borderRadius: '1px' }}>
                    <div className="container-fluid" style={{ padding: '5px', borderRadius: '1px' }}>
                        <div className="row">
                            <div className="col-12 col-sm-12" style={{ padding: '0px', borderRadius: '1px' }}>
                                <div className="card card-success card-tabs" style={{ padding: '0px', borderRadius: '1px' }}>
                                    <div className="card-header p-0 pt-1" style={{ padding: '0px', borderRadius: '0px' }}>
                                        <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist" style={{ padding: '0px', borderRadius: '0px' }}>
                                            <li className="pt-2 px-3" style={{ padding: '0px', borderRadius: '0px', backgroundColor: 'gray' }}>
                                                <h3 className="card-title"><i className='nav-icon fas fa-wrench' style={{ color: 'yellow' }}></i>&nbsp;Cấu hình hệ thống</h3>
                                            </li>
                                            {(localStorage.getItem('role').includes('CONFIG_SYSTEM_TRUNK')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link active" id="custom-tabs-first-dauso-tab"
                                                        data-toggle="pill" href="#custom-tabs-first-dauso" role="tab"
                                                        aria-controls="custom-tabs-first-dauso" aria-selected="true">
                                                        <i className='fas fa-tty'></i>&nbsp;&nbsp;Số & Trunk</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('CONFIG_SYSTEM_QUEUE')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-fourth-queues-tab"
                                                        data-toggle="pill" href="#custom-tabs-fourth-queues" role="tab"
                                                        aria-controls="custom-tabs-fourth-queues" aria-selected="false">
                                                        <i className='far fa-snowflake'></i>&nbsp;&nbsp;Queues</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('CONFIG_SYSTEM_EXTENSION')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-fifth-sonoibo-tab"
                                                        data-toggle="pill" href="#custom-tabs-fifth-sonoibo" role="tab"
                                                        aria-controls="custom-tabs-fifth-sonoibo" aria-selected="false">
                                                        <i className='fas fa-phone-square'></i>&nbsp;&nbsp;Số nội bộ</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('CONFIG_SYSTEM_NETWORK')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-sixth-nhamang-tab"
                                                        data-toggle="pill" href="#custom-tabs-sixth-nhamang" role="tab"
                                                        aria-controls="custom-tabs-sixth-nhamang" aria-selected="false">
                                                        <i className='fas fa-broadcast-tower'></i>&nbsp;&nbsp;Nhà mạng</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('CONFIG_SYSTEM_BILLING')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-seventh-cuoc-tab"
                                                        data-toggle="pill" href="#custom-tabs-seventh-cuoc" role="tab"
                                                        aria-controls="custom-tabs-seventh-cuoc" aria-selected="false">
                                                        <i className='fab fa-btc'></i>&nbsp;&nbsp;Tính cước</a>
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('CONFIG_SYSTEM_VIPBLACK')) &&
                                                <li className="nav-item">
                                                    <a className="nav-link" id="custom-tabs-eight-vip-tab"
                                                        data-toggle="pill" href="#custom-tabs-eight-vip" role="tab"
                                                        aria-controls="custom-tabs-eight-vip" aria-selected="false">
                                                        <i className='far fa-smile'></i>&nbsp;&nbsp;VIP/Blacklist</a>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                    <div className="card-body" style={{ padding: '10px' }}>
                                        <div className="tab-content" id="custom-tabs-five-tabContent">
                                            <div className="tab-pane show active" id="custom-tabs-first-dauso"
                                                role="tabpanel" aria-labelledby="custom-tabs-first-dauso-tab">
                                                <Trunks />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-fourth-queues"
                                                role="tabpanel" aria-labelledby="custom-tabs-fourth-queues-tab">
                                                <Queues />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-fifth-sonoibo"
                                                role="tabpanel" aria-labelledby="custom-tabs-fifth-sonoibo-tab">
                                                <Extensions />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-sixth-nhamang"
                                                role="tabpanel" aria-labelledby="custom-tabs-sixth-nhamang-tab">
                                                <Networks />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-seventh-cuoc"
                                                role="tabpanel" aria-labelledby="custom-tabs-seventh-cuoc-tab">
                                                <Billings />
                                            </div>
                                            <div className="tab-pane" id="custom-tabs-eight-vip"
                                                role="tabpanel" aria-labelledby="custom-tabs-eight-vip-tab">
                                                <VIP_BlackLists />
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default Settings