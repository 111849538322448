import React from 'react'

const Footer = () => {
    return (
        <div>
            {/* Main Footer */}
            <footer className="main-footer" style={{ display: 'none' }}>
                <strong>Copyright © 2014-2022 <a href="https://fla.vietpbx.com">Việt Nam CallCenter</a>.</strong>
                &nbsp;All rights reserved.
                <div className="float-right d-none d-sm-inline-block">
                    <b>Version</b> 3.2.0
                </div>
            </footer>
        </div>
    )
}

export default Footer