// import * as React from 'react';
import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Dashboard from './Dashboard'
import Recordings from './Recordings'
import RecordingToday from './RecordingToday'
import Reportings from './Reportings'
import Campaigns from './Campaigns'
import Billings from './Billings'
import Settings from './Settings'
import SettingAccounts from './SettingAccounts'
import Summary from './Reportings/Summary'
import Calls from './Reportings/Calls'
import Hours from './Reportings/Hours'
import Agents from './Reportings/Agents'
import PerformanceByExtensions from './Reportings/PerformanceByExtensions'
import CallDetails from './Reportings/CallDetails'
import Agent from './Agents/Agent'
import Outbound from './Agents/Outbound'
import Supervisor from './Supervisors/Supervisor'

const SideNav = () => {
    return (
        <Router>
            <div>
                {/* Main Sidebar Container */}
                <aside className="main-sidebar sidebar-dark-success elevation-4">
                    {/* Brand Logo */}
                    <a href="/Dashboard" className="brand-link">
                        <img src="../../fla.jpg" alt="FLA Logo" className="brand-image img-circle elevation-3" style={{ opacity: '100' }} />
                        {/* <img src="https://hopnhat.com/images/Logo-HNC-TV.png" alt="HNC Logo" className="brand-image elevation-3" style={{ opacity: '100' }} /> */}
                        <span className="brand-text font-weight-light">Call Center</span>
                    </a>
                    {/* Sidebar */}
                    <div className="sidebar">
                        <section style={{ height: '15px' }}>
                        </section>
                        <div className="form-inline">
                            <div className="input-group" data-widget="sidebar-search">
                                <input className="form-control form-control-sidebar" type="search" placeholder="Search" aria-label="Search" />
                                <div className="input-group-append">
                                    <button className="btn btn-sidebar">
                                        <i className="fas fa-search fa-fw" />
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* Sidebar Menu */}
                        <nav className="mt-2">
                            <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                                {/* Add icons to the links using the .nav-icon class with font-awesome or any other icon font library */}
                                {(localStorage.getItem('role') === 'DASHBOARD_') &&
                                    <li className="nav-item menu-open">
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                {/* <a href="./Dashboard" className="nav-link"> */}
                                                <Link className="nav-link" to="/Dashboard">
                                                    <i className="nav-icon fas fa-tachometer-alt" />
                                                    <p>Dashboard</p>
                                                </Link>
                                                {/* </a> */}
                                            </li>
                                        </ul>
                                    </li>
                                }
                                {/* Agent */}
                                {(localStorage.getItem('role').includes("AGENT_")) &&
                                    <li className="nav-item menu-open">
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                {/* <a href="/Agent" className="nav-link"> */}
                                                <Link className="nav-link" to="/Agents/Outbound">
                                                    <i className="nav-icon fas fa-phone" style={{ color: 'green' }} />
                                                    <p>
                                                        Nhân viên
                                                    </p>
                                                </Link>
                                                {/* </a> */}
                                            </li>
                                        </ul>
                                    </li>
                                }
                                {/* Supervisor */}
                                {/* {(localStorage.getItem('role').includes('SUP_ALLOW')) && */}
                                {(localStorage.getItem('role').includes('SUP_')) &&
                                    < li className="nav-item menu-open">
                                        <ul className="nav nav-treeview">
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/Supervisors/Supervisor">
                                                    <i className="nav-icon fas fa-desktop" />
                                                    <p>
                                                        Giám sát
                                                    </p>
                                                </Link>
                                            </li>
                                        </ul>
                                    </li>
                                }
                                {/* Reportings */}
                                {(localStorage.getItem('role').includes('REPORT_')) &&
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/Reportings/Summary">
                                            <i className="nav-icon fas fa-book" />
                                            <p>
                                                Báo cáo
                                                <i className="fas fa-angle-left right" />
                                                <span className="badge badge-info right">6</span>
                                            </p>
                                        </Link>
                                        <ul className="nav nav-treeview">
                                            {(localStorage.getItem('role').includes('REPORT_SUMMARY')) &&
                                                <li className="nav-item">
                                                    {/* <a href="/Reportings/Summary" className="nav-link"> */}
                                                    <Link className="nav-link" to="/Reportings/Summary">
                                                        <i className="far fa-circle nav-icon" />
                                                        <p>Tổng hợp</p>
                                                    </Link>
                                                    {/* </a> */}
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('REPORT_CALL_BY_DATE')) &&
                                                <li className="nav-item">
                                                    {/* <a href="pages/layout/top-nav-sidebar.html" className="nav-link"> */}
                                                    <Link className="nav-link" to="/Reportings/Calls">
                                                        <i className="far fa-circle nav-icon" />
                                                        <p>Cuộc gọi theo ngày</p>
                                                    </Link>
                                                    {/* </a> */}
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('REPORT_CALL_BY_HOUR')) &&
                                                <li className="nav-item">
                                                    {/* <a href="pages/layout/top-nav-sidebar.html" className="nav-link"> */}
                                                    <Link className="nav-link" to="/Reportings/Hours">
                                                        <i className="fas fa-hourglass-half nav-icon" />
                                                        <p>Cuộc gọi theo múi giờ</p>
                                                    </Link>
                                                    {/* </a> */}
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('REPORT_CALL_PER_ACCOUNT')) &&
                                                <li className="nav-item">
                                                    {/* <a href="pages/layout/boxed.html" className="nav-link"> */}
                                                    <Link className="nav-link" to="/Reportings/Agents">
                                                        <i className="far fa-circle nav-icon" />
                                                        <p>Năng suất tài khoản</p>
                                                    </Link>
                                                    {/* </a> */}
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('REPORT_CALL_PER_EXTENSION')) &&
                                                <li className="nav-item">
                                                    {/* <a href="pages/layout/boxed.html" className="nav-link"> */}
                                                    <Link className="nav-link" to="/Reportings/PerformanceByExtensions">
                                                        <i className="far fa-circle nav-icon" />
                                                        <p>Năng suất số nội bộ</p>
                                                    </Link>
                                                    {/* </a> */}
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('REPORT_CALL_DETAIL')) &&
                                                <li className="nav-item">
                                                    {/* <a href="pages/layout/fixed-sidebar.html" className="nav-link"> */}
                                                    <Link className="nav-link" to="/Reportings/CallDetails">
                                                        <i className="far fa-circle nav-icon" />
                                                        <p>Chi tiết cuộc gọi</p>
                                                    </Link>
                                                    {/* </a> */}
                                                </li>
                                            }
                                            {(localStorage.getItem('role').includes('REPORT_CALL_DETAIL_OWNER')) &&
                                                <li className="nav-item">
                                                    {/* <a href="pages/layout/fixed-sidebar.html" className="nav-link"> */}
                                                    <Link className="nav-link" to="/Reportings/OwnerCallDetails">
                                                        <i className="far fa-circle nav-icon" />
                                                        <p>Chi tiết cuộc gọi owner</p>
                                                    </Link>
                                                    {/* </a> */}
                                                </li>
                                            }
                                        </ul>
                                    </li>
                                }
                                {/* Recordings Today */}
                                {(localStorage.getItem('role').includes('RECORDING_')) &&
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/RecordingToday">
                                            <i className="nav-icon fas fa-circle red-text" style={{ color: 'red' }} />
                                            <p>
                                                Ghi âm trong ngày
                                            </p>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                }
                                {/* Recordings */}
                                {(localStorage.getItem('role').includes('RECORDING_')) &&
                                    <li className="nav-item">
                                        {/* <a href="/Recordings" className="nav-link"> */}
                                        <Link className="nav-link" to="/Recordings">
                                            <i className="nav-icon fas fa-circle red-text" style={{ color: 'red' }} />
                                            <p>
                                                Ghi âm
                                            </p>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                }
                                {/* Campaign - Chiến dịch */}
                                {(localStorage.getItem('role').includes('supervisor')) &&
                                    <li className="nav-item">
                                        {/* <a href="/Data" className="nav-link"> */}
                                        <Link className="nav-link" to="/Campaigns">
                                            <i className="nav-icon fas fa-edit" />
                                            <p>
                                                Chiến dịch
                                            </p>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                }
                                {/* Campaign - Chiến dịch */}
                                {(localStorage.getItem('role').includes('BILLING_')) &&
                                    <li className="nav-item">
                                        {/* <a href="/Data" className="nav-link"> */}
                                        <Link className="nav-link" to="/Billings">
                                            <i className="far fa-money-bill-alt nav-icon" style={{ color: '#5ae676' }} />
                                            <p>
                                                Tính cước
                                            </p>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                }
                                {/* Settings system - cấu hình hệ thống*/}
                                {(localStorage.getItem('role').includes('CONFIG_SYSTEM_')) &&
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/Settings">
                                            <i className="nav-icon fas fa-wrench" />
                                            <p>
                                                Cấu hình hệ thống
                                            </p>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                }
                                {/* Settings accounts- cấu hình người dùng*/}
                                {(localStorage.getItem('role').includes('CONFIG_ACCOUNT_')) &&
                                    <li className="nav-item">
                                        {/* <a href="/Settings" className="nav-link"> */}
                                        <Link className="nav-link" to="/SettingAccounts">
                                            <i className="nav-icon fas fa-user-cog" />
                                            <p>
                                                Cấu hình người dùng
                                            </p>
                                        </Link>
                                        {/* </a> */}
                                    </li>
                                }
                            </ul>
                        </nav>
                        {/* /.sidebar-menu */}
                    </div>
                </aside>
                {/* Route */}
                <Routes>
                    <Route path="/Dashboard" element={<Dashboard />} />
                    <Route path="/Recordings" element={<Recordings />} />
                    <Route path="/RecordingToday" element={<RecordingToday />} />
                    <Route path="/Reportings" element={<Reportings />} />
                    <Route path="/Campaigns" element={<Campaigns />} />
                    <Route path="/Billings" element={<Billings />} />
                    <Route path="/Settings" element={<Settings />} />
                    <Route path="/SettingAccounts" element={<SettingAccounts />} />
                    <Route path="/Settings/Trunks" element={<Settings />} />
                    <Route path="/Reportings/Summary" element={<Summary />} />
                    <Route path="/Reportings/Calls" element={<Calls />} />
                    <Route path="/Reportings/Hours" element={<Hours />} />
                    <Route path="/Reportings/Agents" element={<Agents />} />
                    <Route path="/Reportings/PerformanceByExtensions" element={<PerformanceByExtensions />} />
                    <Route path="/Reportings/CallDetails" element={<CallDetails />} />
                    <Route path="/Agents/Agent" element={<Agent />} />
                    <Route path="/Agents/Outbound" element={<Outbound />} />
                    <Route path="/Supervisors/Supervisor" element={<Supervisor />} />
                    <Route
                        path="*"
                        element={
                            <main style={{ padding: "1rem" }}>
                                <p>There's nothing here!</p>
                            </main>
                        }
                    />
                </Routes>
            </div >
        </Router >
    )
}

export default SideNav