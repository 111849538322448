import Header from './components/Header'
import Footer from './components/Footer'
import SideNav from './components/SideNav'
import Login from './components/Login'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  let isAuth = localStorage.getItem("isAuth");

  return (
    <>
      {
        isAuth ?
          <>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <ToastContainer />
            <Header />
            <SideNav />
            <Footer />
          </>
          :
          <>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <ToastContainer />
            <Login />
          </>
      }

      {/* <Login /> */}
    </>
  );
}

export default App;
