import React, { useState, useMemo, useEffect } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from 'axios';
import moment from 'moment';
import { saveAs } from "file-saver";
import { convert12to24, convertComplete, signOut, convertDate } from './Utils'
import TableRecordings from './TableRecordings'
import { useDispatch, useSelector } from 'react-redux'
import { addNewHobby } from '../components/Actions/hobby'
import { getListExtension, addNewExtension } from './Actions/extension';


const RecordingToday = () => {
    const listExtension = useSelector(state => state.extension.list)
    const dispatch = useDispatch();
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    // let startDate = moment().startOf('hour').toDate();
    let startDate = moment().subtract(0, 'days').startOf('day').format("MM/DD/YYYY 00:00");
    let endDate = moment().subtract(0, 'days').startOf('day').format("MM/DD/YYYY 23:59");
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [playFile, setPlayFile] = useState(false);
    const [recordingId, setRecordingId] = useState('');
    const [recordingURL, setRecordingURL] = useState('');
    // const [status, setStatus] = useState('');
    const [thoigian, setThoigian] = useState(startDate + ' - ' + endDate);
    const [phone, setPhone] = useState('');
    const [agentId, setAgentId] = useState('all')
    const [queue, setQueue] = useState('all')
    const [extension, setExtension] = useState('all')
    const [exten, setExten] = useState([])
    const [username, setUsername] = useState([])
    const headerData = [
        {
            Header: 'STT',
            accessor: 'id',
            Filter: false,
            Cell: ({ row }) => (
                <>
                    <span>{row.index + 1}</span>
                </>
            ),
        },
        {
            Header: 'Thời gian',
            accessor: row => convertDate(row.started_time),
        },
        {
            Header: 'Số điện thoại',
            accessor: 'phone',
        },
        {
            Header: 'Waiting',
            accessor: 'wait_time',
        },
        {
            Header: 'Talktime',
            accessor: 'talk_time',
        },
        {
            Header: 'AgentID',
            accessor: 'agent_id',
        },
        {
            Header: 'Nhóm',
            accessor: 'queue_name',
        },
        {
            Header: 'Số nội bộ',
            accessor: 'extension',
        },
        {
            Header: 'Hướng',
            accessor: 'direction',
        },
        {
            Header: 'Ngắt máy',
            accessor: row => convertComplete(row.disconnect),
        },
        {
            Header: 'Action',
            Filter: false,
            // accessor: 'call_id',
            Cell: ({ row }) =>
                <>
                    {((playFile === true) && (recordingId === row['original'].call_id)) ? (
                        <span>
                            <audio style={{ width: 170, height: 22 }} controls src={recordingURL} autoPlay /></span>
                    ) : (
                        <span style={{ cursor: 'pointer' }} onClick={() => { handlePlay({ row }) }}><i className='fas fa-play' title='Nghe ghi âm' style={{ color: 'green' }}></i></span>
                    )
                    }
                    &nbsp;&nbsp;&nbsp;
                    <span style={{ cursor: 'pointer' }} onClick={() => { handleDownloadFile({ row }) }}>
                        <i className='fas fa-download' title='Download file ghi âm' style={{ color: 'brown' }}></i>
                    </span>
                </>,
        },
    ]

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        let result = [];
        let URL_API_LISTEXTEN = URL_API + '/extension/listExten'
        async function getExtensions() {
            result = await axios(`${URL_API_LISTEXTEN}`, {
                headers: {
                    'x-access-token': token
                }
            });
            setExten(result.data);
            console.log('connect DB and get list extension: ', result.data);
            for (let i = 0; i < result.data.length; i++) {
                const action = addNewExtension(result.data[i])
                dispatch(action)
            }
        };
        if (listExtension && Object.keys(listExtension).length === 0) {
            getExtensions();
        } else {
            // for (let i = 0; i < result.data.length; i++) {
            //     const action = getListExtension(result.data[i])
            //     dispatch(action)
            // }
            setExten(listExtension);
            console.log('get from redux store: ', listExtension);
        }
    }, []);
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        let URL_API_GETALLACCOUNTUSERNAME = URL_API + '/account/getAllAccountUsername'
        async function getUsername() {
            const result = await axios(`${URL_API_GETALLACCOUNTUSERNAME}`, {
                headers: {
                    'x-access-token': token
                }
            });
            setUsername(result.data);
        };
        getUsername();
    }, []);
    const handleDownloadFile = (row) => {
        const token = localStorage.getItem('accessToken');
        let call_id = row['row'].original.call_id;
        let started_time = row['row'].original.started_time;
        let action = 'download';
        console.log('Started_time: ', row['row'].original.started_time)
        console.log('Call_Id: ', row['row'].original.call_id)
        console.log('action: ', action)
        async function getRecordingURL(callID, startedTime, action) {
            let URL = `${URL_API}/recording/getRecordingFile/${startedTime}/${callID}/${action}`
            const result = await axios.get(URL, {
                headers: {
                    'x-access-token': token
                }
            });
            let downloadFile = result.data.recUrl;
            console.log('result data: ', result)
            setLoadingData(false);
            // var FileSaver = require('file-saver');
            if (result.data.Found === 1) {
                setPlayFile(false)
                setRecordingId(callID)
                setRecordingURL(result.data.recUrl)
                console.log('recUrl: ', result.data)
                console.log('fileName: ', result.data.fileName)
                setTimeout(() => {
                    saveAs(downloadFile, `${result.data.fileName}`);
                }, 1000);
            }
            return 'Đã lấy được file';
        };
        getRecordingURL(call_id, started_time, action);
    }
    const handlePlay = (row) => {
        const token = localStorage.getItem('accessToken');
        let call_id = row['row'].original.call_id;
        let started_time = row['row'].original.started_time;
        let action = 'play';
        console.log('Started_time: ', row['row'].original.started_time)
        console.log('Call_Id: ', row['row'].original.call_id)
        console.log('action: ', action)
        async function getRecordingURL(callID, startedTime, action) {
            let URL = `${URL_API}/recording/getRecordingFile/${startedTime}/${callID}/${action}`
            const result = await axios.get(URL, {
                headers: {
                    'x-access-token': token
                }
            });
            // setData(result.data);
            setLoadingData(false);
            // setStatus();
            if (result.data.Found === 1) {
                setPlayFile(true)
                setRecordingId(callID)
                setRecordingURL(result.data.recUrl)
            }
            return 'Đã lấy được file';
        };
        getRecordingURL(call_id, started_time, action);
    }
    const columns = useMemo(
        () => headerData);

    const handleOnSearch = async () => {
        const token = localStorage.getItem('accessToken');
        setRecordingURL('')
        setPlayFile(false)
        if (!thoigian) {
            setThoigian("26/04/2022 08:00 PM - 28/04/2022 04:00 AM");
            let start = startDate;
            let end = endDate;
            console.log('Start: ', start)
            console.log('End: ', end)
        }
        if (!agentId) {
            setAgentId("all");
        }
        if (!queue) {
            setQueue("all");
        }
        let dates = thoigian.split(" - ");
        let start = convert12to24(dates[0]);
        let end = convert12to24(dates[1]);;

        console.log('Ngay thang nam ', dates)
        console.log('Thoi gian bắt đầu: ', dates[0], ' Unixtime: ', start)
        console.log('Thời gian kết thúc: ', dates[1], ' Unixtime: ', end)
        console.log('Phone: ', phone)
        console.log('AgentID: ', agentId)
        console.log('Extension: ', extension)
        console.log('call_status: ', 'ANSWERED')
        try {
            let URL_API_SEARCHRECORDINGTODAY = URL_API + '/recording/searchRecordingToday'
            const searchRecordingToday = { start_time: start, end_time: end, phone: phone, agent_id: agentId, queue_name: queue, extension: extension, call_status: 'ANSWERED', direction: '' }
            if (token) {
                // Truyền giá trị vào API để lấy data
                let result = await axios.post(`${URL_API_SEARCHRECORDINGTODAY}`, searchRecordingToday, {
                    headers: {
                        'x-access-token': token
                    }
                });
                setData(result.data)
                setLoadingData(false);
                console.log('data type 1: ', result.data);
                console.log('data type 2: ', result.data.length);
                for (let i = 0; i < result.data.length; i++) {
                    const action = addNewHobby(result.data[i])
                    dispatch(action)
                }
            }
        } catch (err) {
            console.log('Get error: ', err)
            console.log('Get error response: ', err.response)
            console.log('Get error response.data: ', err.response.data)
            console.log('Get error response.data.errMsg: ', err.response.data.errMsg)
            if (err.response.data.errMsg === 'jwt expired') {
                console.log('Token was expired')
                signOut();
            } else {
                console.log('Recordings.js Error in 266: ', err)
            }
        }
    }
    const handleOnChangeThoiGian = (event) => {
        setThoigian(event.target.value);
        console.log("handleChange: ", thoigian)
    };
    const handleOnChangePhone = (event) => {
        if (!event) {
            setPhone('all');
        }
        setPhone(event.target.value);
        console.log("handleOnChangePhone: ", phone)
    };
    const handleOnChangeAgentId = (event) => {
        // const valueOfInput = setValue(event.target.value);
        if (!event) {
            setAgentId('all');
        }
        setAgentId(event.target.value);
        console.log("handleOnChangeAgentId: ", agentId)
    };
    const handleOnChangeQueue = (event) => {
        if (!event) {
            setQueue('all');
        }
        setQueue(event.target.value);
        console.log("handleOnChangeQueue: ", queue)
    };
    const handleOnChangeExtension = (event) => {
        setExtension(event.target.value);
        console.log("handleOnChangeExtension: ", extension)
    }
    const handleCallback = (thoi) => {
        // alert('handlCallback: ');
        setThoigian(thoi);
        console.log("Handle Callback: ", thoigian)
    }
    return (
        <div>
            <div className="content-wrapper">
                {/* Content Header (Page header) */}
                <section style={{ height: '15px' }}>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* /.card */}
                                <div className="card">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label className="col-form-label col-form-label-sm">Thời gian ghi âm</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="far fa-clock" /></span>
                                                        </div>
                                                        {/* <input type="text" className="form-control form-control-sm float-right" id="reservationtime" /> */}
                                                        <DateRangePicker
                                                            className="form-control form-control-sm float-right" disabled='true'
                                                            onEvent={(event) => handleOnChangeThoiGian(event)} value={thoigian}
                                                            onCallback={(event) => handleCallback(event.timePicker)}
                                                            initialSettings={{
                                                                timePicker: true,
                                                                // startDate: moment().startOf('hour').toDate(),
                                                                // endDate: moment().startOf('hour').add(32, 'hour').toDate(),
                                                                startDate: startDate,
                                                                endDate: endDate,
                                                                locale: {
                                                                    format: 'MM/DD/YYYY hh:mm A',
                                                                },
                                                            }}
                                                        >
                                                            <input type="text" className="form-control col-12  form-control-sm" />
                                                        </DateRangePicker>
                                                        {/* <input type="text" className="form-control col-6" onChange={(event) => handleChange(event)} value={thoigian} /> */}
                                                        {/* <input type="button" value="click here" onClick={() => { handleDateTime() }} /> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">Phone</label>
                                                        <input type="text" onChange={(event) => handleOnChangePhone(event)} value={phone} className="form-control form-control-sm" placeholder="Nhập SĐT..." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">Agent ID</label>
                                                        <select defaultValue={'all'} onChange={(event) => { handleOnChangeAgentId(event) }} className='form-control form-control-sm'>
                                                            <option value='all'>Tất cả</option>
                                                            {
                                                                username.map((item, i) => <option key={i} value={item.username}>{item.username}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">Nhóm</label>
                                                        <select defaultValue={'all'} onChange={(event) => { handleOnChangeQueue(event) }} className="form-control form-control-sm">
                                                            <option value='all'>Tất cả</option>
                                                            <option value='kythuat'>Kỹ thuật</option>
                                                            <option value='kinhdoanh'>Kinh doanh</option>
                                                            <option value='ketoan'>Kế toán</option>
                                                            <option value='nhansu'>Nhân sự</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">Extension</label>
                                                        <select style={{ transitionAll: 0 }} defaultValue='all' onChange={(event) => { handleOnChangeExtension(event) }} className="form-control form-control-sm">
                                                            <option value='all'>Tất cả</option>
                                                            {
                                                                exten.map((item, i) => <option key={i} value={item.username}>{item.username}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">&nbsp;</label>
                                                        <button type="submit" onClick={() => { handleOnSearch() }}
                                                            className="btn btn-success form-control form-control-sm btn-sm">
                                                            <i className="fas fa-search fa-fw" />&nbsp; Tìm kiếm</button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TableRecordings columns={columns} data={data} />
                                            </div>
                                        </div>
                                        {/* /.row */}
                                    </div>
                                    {/* /.card-body */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}
                    </div>
                    {/* /.container-fluid */}
                </section>
                {/* /.content */}
            </div >
        </div >
    )
}

export default RecordingToday