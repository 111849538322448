import React, { useEffect, useState, useMemo } from 'react'
import TableSuperQueues from './TableSuperQueues';
import Header from '../Header';
import axios from 'axios';
import socket from '../socketConnect';

const SuperQueues = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API

    const [queues, setQueues] = useState([]);
    const handleQueueShow = () => {
        alert('get list queue and member inside')
    }

    useEffect(() => {
        console.log('socket.connected in SuperCalls: ', socket.connected)
        if (socket.connected !== true) {
            socket.open((err) => {
                if (err) {
                    // an error has occurred
                    console.log('Thiet lập kết nối không thành công: ', err)
                } else {
                    // the connection was successfully established
                    console.log('Thiet lập kết nối thành công: ')
                    console.log('socket connected: ', socket.connected); // true
                    console.log('connect socket_id 1: ', socket.id)
                }
            });
            socket.on("connect", () => {
                console.log('connect socket_id 2: ', socket.id)
            });
        }
        socket.on("connect_error", (err) => {
            console.log('connect_error : socket_id: ', socket.id)
            console.log(`socket listen error due to ${err.message}`);
        });
        socket.on('CallsEvent', async function (da) {
            // console.log('CallsEvent: socket_id: ', socket.id)
            // console.log('Listen data in SuperCalls: ', calls)
            // console.log('Event0: ', da.event_name)
            if (da.event_name === 'newchannel') {
                const newObj1 = await queues.push(da);
                console.log('newchannel: ', newObj1, queues)
            }
            // Bắt đầu queuecallerjoin ==> bat dau do cuoc goi vao Queue
            if (da.event_name === 'queuecallerjoin') {
                console.log('queuecallerjoin: ', da, ' _data.length: ', queues.length)
                if (queues.length > 0) {
                    // console.log('SuperCalls Truoc khi tim: ', Math.floor(new Date().getTime()))
                    for (let i = 0; i < queues.length; i++) {
                        try {
                            console.log('So thu tu : ', i)
                            // console.log('data[i].call_id: ', calls[i].call_id)
                            if (queues[i].call_id === da.call_id) {
                                queues[i].status = da.status
                                queues[i].event_name = da.event_name
                                queues[i].queue = da.queue
                                queues[i].agent_id = da.agent_id
                                queues[i].to = da.to
                                queues[i].wait_time = Math.floor(new Date().getTime() / 1000) - da.wait_time
                                console.log('Waiting time data[i].status: ', i,
                                    queues[i].status, ' data[i]: ', queues[i]);
                                break;
                            } else {
                                console.log('before set data queuecallerjoin : ', queues)
                            }
                        } catch (e) {
                            console.error('queuecallerjoin: Hiển thị thông tin error: ', e);
                        }
                    }
                }
            }
            if (da.event_name === 'bridgeenter') {
                // let data = new Array();
                console.log('Insert Loop all: ', da, ' _data.length: ', queues.length)
                if (queues.length > 0) {
                    for (let i = 0; i < queues.length; i++) {
                        try {
                            console.log('data[0].call_id: ', queues[0].call_id)
                            console.log('data[i].call_id: ', queues[i].call_id)
                            if (queues[i].call_id === da.call_id) {
                                queues[i].status = da.status
                                queues[i].event_name = da.event_name
                                queues[i].queue = da.queue
                                queues[i].agent_id = da.agent_id
                                queues[i].to = da.to
                                // data[i].wait_time = Math.floor(new Date().getTime() / 1000) - da.wait_time
                                queues[i].talk_time = Math.floor(new Date().getTime() / 1000) - da.talk_time
                                console.log('Cap nhat trang thai connected time data[i].status: ', i,
                                    queues[i].status, ' data[i]: ', queues[i]);
                                break;
                            } else {
                                console.log('before set data: ', queues)
                            }
                        } catch (e) {
                            console.error('Hiển thị thông tin error: ', e);
                        }
                    }
                }
            }
            if (da.event_name === 'hangup') {
                for (let i = 0; i < queues.length; i++) {
                    console.log('Loop all: ', queues[i])
                    if (queues[i].call_id === da.call_id) {
                        queues.splice(i, 1)
                        // console.log('gia tri tuong dong: ', i, da.event_name);
                        break;
                    }
                }
                setQueues(queues);
            }
        });

        return () => {
            socket.on("disconnect", (reason) => {
                console.log('disconnect socketid: ', socket.id); // undefined
                console.log('disconnect reason: ', reason);
                socket.disconnect(true);
            });
            // before the component is destroyed
            // unbind all event handlers used in this component
            socket.off("CallsEvent", function () {
                console.log('socket off: ')
            });
            socket.off("connect_error", function () {
                console.log('socket off connect_error: ')
            });
            socket.off("connect", function () {
                console.log('socket off connect: ')
            });
        };
    }, [])

    const headerData = [
        {
            Header: 'STT',
            accessor: 'id',
            Filter: false,
            Cell: ({ row }) => (
                <>
                    <span>{row.index + 1}</span>
                </>
            ),
        },
        {
            Header: 'Tên nhóm',
            accessor: 'call_id'
        },
        {
            Header: 'Nhân viên',
            // accessor: row => convertDate(row.started_time),
            accessor: 'duration',
            Cell: row => (
                <>
                    <span>
                        {/* {new Date((row.value + seconds) * 1000).toISOString().substring(11, 19)} */}
                        {(row.value > 0) ? (Math.floor(new Date().getTime() / 1000) - (row.value)) : ""}
                        {/* {(Math.floor(new Date().getTime() / 1000) - (row.value))} */}
                    </span>
                </>
            ),
            // accessor: {Math.floor(new Date().getTime() / 1000)}'time_id'
        },
        {
            Header: 'Số nội bộ',
            accessor: 'from',
        },
        {
            Header: 'Trạng thái',
            accessor: 'talk_time',
            Cell: row => (
                <>
                    <span>
                        {/* {{(row.value === '0') ? "" : new Date((row.value + seconds) * 1000).toISOString().substring(11, 19)} */}
                        {(row.value > 0) ? (Math.floor(new Date().getTime() / 1000) - (row.value)) : ""}
                        {/* {(Math.floor(new Date().getTime() / 1000) - (row.value))} */}
                    </span>
                </>
            ),
        },
        {
            Header: 'Lí do',
            accessor: 'event_name',
        },
        {
            Header: 'Kỹ năng',
            accessor: 'status',
        },
        // {
        //     Header: 'Action',
        //     Filter: false,
        //     // accessor: 'call_id',
        //     Cell: ({ row }) =>
        //         <>
        //             {/* <span style={{ cursor: 'pointer' }} onClick={() => handleListen(row.original)}><button><i className="fas fa-user-secret" title='Nghe xen' style={{ color: 'green' }} /></button></span>&nbsp;
        //             <span style={{ cursor: 'pointer' }} onClick={() => handleCoach(row.original)}><button><i className="fas fa-user-friends" title='Nhắc nhở' style={{ color: 'blue' }} /></button></span>&nbsp;
        //             <span style={{ cursor: 'pointer' }} onClick={() => handleConf(row.original)}><button><i className="fas fa-users" title='Hội thoại' style={{ color: 'blue' }} /></button></span>&nbsp;
        //             <span style={{ cursor: 'pointer' }} onClick={() => handleDisconnect(row.original)}><button><i className="fas fa-phone-slash" title='Ngắt cuộc gọi' style={{ color: 'red' }} /></button></span> */}
        //         </>,
        // },
    ]
    const headerDataSummary = [
        {
            Header: 'STT',
            accessor: 'id',
            Filter: false,
            Cell: ({ row }) => (
                <>
                    <span>{row.index + 1}</span>
                </>
            ),
        },
        {
            Header: 'Tên nhóm',
            accessor: 'call_id',
            Filter: false,
        },
        {
            Header: 'Cuộc gọi chờ',
            // accessor: row => convertDate(row.started_time),
            accessor: 'duration',
            Filter: false,
            Cell: row => (
                <>
                    <span>
                        {/* {new Date((row.value + seconds) * 1000).toISOString().substring(11, 19)} */}
                        {(row.value > 0) ? (Math.floor(new Date().getTime() / 1000) - (row.value)) : ""}
                        {/* {(Math.floor(new Date().getTime() / 1000) - (row.value))} */}
                    </span>
                </>
            ),
            // accessor: {Math.floor(new Date().getTime() / 1000)}'time_id'
        },
        {
            Header: 'Nhân viên',
            accessor: 'from',
            Filter: false,
        },
        {
            Header: 'Sẵn sàng',
            accessor: 'talk_time',
            Filter: false,
            Cell: row => (
                <>
                    <span>
                        {/* {{(row.value === '0') ? "" : new Date((row.value + seconds) * 1000).toISOString().substring(11, 19)} */}
                        {(row.value > 0) ? (Math.floor(new Date().getTime() / 1000) - (row.value)) : ""}
                        {/* {(Math.floor(new Date().getTime() / 1000) - (row.value))} */}
                    </span>
                </>
            ),
        },
        {
            Header: 'Không sẵn sàng',
            accessor: 'event_name',
            Filter: false,
        },
        {
            Header: 'Tạm dừng',
            accessor: 'status',
            Filter: false,
        },
        {
            Header: 'Lý do',
            accessor: 'aaa',
            Filter: false,
        },
        {
            Header: 'Trả lời',
            accessor: 'stabbbbtus',
            Filter: false,
        },
    ]

    const columns = useMemo(
        () => headerData);

    const columnsSummary = useMemo(
        () => headerDataSummary);

    return (
        <div>
            <div className="row mb-1" style={{ padding: '1px' }}>
                <div className="col-sm-6" style={{ padding: '1px' }}>
                    <h5>Giám sát Queues</h5>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right" style={{ padding: '1px', backgroundColor: 'white' }}>
                        <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                        <li className="breadcrumb-item active">Giám sát Queues</li>
                    </ol>
                </div>
            </div>
            <section className="content">
                <div className="row mb-2">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-header" style={{ padding: '10px', borderRadius: '0px', color: 'green' }}>
                                <h5 className="card-title" style={{ height: '0px', borderRadius: '0px' }}>
                                    <i className='fa fa-hourglass'></i>&nbsp;&nbsp;<b>Tổng hợp Queues</b></h5>
                                {/* Nút bấm thu nhỏ và đóng */}
                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus" />
                                    </button>
                                    <button type="button" className="btn btn-tool" data-card-widget="remove">
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                                {/* End nút bấm thu nhỏ và đóng */}
                            </div>
                            <div className="card-body" style={{ padding: '10px', borderRadius: '0px' }}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <TableSuperQueues columns={columnsSummary} data={queues} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mb-2">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-header" style={{ padding: '10px', borderRadius: '0px', color: 'green' }}>
                                <h5 className="card-title" style={{ height: '0px', borderRadius: '0px' }}>
                                    <i className='fa fa-hourglass-end'></i>&nbsp;&nbsp;<b>Chi tiết Queues</b></h5>
                                {/* Nút bấm thu nhỏ và đóng */}
                                <div className="card-tools">
                                    <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                        <i className="fas fa-minus" />
                                    </button>
                                    <button type="button" className="btn btn-tool" data-card-widget="remove">
                                        <i className="fas fa-times" />
                                    </button>
                                </div>
                                {/* End nút bấm thu nhỏ và đóng */}
                            </div>
                            <div className="card-body" style={{ padding: '10px', borderRadius: '0px' }}>
                                <div className="row">
                                    <div className="col-sm-12">
                                        <TableSuperQueues columns={columns} data={queues} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



            </section>
        </div >
    )
}

export default SuperQueues