import React, { useState, useMemo, useEffect } from 'react'
import { CSVLink, CSVDownload } from "react-csv";
import DateRangePicker from 'react-bootstrap-daterangepicker';
import axios from 'axios';
import moment from 'moment';
import { convert12to24, convertComplete, signOut } from '../Utils'
import TableCallDetails from './TableCallDetails'


const CallDetails = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    let startDate = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY HH:mm");
    let endDate = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY 23:59");
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    // const [status, setStatus] = useState('');
    const [thoigian, setThoigian] = useState(startDate + ' - ' + endDate);
    const [phone, setPhone] = useState('');
    const [agentId, setAgentId] = useState('all')
    const [queue, setQueue] = useState('all')
    const [extension, setExtension] = useState('all')
    const [direction, setDirection] = useState('both')
    const [callStatus, setCallStatus] = useState('both')
    const [disconnect, setDisconnect] = useState('both')
    const [dnis, setDnis] = useState('all')
    const [exten, setExten] = useState([])


    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        let URL_API_LISTEXTEN = URL_API + '/extension/listExten'
        async function getExtensions() {
            const result = await axios(`${URL_API_LISTEXTEN}`, {
                headers: {
                    'x-access-token': token
                }
            });
            setExten(result.data);
        };
        getExtensions();
    }, []);

    const handleCallback = (thoi) => {
        // alert('handlCallback: ');
        setThoigian(thoi);
        console.log("Handle Callback: ", thoigian)
    }
    const handleOnSearch = async () => {
        const token = localStorage.getItem('accessToken');
        if (!thoigian) {
            setThoigian("26/04/2022 08:00 PM - 28/04/2022 04:00 AM");
            let start = startDate;
            let end = endDate;
            console.log('Start: ', start)
            console.log('End: ', end)
        }
        if (!agentId) {
            setAgentId("all");
        }
        if (!queue) {
            setQueue("all");
        }
        let dates = thoigian.split(" - ");
        let start = convert12to24(dates[0]);
        let end = convert12to24(dates[1]);;
        console.log('Start time: ', start)
        console.log('End time: ', end)
        console.log('phone: ', phone);
        console.log('agentId: ', agentId);
        console.log('queue: ', queue);
        console.log('extension: ', extension)
        console.log('direction: ', direction)
        console.log('disconnect: ', disconnect)
        console.log('callStatus: ', callStatus)
        console.log('dnis: ', dnis)
        // console.log('handleDateTime _thoi gian: ', thoigian);
        try {
            let getCallDetail = { start_time: start, end_time: end, phone: phone, agent_id: agentId, queue_name: queue, extension: extension, call_status: callStatus, direction: direction, disconnect: disconnect, dnis: dnis }
            // Truyền giá trị vào API để lấy data
            const result = await axios.post(`${URL_API}/reporting/CallDetails`, getCallDetail, {
                headers: { 'x-access-token': token }
            })
            setData(result.data)
            //    setLoadingData(false);
            // setStatus();
            console.log('get list Data: ', getCallDetail)
            console.log('get list Data result: ', result.data)
        } catch (err) {
            console.log('Get error: ', err)
            console.log('Get error response: ', err.response)
            console.log('Get error response.data: ', err.response.data)
            // console.log('Get error response.data.errMsg: ', err.response.data.errMsg)
            if (err.response.data.errMsg === 'jwt expired') {
                console.log('Token was expired')
                signOut();
            } else {
                console.log('Recordings.js Error in 266: ', err)
            }
        }
    }
    const handleOnChangeThoiGian = (event) => {
        setThoigian(event.target.value);
        console.log("handleChange: ", thoigian)
    };
    const handleOnChangePhone = (event) => {
        if (!event) {
            setPhone('all');
        }
        setPhone(event.target.value);
        console.log("handleOnChangePhone: ", phone)
    };
    const handleOnChangeAgentId = (event) => {
        // const valueOfInput = setValue(event.target.value);
        if (!event) {
            setAgentId('all');
        }
        setAgentId(event.target.value);
        console.log("handleOnChangeAgentId: ", agentId)
    };
    const handleOnChangeQueue = (event) => {
        if (!event) {
            setQueue('all');
        }
        setQueue(event.target.value);
        console.log("handleOnChangeQueue: ", queue)
    };
    const handleOnChangeExtension = (event) => {
        setExtension(event.target.value);
        console.log("handleOnChangeExtension: ", extension)
    }
    const handleOnChangeDirection = (event) => {
        setDirection(event.target.value);
        console.log("handleOnChangeDirection: ", direction)
    }
    const handleOnChangeStatus = (event) => {
        setCallStatus(event.target.value);
        console.log("handleOnChangeStatus: ", callStatus)
    }
    const handleOnChangeDisconnect = (event) => {
        setDisconnect(event.target.value);
        console.log("handleOnChangeDirection: ", disconnect)
    }
    const handleOnChangeDnis = (event) => {
        setDnis(event.target.value);
        console.log("handleOnChangeDnis: ", dnis)
    }
    const handleExportCallDetails = () => {
        alert('Export báo cáo Call Details')
    }
    function convertDate(unix_timestamp) {

        const milliseconds = unix_timestamp * 1000
        const dateObject = new Date(milliseconds)
        const startDate = dateObject.toLocaleString()
        return startDate;
    }
    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                accessor: 'id',
                Filter: false,
                Cell: ({ row }) => (
                    <>
                        <span>{row.index + 1}</span>
                    </>
                ),
            },
            {
                Header: 'Thời gian',
                accessor: row => convertDate(row.started_time),
                Filter: false,
            },
            {
                Header: 'Phone',
                accessor: 'phone',
            },
            {
                Header: 'AgentID',
                accessor: 'agent_id',
            },
            {
                Header: 'Nhóm',
                accessor: 'queue_name',
            },
            {
                Header: 'Số nội bộ',
                accessor: 'extension',
            },
            {
                Header: 'Waiting',
                accessor: 'wait_time',
                Filter: false,
            },
            {
                Header: 'Talktime',
                accessor: 'talk_time',
                Filter: false,
            },
            {
                Header: 'Hướng',
                accessor: 'direction',
            },
            {
                Header: 'Tình trạng',
                accessor: 'call_status',
            },
            {
                Header: 'Ngắt máy',
                accessor: row => convertComplete(row.disconnect),
            },
        ]);
    return (
        <div>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Chi tiết cuộc gọi</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                                    <li className="breadcrumb-item active">Chi tiết cuộc gọi</li>
                                </ol>
                            </div>
                        </div>
                    </div>

                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* /.card */}
                                <div className="card">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className="col-4">
                                                <div className="form-group">
                                                    <label className="col-form-label col-form-label-sm">Thời gian</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="far fa-clock" /></span>
                                                        </div>
                                                        {/* <input type="text" className="form-control form-control-sm float-right" id="reservationtime" /> */}
                                                        <DateRangePicker
                                                            className="form-control form-control-sm float-right"
                                                            onEvent={(event) => handleOnChangeThoiGian(event)} value={thoigian}
                                                            onCallback={(event) => handleCallback(event.timePicker)}
                                                            initialSettings={{
                                                                // timePicker: true,
                                                                showDropdowns: true,
                                                                startDate: startDate,
                                                                endDate: endDate,
                                                                locale: {
                                                                    format: 'MM/DD/YYYY HH:mm:ss',
                                                                },
                                                            }}
                                                        >
                                                            <input type="text" className="form-control col-12  form-control-sm" />
                                                        </DateRangePicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">Phone</label>
                                                        <input type="text" onChange={(event) => handleOnChangePhone(event)} value={phone} className="form-control form-control-sm" placeholder="Nhập SĐT..." />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">Agent ID</label>
                                                        <select defaultValue={'all'} onChange={(event) => { handleOnChangeAgentId(event) }} className='form-control form-control-sm'>
                                                            <option value='all'>Tất cả</option>
                                                            <option value='hanp'>Hanp</option>
                                                            <option value='dungdt'>Dungdt</option>
                                                            <option value='tuannm'>Tuannm</option>
                                                            <option value='toandd'>Toandd</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">Nhóm</label>
                                                        <select style={{ transition: 0 }} defaultValue={'all'} onChange={(event) => { handleOnChangeQueue(event) }} className="form-control form-control-sm">
                                                            <option value='all'>Tất cả</option>
                                                            <option value='kythuat'>Kỹ thuật</option>
                                                            <option value='kinhdoanh'>Kinh doanh</option>
                                                            <option value='ketoan'>Kế toán</option>
                                                            <option value='nhansu'>Nhân sự</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">Extension</label>
                                                        <select defaultValue='all' onChange={(event) => { handleOnChangeExtension(event) }} className="form-control form-control-sm">
                                                            <option value='all'>Tất cả</option>
                                                            {
                                                                exten.map((item, i) => <option key={i} value={item.username}>{item.username}</option>)
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <label className="col-form-label col-form-label-sm">Hướng gọi</label>
                                                    <select defaultValue={'all'} onChange={(event) => { handleOnChangeDirection(event) }} className='form-control form-control-sm'>
                                                        <option value='both'>Cả hai</option>
                                                        <option value='inbound'>Gọi vào</option>
                                                        <option value='outbound'>Gọi ra</option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='row' style={{ padding: 0 }}>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <label className="col-form-label col-form-label-sm">Ngắt máy trước</label>
                                                    <select defaultValue={'all'} onChange={(event) => { handleOnChangeDisconnect(event) }} className='form-control form-control-sm'>
                                                        <option value='both'>Cả hai</option>
                                                        <option value='agent'>Nhân viên</option>
                                                        <option value='customer'>Khách hàng</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <label className="col-form-label col-form-label-sm">Tình trạng</label>
                                                <select defaultValue={'both'} onChange={(event) => { handleOnChangeStatus(event) }} className="form-control form-control-sm">
                                                    <option value='both'>Tất cả</option>
                                                    <option value='answered'>Trả lời</option>
                                                    <option value='unanswered'>Không trả lời</option>
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <label className="col-form-label col-form-label-sm">Đầu số - số đích</label>
                                                <select defaultValue={'all'} onChange={(event) => { handleOnChangeDnis(event) }} className="form-control form-control-sm">
                                                    <option value='all'>Tất cả</option>
                                                </select>
                                            </div>
                                            <div className="col-2">
                                                <div>
                                                    <label className="col-form-label col-form-label-sm">&nbsp;</label>
                                                    <button type="submit" onClick={() => { handleOnSearch() }}
                                                        className="btn btn-success form-control form-control-sm btn-sm">
                                                        <i className="fas fa-search fa-fw" />&nbsp; Xem báo cáo</button>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div>
                                                    <label className="col-form-label col-form-label-sm">&nbsp;</label><br></br>
                                                    {/* <button type="submit" onClick={() => { handleExportCallDetails() }}
                                                        className="btn btn-success form-control form-control-sm btn-sm">
                                                        <i className="fas fa-file-export" />&nbsp; Export báo cáo</button> */}
                                                    <i className="fas fa-download" style={{ color: 'red' }} />&nbsp;&nbsp;
                                                    <CSVLink
                                                        filename="Bao_cao_chi_tiet.csv"
                                                        data={data}>Download báo cáo
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TableCallDetails columns={columns} data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </div>
    )
}

export default CallDetails