const initialState = {
    list: [],
    activeId: null,
}

const extensionReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_EXTENSION': {
            const newList = [...state.list];
            newList.push(action.payload);

            return {
                ...state,
                list: newList
            }
        }
        case 'DEL_EXTENSION': {
            const newList = [...state.list];
            newList.pop(action.payload);

            return {
                ...state,
                list: newList
            }
        }
        // case 'LIST_EXTENSION': {
        //     const newList = [...state.list];

        //     return {
        //         ...state,
        //         list: newList
        //     }
        // }

        default:
            return state;
    }
}

export default extensionReducer;