import React, { useState, useEffect } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import { toast } from 'react-toastify'
import socket from './socketConnect'
// import io from 'socket.io-client';
// const socket = io('http://localhost:6600', { transports: ['websocket'], autoConnect: false });

const Header = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    const [statePhone, setStatePhone] = useState('')
    console.log('statePhone, trạng thái của Phone: ', statePhone)

    const [popup, setPopup] = useState({
        show: false,
        id: null,
    });
    const handlePersonal = () => {
        // alert('Thông tin cá nhân: ');
        setPopup({
            show: true,
            id: null,
        })
    }
    const handleSignOut = () => {
        const token = localStorage.getItem('accessToken');
        alert('Đăng xuất nhân viên');
        async function signOut() {
            let username = { username: localStorage.getItem('username') }
            const result1 = await axios.post(`${URL_API}/signout`, username, {
                headers: {
                    'x-access-token': token
                }
            });
            console.log('SignOut 1', result1)
        };
        signOut();
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('tokenExpiredAt');
        localStorage.removeItem('username');
        localStorage.removeItem('fullname');
        localStorage.removeItem('extension');
        localStorage.removeItem('role');
        localStorage.removeItem('isAuth');
        window.location.reload(false);
    }
    const handleClose = () => {
        setPopup(
            {
                show: false,
                id: null,
            });
    };

    // Lay trang thai cua extension
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        // console.log('Get extension list: ', extensions)
        async function getStatusByExtensionId() {
            let extensionId = { extensionId: "PJSIP/" + localStorage.getItem('extension') }
            console.log("get extension: ", extensionId)
            const result1 = await axios.post(`${URL_API}/online/getOnlineExtensionById`, extensionId, {
                headers: {
                    'x-access-token': token
                }
            });
            if (result1.data != null) {
                console.log('Lay thong tin extension 1 ', result1)
                console.log('Lay thong tin extension 1 1 ', result1.data)
                console.log('Lay thong tin extension 1 1 1 ', result1.data.device)
                setStatePhone(result1.data.state);
            }
        };
        if (localStorage.getItem('extension')) {
            getStatusByExtensionId();
        }
    }, []);
    useEffect(() => {
        console.log('header socket.connected in Header.js: ', socket.connected)
        if (socket.connected !== true) {
            socket.open((err) => {
                if (err) {
                    // an error has occurred
                    console.log('Header.js Thiet lập kết nối không thành công: ', err)
                } else {
                    // the connection was successfully established
                    console.log('Header.js Thiet lập kết nối thành công: ')
                    console.log('Header.js socket connected: ', socket.connected); // true
                    console.log('Header.js connect socket_id: ', socket.id)
                }
            });
            if (socket.connected === true) {
                socket.on("connect", () => {
                    console.log('Header.js connect socket_id: ', socket.id)
                });
            }
        }
        socket.on("connect_error", (err) => {
            console.log('Header connect_error : socket_id: ', socket.id)
            console.log(`Header socket listen error due to ${err.message}`);
        });

        // function showNotificationState(trangthai, sonoibo) {
        //     let thongbao = trangthai;
        //     let message = '';
        //     switch (thongbao) {
        //         case 'NOT_INUSE':
        //             message = 'Sẵn sàng';
        //             break;
        //         case 'UNAVAILABLE':
        //             message = 'Không sẵn sàng';
        //             break;
        //         default:
        //             message = 'Sẵn sàng';
        //             break;
        //     }
        //     const notification = new Notification("Một thông báo mới được gửi đến", {
        //         body: 'Số nội bộ ' + sonoibo + ' ' + message
        //     });
        // }

        socket.on('ExtensionsEvent', async (da) => {
            // if (da.device === "PJSIP/" + localStorage.getItem('extension')) {
            if (da.device === localStorage.getItem('extension')) {
                console.log('Header.js Get trang thai extension: ', da.device)
                setStatePhone(da.state)
                let message;
                switch (da.state) {
                    case 'NOT_INUSE':
                        message = 'Sẵn sàng';
                        // showNotificationState('NOT_INUSE', da.device);
                        break;
                    case 'UNAVAILABLE':
                        message = 'Không sẵn sàng';
                        // showNotificationState('UNAVAILABLE', da.device);
                        break;
                    case 'INUSE':
                        message = 'Đang bận';
                        break;
                    case 'RINGING':
                        message = 'Đổ chuông';
                        break;
                    case 'ONHOLD':
                        message = 'Hold máy';
                        break;
                    default:
                        message = 'Sẵn sàng';
                        break;
                }
                console.log('Header.js Header da nhan socket value 1: ', da)
                // toast.success('Thay đổi trạng thái extension thành công ' + da.device + ' => ' + message);
            }
        });

        return () => {
            // socket.on("disconnect", (reason) => {
            //     console.log('Header.js disconnect socketid: ', socket.id); // undefined
            //     console.log('Header.js disconnect reason: ', reason);
            //     socket.disconnect(true);
            // });
            // before the component is destroyed
            // unbind all event handlers used in this component
            socket.off("ExtensionsEvent", function () {
                console.log('Header.js socket off: ')
            });
            socket.off("disconnect", function () {
                console.log('Header.js socket off disconnect: ')
            });
            socket.off("connect_error", function () {
                console.log('Header.js socket off connect_error: ')
            });
        };
    }, []);
    return (
        <>
            {/* <div> */}
            <Modal show={popup.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Thông tin cá nhân</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p>Họ và tên: {localStorage.getItem('fullname')}</p>
                        <p>Vai trò: {localStorage.getItem('role')}</p>
                        <p>Số nội bộ: {localStorage.getItem('extension')}</p>
                        {/* {username} */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Đóng
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Navbar */}
            <nav className="main-header navbar navbar-expand navbar-dark">
                {/* Left navbar links */}
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#top" role="button"><i className="fas fa-bars" /></a>
                    </li>
                    {/* <li className="nav-item d-none d-sm-inline-block">
                        <a href="/index.html" className="nav-link">Home </a>
                    </li> */}
                </ul>
                {/* Right navbar links */}
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item dropdown user-menu">
                        <a href="#top" className="nav-link dropdown-toggle" data-toggle="dropdown">
                            <button style={{
                                fontSize: '14px', padding: '5px', size: '250px',
                                color: 'black', background: 'white'
                            }}
                                className='btn btn-success'>
                                {localStorage.getItem('extension')}&nbsp;&nbsp;&nbsp;
                                {statePhone === 'UNAVAILABLE' &&
                                    <i className="fas fa-circle" title='Không sẵn sàng' style={{ color: '#ff4444' }}></i>}
                                {statePhone === 'NOT_INUSE' &&
                                    <i className="fas fa-circle" title='Sẵn sàng' style={{ color: '#00C851' }}></i>}
                                {statePhone === 'RINGING' &&
                                    <i className="fas fa-circle" title='Đổ chuông' style={{ color: '#ffbb33' }}></i>}
                                {statePhone === 'INUSE' &&
                                    <i className="fas fa-circle" title='Đang bận' style={{ color: '#33b5e5' }}></i>}
                                {statePhone === 'ONHOLD' &&
                                    <i className="fas fa-pause" title='Hold máy' style={{ color: '#33b5e5' }}></i>}
                            </button>

                        </a>
                        {console.log('reload header >>>', statePhone)}
                    </li>
                    <li className="nav-item dropdown user-menu">
                        <a href="#top" className="nav-link dropdown-toggle" data-toggle="dropdown">
                            <img src="../../dist/img/user2-160x160.jpg" className="user-image img-circle elevation-2" alt="User Image" />
                            <span className="d-none d-md-inline">{localStorage.getItem('username')}</span>
                        </a>
                        <ul className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            {/* User image */}
                            <li className="user-header bg-success">
                                <img src="../../dist/img/user2-160x160.jpg" className="img-circle elevation-2" alt="User Image" />
                                <p>
                                    {localStorage.getItem('fullname')}<br />
                                    Số nội bộ: {localStorage.getItem('extension')}
                                    {/* <small>Tạo account: 12/4/2022</small> */}
                                </p>
                            </li>
                            {/* Menu Footer*/}
                            <li className="user-footer">
                                <button className="btn btn-default btn-flat" onClick={() => { handlePersonal() }}>Cá nhân</button>
                                <button className="btn btn-default btn-flat float-right" onClick={() => { handleSignOut() }}>Đăng xuất</button>
                            </li>
                        </ul>
                    </li>
                    <li className="nav-item">
                        <a className="nav-link" data-widget="fullscreen" href="#top" role="button">
                            <i className="fas fa-expand-arrows-alt" />
                        </a>
                    </li>
                </ul>
            </nav >
            {/* /.navbar */}
            {/* </div > */}
        </>
    )
}

export default Header