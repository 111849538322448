import React, { useEffect, useState, useMemo } from 'react';
import TableVip from './TableVip';
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DefaultColumnFilter } from '../Common/extensionData';


const VIP_BlackLists = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    const [data, setData] = useState([]);
    const [vipData, setVipData] = useState([]);
    const [blackListData, setBlackListData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [isEnableButton, setIsEnableButton] = useState(true);
    const [again, setAgain] = useState(false);
    const [updateVipBlackList, setUpdateVipBlackList] = useState(false);
    const [id, setId] = useState('');
    const [networkName, setNetworkName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [status, setStatus] = useState('');
    const [reason, setReason] = useState('');
    const [notes, setNotes] = useState('');
    const [popup, setPopup] = useState({
        show: false,
        id: null,
        deleteId: null,
    });

    const handleClose = () => {
        setPopup(
            {
                show: false,
                id: null,
                deleteId: null,
            });
    };
    const handleShow = (row) => {
        setPopup({ show: true, id: row['row'].original.id, deleteId: row['row'].index });
        console.log('Show account11: ', row['row'].original.id)
        console.log('Show account22: ', row)
        console.log('Show account33 deleteId: ', row['row'].index)
    }
    const handleEdit = (row) => {
        // setPopup({ show: true, id: row['row'].original.networkName });
        console.log('row: ', row)
        // alert('tét')
        // let port = row['row'].original.port;
        // setId(row['row'].original.id)
        setPhoneNumber(row['row'].original.phoneNumber)
        setStatus(row['row'].original.status)
        setNotes(row['row'].original.reason)
        // (phoneNumber) ? setIsEnableButton(false) : setIsEnableButton(true);
        // console.log('Edit account: ', row['row'].original.networkName, row['row'].original.roleId)
    }
    const handleDeleteYes = async () => {
        const token = localStorage.getItem('accessToken');
        let maloi = '';
        if (popup.show && popup.id) {
            setPopup({
                show: false,
                id: null,
                deleteId: null,
            });
            let tralai = await axios.delete(`${URL_API}/network/deleteNetwork/${popup.id}`, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    console.log(' Da Xoa 1: ', response);
                    const newObj = data.splice(popup.deleteId, 1)
                    console.log(' Xoa 2: ', response.data.errCode)
                    console.log('Xoa New Object: ', newObj)
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' loi 1: ', error);
                });

            setTimeout(() => {
                if (maloi === 0) {
                    toast.success('Đã xóa nhà mạng thành công ' + popup.id);
                    console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Xóa nhà mạng không thành công ' + popup.id);
                }
                setLoadingData(true)
                setAgain(true)
                console.log('Xóa 4 deleteId: ', popup.deleteId)
                console.log('Data new: ', data)
            }, 50);
        }
    }

    const handleShowPass = () => {
        alert('Show mật khẩu')
    }

    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                Cell: ({ row }) => (
                    <><h6>{row.index + 1}</h6></>
                ),
            },
            {
                Header: 'Số điện thoại',
                accessor: 'phoneNumber',
                Filter: DefaultColumnFilter,
            },
            {
                Header: 'Ghi chú',
                accessor: 'reason',
                Filter: false,
            },
            {
                Header: 'Action',
                accessor: 'action',
                Filter: false,
                Cell: ({ row, id }) =>
                    <>{row.header}
                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleEdit({ row }) }}><i className='fas fa-edit'></i></span> &nbsp;
                        <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { handleShow({ row }) }}><i className='fas fa-trash-alt'></i></span>
                    </>,
            },
        ]);
    // get Vip Data
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        console.log('Again in effect: ', again)
        async function getAllVip() {
            if (again === false) {
                const result = await axios.get(`${URL_API}/vipBlackList/getAllVip`, {
                    headers: {
                        'x-access-token': token
                    }
                });
                setVipData(result.data);
                setLoadingData(false);
                console.log('refresh API')
            } else {
                // setData(data);
                // setAgain(true);
                setLoadingData(false);
                console.log('refresh No API')
            }
            // setStatus();
            console.log('Ket qua refresh1: ', data)
            console.log('Ket qua again: ', again)
        };
        getAllVip();
        // }
    }, [loadingData]);
    // get BlackList
    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        console.log('Again in effect: ', again)
        async function getAllBlackList() {
            if (again === false) {
                const result = await axios.get(`${URL_API}/vipBlackList/getAllBlackList`, {
                    headers: {
                        'x-access-token': token
                    }
                });
                setBlackListData(result.data);
                setLoadingData(false);
                console.log('refresh API')
            } else {
                // setData(data);
                // setAgain(true);
                setLoadingData(false);
                console.log('refresh No API')
            }
            // setStatus();
            console.log('Ket qua refresh1: ', data)
            console.log('Ket qua again: ', again)
        };
        getAllBlackList();
        // }
    }, [loadingData]);


    // Add new Network
    const HandleAddNewVipBlackList = async () => {
        const token = localStorage.getItem('accessToken');
        console.log('Vip BlackList phoneNumber, status, reason: ', phoneNumber, status, reason)
        let maloi = '';
        if ((phoneNumber !== '') && (status !== '')) {
            console.log('data: ', data)
            console.log('data id: ', data[data.length - 1].id)
            // POST request using axios inside useEffect React hook
            let vipBlackList = { phoneNumber: phoneNumber, status: status, reason: reason }
            let tralai = await axios.post(`${URL_API}/vipBlackList/saveVipBlackList`, vipBlackList, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    const newObj = data.push(Object.assign({ id: data[data.length - 1].id + 1 }, vipBlackList))
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' loi 1: ', error);
                });
            setTimeout(() => {
                if (maloi === 0) {
                    toast.success('Thêm mới Vip BlackList thành công ' + phoneNumber);
                } else {
                    toast.warning('Thêm mới Vip BlackList không thành công, Phone Number đã sử dụng ' + phoneNumber);
                }
                setLoadingData(true)
                setPhoneNumber('')
            }, 50);

        } else {
            alert("Tên nhà mạng hoặc địa chỉ IP không được để trống!")
        }
    }

    const handleOnChangePhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
    }
    const handleOnChangeVIPBlacklist = (event) => {
        setStatus(event.target.value);
    }
    const handleOnChangeNotes = (event) => {
        setNotes(event.target.value);
    }

    const HandleUpdateVipBlackList = () => {
        const token = localStorage.getItem('accessToken');
        // POST request using axios inside useEffect React hook
        let updateVipBlackList = { phoneNumber: phoneNumber, status: status, reason: reason }
        axios.post(`${URL_API}/vipBlackList/updateVipBlackList/${id}`, updateVipBlackList, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // console.log(response);
            })
            .catch(function (error) {
                // console.log(error);
            });

        setTimeout(() => {
            toast.success('Cập nhật Vip BlackList thành công ' + phoneNumber);
            // setStatus('Cập nhật thành công Account: ' + networkName);
            setLoadingData(phoneNumber)
            setPhoneNumber('')
        }, 1000);
    }

    const handleAddNew = () => {
        setUpdateVipBlackList(false)
        setPhoneNumber('')
        setIsEnableButton(true)
    }
    return (
        <div>
            <Modal show={popup.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cảnh báo xóa thông tin nhà mạng</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc muốn xóa nhà mạng này? {popup.id}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteYes}>
                        Có
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Không
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Section 2 */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-3">
                            {/* general form elements */}
                            <div className="card card-success">
                                <div className="card-header">
                                    <h3 className="card-title sm">
                                        {(updateVipBlackList === false) ? (
                                            <span><i className='far fa-smile'></i>&nbsp;&nbsp;Thêm mới VIP/BlackList</span>
                                        ) : (
                                            <span><i className='far fa-smile'></i>&nbsp;&nbsp;Cập nhật VIP/BlackList</span>
                                        )}
                                    </h3>
                                </div>
                                <form>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="phoneNumber">Số điện thoại</label>
                                            <input type="text"
                                                onChange={(event) => { handleOnChangePhoneNumber(event) }}
                                                value={phoneNumber}
                                                className="form-control form-control-sm is-valid" id="phoneNumber"
                                                placeholder="Nhập vào số điện thoại" required />
                                        </div>
                                        <div className="form-group has-validation">
                                            <label htmlFor="Vip_BlackList">VIP/BlackList</label>
                                            <select value={status} onChange={(event) => { handleOnChangeVIPBlacklist(event) }}
                                                className='form-control form-control-sm'>
                                                <option value='vip'>VIP</option>
                                                <option value='blacklist'>BlackList</option>
                                            </select>
                                        </div>
                                        <div>
                                            <label htmlFor="notes">Ghi chú</label>
                                            <input type="text" onChange={(event) => { handleOnChangeNotes(event) }}
                                                value={notes} className="form-control form-control-sm"
                                                id="notes" placeholder="Nhập vào ghi chú" />
                                        </div>
                                    </div>
                                    <div className="card-footer">
                                        <button type="button" onClick={() => {
                                            (phoneNumber === false) ? HandleAddNewVipBlackList() : HandleUpdateVipBlackList()
                                        }}
                                            className="btn btn-success">
                                            {(phoneNumber === false) ? (
                                                <span><i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</span>
                                            ) : (
                                                <span><i className='fas fa-sync'></i>&nbsp;&nbsp;Cập nhật</span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>

                        <div className="col-md-9">
                            <div className="card">
                                {/* <div className="card-header"> */}
                                <div className="card-tools">
                                    <a href='#top' onClick={() => { handleAddNew() }}>
                                        {(updateVipBlackList === true) ? (
                                            <span style={{ color: 'green' }}>
                                                <i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</span>
                                        ) : (
                                            <span></span>
                                        )}
                                    </a>
                                </div>
                                {/* </div> */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div style={{ height: '32px' }}>
                                                <h3 className="card-title"><b>Số VIP</b></h3>
                                            </div>
                                            <TableVip columns={columns} data={vipData} />
                                        </div>
                                        <div className="col-md-6">
                                            <div style={{ height: '32px' }}>
                                                <h3 className="card-title"><b>Số BlackList</b></h3>
                                            </div>
                                            <TableVip columns={columns} data={blackListData} />
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default VIP_BlackLists