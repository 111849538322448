import React, { useState, useMemo, useEffect } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import { CSVLink, CSVDownload } from "react-csv";
import axios from 'axios';
import moment from 'moment';
import TableHours from './TableHours'
import { signOut } from './../Utils'


const Hours = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    let startDateTime = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY HH:mm");
    let endDateTime = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY 23:59");
    let currentDate = moment().subtract(1, 'days').startOf('day').format("MM/DD/YYYY");
    const [data, setData] = useState([]);
    // const [status, setStatus] = useState('');
    const [thoigian, setThoigian] = useState(currentDate);

    const [agentId, setAgentId] = useState('all')
    const [queue, setQueue] = useState('all')
    const [direction, setDirection] = useState('both')
    const [callStatus, setCallStatus] = useState('both')
    const [exten, setExten] = useState([])

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        let URL_API_LISTEXTEN = URL_API + '/extension/listExten'
        async function getExtensions() {
            const result = await axios(`${URL_API_LISTEXTEN}`, {
                headers: {
                    'x-access-token': token
                }
            });
            setExten(result.data);
        };
        getExtensions();
    }, []);

    const handleCallback = (thoi) => {
        // alert('handlCallback: ');
        setThoigian(thoi);
        console.log("Handle Callback: ", thoigian)
    }
    const handleOnReportCallByHours = async () => {
        const token = localStorage.getItem('accessToken');
        if (!thoigian) {
            setThoigian("26/04/2022 08:00 PM - 28/04/2022 04:00 AM");
            let start = startDay;
            let end = endDay;
            console.log('Start: ', start)
            console.log('End: ', end)
        }
        if (!agentId) {
            setAgentId("all");
        }
        if (!queue) {
            setQueue("all");
        }
        function convertDateToUnixTime(strDateTime) {
            let convertDate = new Date(strDateTime);
            const unixTimestamp = Math.floor(convertDate.getTime() / 1000);
            return unixTimestamp;
        }
        let startDay = thoigian + " 00:00"
        let endDay = thoigian + " 23:59"
        let startUnixTime = convertDateToUnixTime(startDay)
        let endUnixTime = convertDateToUnixTime(endDay)
        console.log('direction: ', direction)
        console.log('thoigian: ', thoigian, ' ')
        console.log('start1: ', startDay, convertDateToUnixTime(startDay))
        console.log('end1: ', endDay, convertDateToUnixTime(endDay))

        try {
            let getCallsHours = { start_time: startUnixTime, end_time: endUnixTime }
            // Truyền giá trị vào API để lấy data
            const result = await axios.post(`${URL_API}/reporting/summaryCallByHours`, getCallsHours, {
                headers: { 'x-access-token': token }
            })
            setData(result.data)
        } catch (err) {
            console.log('Get error: ', err)
            console.log('Get error response: ', err.response)
            console.log('Get error response.data: ', err.response.data)
            console.log('Get error response.data.errMsg: ', err.response.data.errMsg)
            if (err.response.data.errMsg === 'jwt expired') {
                console.log('Token was expired')
                signOut();
            } else {
                console.log('Recordings.js Error in 266: ', err)
            }
        }

    }
    const handleOnChangeThoiGian = (event) => {
        setThoigian(event.target.value);
        // setStartDay(thoigian + "00:00")
        // setEndDay(thoigian + "23:59")
        console.log("handleOnChangeThoiGian: ", thoigian)
    };
    const handleOnChangeQueue = (event) => {
        if (!event) {
            setQueue('all');
        }
        setQueue(event.target.value);
        console.log("handleOnChangeQueue: ", queue)
    };
    const handleOnChangeDirection = (event) => {
        setDirection(event.target.value);
        console.log("handleOnChangeDirection: ", direction)
    }
    const handleOnChangeStatus = (event) => {
        setCallStatus(event.target.value);
        console.log("handleOnChangeStatus: ", callStatus)
    }
    const handleExportCallByHours = () => {
        alert('Export báo cáo theo múi giờ')
    }
    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                Cell: ({ row }) => (
                    <><h6>{row.index + 1}</h6></>
                ),
            },
            {
                Header: 'Múi giờ',
                accessor: 'ndateHour',
                Filter: false,
            },
            {
                Header: 'Tổng cuộc gọi',
                accessor: 'TongCuocGoi',
                Filter: false,
            },
            {
                Header: 'Tổng cuộc gọi vào',
                accessor: 'TongCuocGoiVao',
                Filter: false,
            },
            {
                Header: 'Trả lời',
                accessor: 'Traloi',
                Filter: false,
            },
            {
                Header: 'Không trả lời',
                accessor: 'KhongTraloi',
                Filter: false,
            },
            {
                Header: 'Tổng cuộc gọi ra',
                accessor: 'TongGoiRa',
                Filter: false,
            },
            {
                Header: 'Gọi ra có trả lời',
                accessor: 'GoiRaCoTraloi',
                Filter: false,
            },
            {
                Header: 'Gọi ra không trả lời',
                accessor: 'GoiRaKhongTraloi',
                Filter: false,
            },
        ]);
    return (
        <div>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Cuộc gọi theo múi giờ</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                                    <li className="breadcrumb-item active">Cuộc gọi theo múi giờ</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="content">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-12">
                                {/* /.card */}
                                <div className="card">
                                    <div className="card-header">
                                        <div className='row'>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <label className="col-form-label col-form-label-sm">Thời gian</label>
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="far fa-clock" /></span>
                                                        </div>
                                                        {/* <input type="text" className="form-control form-control-sm float-right" id="reservationtime" /> */}
                                                        <DateRangePicker
                                                            className="form-control form-control-sm float-right"
                                                            onEvent={(event) => handleOnChangeThoiGian(event)} value={thoigian}
                                                            onCallback={(event) => handleCallback(event.timePicker)}
                                                            initialSettings={{
                                                                singleDatePicker: true,
                                                                showDropdowns: true,
                                                                startDate: startDateTime,
                                                                endDate: endDateTime,
                                                                minYear: 2020,
                                                                maxYear: parseInt(moment().format('YYYY'), 10),
                                                            }}
                                                        >
                                                            <input type="text" className="form-control col-12  form-control-sm" />
                                                        </DateRangePicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div className="form-group">
                                                    <div>
                                                        <label className="col-form-label col-form-label-sm">Queue</label>
                                                        <select style={{ transition: 0 }} defaultValue={'all'} onChange={(event) => { handleOnChangeQueue(event) }} className="form-control form-control-sm">
                                                            <option value='all'>Tất cả</option>
                                                            <option value='kythuat'>Kỹ thuật</option>
                                                            <option value='kinhdoanh'>Kinh doanh</option>
                                                            <option value='ketoan'>Kế toán</option>
                                                            <option value='nhansu'>Nhân sự</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div>
                                                    <label className="col-form-label col-form-label-sm">&nbsp;</label>
                                                    <button type="submit" onClick={() => { handleOnReportCallByHours() }}
                                                        className="btn btn-success form-control form-control-sm btn-sm">
                                                        <i className="fas fa-search fa-fw" />&nbsp;Xem báo cáo</button>
                                                </div>
                                            </div>
                                            <div className="col-2">
                                                <div>
                                                    <label className="col-form-label col-form-label-sm">&nbsp;</label><br></br>
                                                    {/* <button type="submit" onClick={() => { handleExportCallDetails() }}
                                                        className="btn btn-success form-control form-control-sm btn-sm">
                                                        <i className="fas fa-file-export" />&nbsp; Export báo cáo</button> */}
                                                    <i className="fas fa-download" style={{ color: 'red' }} />&nbsp;&nbsp;
                                                    <CSVLink
                                                        filename="Cuoc_goi_theo_mui_gio.csv"
                                                        data={data}>Download báo cáo
                                                    </CSVLink>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-12">
                                                <TableHours columns={columns} data={data} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div >
        </div >
    )
}

export default Hours