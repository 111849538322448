import React, { Component, useEffect, useState, useMemo } from 'react';


const DstGroup = (props) => {
    return (
        <div>
            <section className="content">
                <div className="container-fluid" style={{ padding: 0 }}>
                    <div className="row" style={{ padding: 0 }}>
                        <div className="col-md-12" style={{ padding: 0 }}>
                            <div className="row" style={{ padding: 3 }}>
                                <div className="col-md-12">
                                    <div className="input-group input-group" style={{ padding: 3 }}>
                                        <div className='input-group-prepend'>
                                            <span className='input-group-text'>Nhóm 1:</span>
                                        </div>
                                        <input type="text" value={props.dataFromParent} className="form-control" />
                                        <span className="input-group-append">
                                            <button type="button" className="btn btn-success btn-flat">Cập nhật</button>
                                        </span>
                                    </div>
                                    <div className="input-group input-group" style={{ padding: 3 }}>
                                        <div className='input-group-prepend'>
                                            <span className='input-group-text'>Nhóm 2:</span>
                                        </div>
                                        <input type="text" className="form-control" />
                                        <span className="input-group-append">
                                            <button type="button" className="btn btn-success btn-flat">Cập nhật</button>
                                        </span>
                                    </div>
                                    <div className="input-group input-group" style={{ padding: 3 }}>
                                        <div className='input-group-prepend'>
                                            <span className='input-group-text'>Nhóm 3:</span>
                                        </div>
                                        <input type="text" className="form-control" />
                                        <span className="input-group-append">
                                            <button type="button" className="btn btn-success btn-flat">Cập nhật</button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >
        </div >
    )
}

export default DstGroup