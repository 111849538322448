import { React, useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker';
import moment from 'moment';

const Agent = () => {
    const [thoigian, setThoigian] = useState('');
    const handleDateTime = () => {
        alert('Nothing is free !');

        // const valueOfInput = setThoigian(event.target.value);
        // console.log('Time: ', valueOfInput);
        console.log('handleDateTime: ', thoigian);
        let dates = thoigian.split(" - ");
        let start = dates[0];
        let end = dates[1];
        console.log('Start: ', start)
        console.log('End: ', end)
    }
    const handleChange = (event) => {
        // const valueOfInput = setValue(event.target.value);
        setThoigian(event.target.value);
        console.log("handleChange: ", thoigian)
    };
    const handleCallback = (thoi) => {
        // alert('handlCallback: ');
        setThoigian(thoi);
        console.log("Handle Callback: ", thoigian)

    }
    const handleAnswer = () => {
        alert(' Answer');
    }
    const handleCancel = () => {
        alert(' Cancel');
    }
    return (
        <div>
            <div className="content-wrapper">
                <section className="content-header">
                    <div className="container-fluid">
                        <div className="row mb-2">
                            <div className="col-sm-6">
                                <h1>Nhân viên</h1>
                            </div>
                            <div className="col-sm-6">
                                <ol className="breadcrumb float-sm-right">
                                    <li className="breadcrumb-item"><a href="/index.html">Home</a></li>
                                    <li className="breadcrumb-item active">Nhân viên</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </section>
                {/* Main content */}
                <section className="content">
                    <div className="container-fluid">
                        {/* /.row */}
                        <div className="row">
                            <div className="col-md-3">
                                <div className="card">
                                    <div className="card-header" style={{ padding: '10px', borderRadius: '0px' }}>
                                        <h5 className="card-title" style={{ height: '0px', borderRadius: '0px' }}>Cuộc gọi</h5>
                                        {/* Nút bấm thu nhỏ và đóng */}
                                        <div className="card-tools">
                                            <button type="button" className="btn btn-tool" data-card-widget="collapse">
                                                <i className="fas fa-minus" />
                                            </button>
                                            <button type="button" className="btn btn-tool" data-card-widget="remove">
                                                <i className="fas fa-times" />
                                            </button>
                                        </div>
                                        {/* End nút bấm thu nhỏ và đóng */}
                                    </div>
                                    {/* /.card-header */}

                                    <div className="card-body" style={{ padding: '10px', borderRadius: '0px' }}>
                                        <div className="row">
                                            {/* /.col */}
                                            <div className="col-md-12">
                                                <p className="text-center">
                                                    <strong>Thông tin cuộc gọi</strong>
                                                </p>
                                                <div>
                                                    <p className="text-center">
                                                        <strong>Ringing</strong>
                                                    </p>
                                                    <p className="text-center">
                                                        <strong>0909654979</strong>
                                                    </p>
                                                    <p className="text-center">
                                                        <small>00:00:08</small>
                                                    </p>
                                                </div>
                                                {/* /.progress-group */}
                                                <div>
                                                    <span>
                                                        <button className='btn btn-block btn-success' onClick={() => { handleAnswer() }}>Trả lời</button>
                                                        <button className='btn btn-block btn-danger' onClick={() => { handleCancel() }}>Từ chối</button>
                                                    </span>

                                                </div>
                                            </div>
                                            {/* /.col */}
                                        </div>
                                        {/* /.row */}
                                    </div>
                                </div>
                                {/* /.card */}
                            </div>
                            <div className="col-md-9">
                                <div className="card">
                                    <div className="card-header" style={{ padding: '0px', borderRadius: '0px' }}>
                                        <ul className="nav nav-tabs" id="custom-tabs-two-tab" role="tablist" style={{ padding: '0px', borderRadius: '0px' }}>
                                            <li className="nav-item">
                                                <a className="nav-link active" id="custom-tabs-two-home-tab" data-toggle="pill" href="#custom-tabs-two-home" role="tab" aria-controls="custom-tabs-two-home" aria-selected="true">Lịch sử cuộc gọi</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-two-profile-tab" data-toggle="pill" href="#custom-tabs-two-profile" role="tab" aria-controls="custom-tabs-two-profile" aria-selected="false">Trạng thái làm việc</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="custom-tabs-two-messages-tab" data-toggle="pill" href="#custom-tabs-two-messages" role="tab" aria-controls="custom-tabs-two-messages" aria-selected="false">Nhóm</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="card-body">
                                        <div className="tab-content" id="custom-tabs-two-tabContent">
                                            <div className="tab-pane fade show active" id="custom-tabs-two-home" role="tabpanel" aria-labelledby="custom-tabs-two-home-tab">
                                                Lịch sử cuộc gọi
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-two-profile" role="tabpanel" aria-labelledby="custom-tabs-two-profile-tab">
                                                Trạng thái làm việc
                                            </div>
                                            <div className="tab-pane fade" id="custom-tabs-two-messages" role="tabpanel" aria-labelledby="custom-tabs-two-messages-tab">
                                                Nhóm
                                            </div>
                                        </div>
                                    </div>
                                    {/* /.card-header */}
                                </div>
                                {/* /.card */}
                            </div>
                            {/* /.col */}
                        </div>
                        {/* /.row */}

                    </div>{/*/. container-fluid */}
                </section>
                {/* /.content */}
            </div >
        </div >
    )
}

export default Agent