import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { DefaultColumnFilter } from '../Common/extensionData';
import TablePermission from './TablePermission';


const Permissions = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API
    const [data, setData] = useState([]);
    const [loadingData, setLoadingData] = useState(true);
    const [isEnableButton, setIsEnableButton] = useState(true);
    const [again, setAgain] = useState(false);
    const [updateNetwork, setUpdateNetwork] = useState(false);
    const [id, setId] = useState('');
    const [networkName, setNetworkName] = useState('');
    const [ipAddress, setIpAddress] = useState('');
    const [port, setPort] = useState('');
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [notes, setNotes] = useState('');
    const [popup, setPopup] = useState({
        show: false,
        id: null,
        deleteId: null,
    });

    const handleClose = () => {
        setPopup(
            {
                show: false,
                id: null,
                deleteId: null,
            });
    };
    const handleDeletePermission = (row) => {
        // setPopup({ show: true, id: row['row'].original.id, deleteId: row['row'].index });
        // console.log('Show account11: ', row['row'].original.id)
        // console.log('Show account22: ', row)
        // console.log('Show account33 deleteId: ', row['row'].index)
        alert('Delete permission: ')
    }
    const handleEditPermission = (row) => {
        alert('Edit Permission')
        // setPopup({ show: true, id: row['row'].original.networkName });
        console.log('row: ', row)
        // let permission_Note = row['row'].original.permission_Note;
        // setUpdateNetwork(true)
        // setId(row['row'].original.id)
        // setNetworkName(row['row'].original.networkName)
        // setIpAddress(row['row'].original.ipAddress)
        // setPort(port)
        // setUsername(row['row'].original.username)
        // setPassword(row['row'].original.password)
        // setNotes(row['row'].original.notes)
        // (networkName) ? setIsEnableButton(false) : setIsEnableButton(true);
        // console.log('Edit account: ', row['row'].original.networkName, row['row'].original.roleId)
    }
    const handleDeleteYes = async () => {
        const token = localStorage.getItem('accessToken');
        let maloi = '';
        if (popup.show && popup.id) {
            setPopup({
                show: false,
                id: null,
                deleteId: null,
            });
            let tralai = await axios.delete(`${URL_API}/network/deleteNetwork/${popup.id}`, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    console.log(' Da Xoa 1: ', response);
                    const newObj = data.splice(popup.deleteId, 1)
                    console.log(' Xoa 2: ', response.data.errCode)
                    console.log('Xoa New Object: ', newObj)
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' loi 1: ', error);
                });

            setTimeout(() => {
                if (maloi === 0) {
                    toast.success('Đã xóa nhà mạng thành công ' + popup.id);
                    console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Xóa nhà mạng không thành công ' + popup.id);
                }
                setLoadingData(true)
                setAgain(true)
                console.log('Xóa 4 deleteId: ', popup.deleteId)
                console.log('Data new: ', data)
            }, 50);
        }
    }

    const handleShowPass = () => {
        alert('Show mật khẩu')
    }

    const columns = useMemo(
        () => [
            {
                Header: 'STT',
                Cell: ({ row }) => (
                    <><h6>{row.index + 1}</h6></>
                ),
            },
            {
                Header: 'Tên quyền',
                accessor: 'permission_Name',
                Filter: false,
            },
            {
                Header: 'Mã quyền',
                accessor: 'permission_Code',
                Filter: false,
            },
            {
                Header: 'Giải thích',
                accessor: 'permission_Note',
                Filter: false,
            },
            {
                Header: 'Action',
                accessor: 'action',
                Filter: false,
                Cell: ({ row, id }) =>
                    <>{row.header}
                        <span style={{ cursor: 'pointer', color: 'blue' }} onClick={() => { handleEditPermission({ row }) }}><i className='fas fa-edit'></i></span> &nbsp;
                        <span style={{ cursor: 'pointer', color: 'red' }} onClick={() => { handleDeletePermission({ row }) }}><i className='fas fa-trash-alt'></i></span>
                    </>,
            },
        ]);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        console.log('Again in effect: ', again)
        async function getPermissions() {
            if (again === false) {
                const result = await axios.get(`${URL_API}/permission/getAllPermission`, {
                    headers: {
                        'x-access-token': token
                    }
                });
                setData(result.data);
                setLoadingData(false);
                console.log('refresh API')
            } else {
                // setData(data);
                // setAgain(true);
                setLoadingData(false);
                console.log('refresh No API')
            }
            // setStatus();
            console.log('Ket qua refresh1: ', data)
            console.log('Ket qua again: ', again)
        };
        getPermissions();
        // }
    }, [loadingData]);

    const handleOnChangeNetworkName = (event) => {
        setNetworkName(event.target.value)
        setIsEnableButton(false);
    }
    const handleOnChangeIpAddress = (event) => {
        // console.log('Port: ', event.target.value)
        setIpAddress(event.target.value);
    }
    const handleOnChangePort = (event) => {
        // console.log('Port: ', event.target.value)
        setPort(event.target.value);
    }
    const handleOnChangeUsername = (event) => {
        setUsername(event.target.value);
    }
    const handleOnChangePassword = (event) => {
        // console.log('Password: ', event.target.value)
        setPassword(event.target.value);
    }
    const handleOnChangeNotes = (event) => {
        setNotes(event.target.value);
    }
    // Add new Network
    const HandleAddNewNetwork = async () => {
        const token = localStorage.getItem('accessToken');
        console.log('Networks networkName, ipAddress, port, username, password, notes: ', networkName, ipAddress, port, username, password, notes)
        let maloi = '';
        if ((networkName !== '') && (ipAddress !== '')) {
            console.log('data: ', data)
            console.log('data id: ', data[data.length - 1].id)
            // POST request using axios inside useEffect React hook
            let network = { networkName: networkName, ipAddress: ipAddress, port: port, username: username, password: password, notes: notes }
            let tralai = await axios.post(`${URL_API}/network/saveNetwork`, network, {
                headers: {
                    'x-access-token': token
                }
            })
                .then(function (response) {
                    const newObj = data.push(Object.assign({ id: data[data.length - 1].id + 1 }, network))
                    return maloi = response.data.errCode
                })
                .catch(function (error) {
                    console.log(' loi 1: ', error);
                });
            setTimeout(() => {
                if (maloi === 0) {
                    toast.success('Thêm mới nhà mạng thành công ' + networkName);
                    // console.log('LoadingData: ', loadingData)
                } else {
                    toast.warning('Thêm mới nhà mạng không thành công, địa chỉ IP đã sử dụng ' + networkName);
                }
                setLoadingData(true)
                setNetworkName('')
                // console.log('Data new: ', data)
            }, 50);

        } else {
            alert("Tên nhà mạng hoặc địa chỉ IP không được để trống!")
        }
    }
    const HandleUpdateNetwork = () => {
        const token = localStorage.getItem('accessToken');
        // POST request using axios inside useEffect React hook
        let updateNetwork = { id: id, networkName: networkName, ipAddress: ipAddress, port: port, username: username, password: password, notes: notes }
        axios.post(`${URL_API}/network/updateNetwork/${id}`, updateNetwork, {
            headers: {
                'x-access-token': token
            }
        })
            .then(function (response) {
                // console.log(response);
            })
            .catch(function (error) {
                // console.log(error);
            });

        setTimeout(() => {
            toast.success('Cập nhật nhà mạng thành công ' + networkName);
            // setStatus('Cập nhật thành công Account: ' + networkName);
            setLoadingData(networkName)
            setNetworkName('')
        }, 1000);
    }

    const handleAddNew = () => {
        setUpdateNetwork(false)
        setNetworkName('')
        setIsEnableButton(true)
    }
    return (
        <div>
            <Modal show={popup.show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Cảnh báo xóa thông tin nhà mạng</Modal.Title>
                </Modal.Header>
                <Modal.Body>Bạn có chắc muốn xóa nhà mạng này? {popup.id}</Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDeleteYes}>
                        Có
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                        Không
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* Section 2 */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        {/* left column */}
                        <div className="col-md-3">
                            {/* general form elements */}
                            <div className="card card-success">
                                <div className="card-header">
                                    <h3 className="card-title sm">
                                        {(updateNetwork === false) ? (
                                            <span><i className='fas fa-user-secret'></i>&nbsp;&nbsp;Thêm mới quyền</span>
                                        ) : (
                                            <span><i className='fas fa-user-secret'></i>&nbsp;&nbsp;Cập nhật quyền</span>
                                        )}
                                    </h3>
                                </div>
                                {/* /.card-header */}
                                {/* form start */}
                                <form>
                                    <div className="card-body">
                                        <div className="form-group">
                                            <label htmlFor="permissionName">Tên quyền</label>
                                            <input type="text"
                                                onChange={(event) => { handleOnChangeNetworkName(event) }} value={networkName}
                                                className="form-control form-control-sm is-valid" id="permissionName"
                                                placeholder="Nhập vào tên quyền" required />
                                        </div>
                                        <div className="form-group has-validation">
                                            <label htmlFor="permissionCode">Mã quyền</label>
                                            <input type="text" onChange={(event) => { handleOnChangeIpAddress(event) }} value={ipAddress} className="form-control form-control-sm is-valid" id="permissionCode" placeholder="mã quyền" />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="port">Giải thích</label>
                                            <input type="text" onChange={(event) => { handleOnChangePort(event) }} value={port} className="form-control form-control-sm is-valid" id="permissionNote" placeholder="Mô tả quyền" />
                                        </div>
                                    </div>
                                    {/* /.card-body */}
                                    <div className="card-footer">
                                        <button type="button" onClick={() => {
                                            (updateNetwork === false) ? HandleAddNewNetwork() : HandleUpdateNetwork()
                                        }}
                                            className="btn btn-success">
                                            {(updateNetwork === false) ? (
                                                <span><i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</span>
                                            ) : (
                                                <span><i className='fas fa-sync'></i>&nbsp;&nbsp;Cập nhật</span>
                                            )}
                                        </button>
                                    </div>
                                </form>
                            </div>
                            {/* /.card */}
                        </div>
                        {/*/.col (left) */}
                        {/* right column */}

                        <div className="col-md-9">
                            <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Danh sách quyền</h3>
                                    <div className="card-tools">
                                        <a href='#top' onClick={() => { handleAddNew() }}>
                                            {(updateNetwork === true) ? (
                                                <span style={{ color: 'green' }}>
                                                    <i className='fas fa-plus'></i>&nbsp;&nbsp;Thêm mới</span>
                                            ) : (
                                                <span></span>
                                            )}
                                        </a>
                                    </div>
                                </div>
                                {/* /.card-header */}
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <TablePermission columns={columns} data={data} />
                                        </div>
                                    </div>
                                    {/* /.row */}
                                </div>
                            </div>
                            {/* /.card */}
                        </div>
                    </div>
                </div>{/* /.container-fluid */}
            </section>
            {/* /.content */}
        </div >
    )
}

export default Permissions