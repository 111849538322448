import React, { useEffect, useState, useMemo, useRef } from 'react'
// import { convert12to24, convertComplete, convertUnixTimeToDateTime, convertDate } from '../../components/Utils'
import axios from 'axios';
// import moment from 'moment';
import { toast } from 'react-toastify';
import TableSuperCalls from './TableSuperCalls';
import socket from '../socketConnect'
// import io from 'socket.io-client';
// const socket = io('http://localhost:6600', { transports: ['websocket'], autoConnect: false });


// REACT_APP_PRO_URL_API=https://vietdialerapi.vietpbx.com/api/v1
// REACT_APP_DEV_URL_API=http://localhost:6600/api/v1


const SuperCalls = () => {
    let URL_API;
    (process.env.NODE_ENV === "production")
        ? URL_API = process.env.REACT_APP_PRO_URL_API
        : URL_API = process.env.REACT_APP_DEV_URL_API

    const [calls, setCalls] = useState([]);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        async function getAllCalls() {
            const result = await axios.get(`${URL_API}/calls/getAllCalls`, {
                headers: {
                    'x-access-token': token
                }
            });
            setCalls(result.data);
        };
        getAllCalls();
    }, []);
    // Không biết cái này dùng làm gì?
    // useEffect(() => {
    //     console.log('socket.connected in SuperCalls: ', socket.connected)
    //     if (socket.connected !== true) {
    //         socket.open((err) => {
    //             if (err) {
    //                 // an error has occurred
    //                 console.log('Thiet lập kết nối không thành công: ', err)
    //             } else {
    //                 // the connection was successfully established
    //                 console.log('Thiet lập kết nối thành công: ')
    //                 console.log('socket connected: ', socket.connected); // true
    //                 console.log('connect socket_id 1: ', socket.id)
    //             }
    //         });
    //         socket.on("connect", () => {
    //             console.log('connect socket_id 2: ', socket.id)
    //         });
    //     }
    //     socket.on("connect_error", (err) => {
    //         console.log('connect_error : socket_id: ', socket.id)
    //         console.log(`socket listen error due to ${err.message}`);
    //     });
    //     socket.on('CallsEvent', async function (da) {
    //         // console.log('CallsEvent: socket_id: ', socket.id)
    //         // console.log('Listen data in SuperCalls: ', calls)
    //         // console.log('Event0: ', da.event_name)
    //         if (da.event_name === 'newchannel') {
    //             const newObj1 = await calls.push(da);
    //             console.log('newchannel: ', newObj1, calls)
    //         }
    //         // Bắt đầu queuecallerjoin ==> bat dau do cuoc goi vao Queue
    //         if (da.event_name === 'queuecallerjoin') {
    //             console.log('queuecallerjoin: ', da, ' _data.length: ', calls.length)
    //             if (calls.length > 0) {
    //                 // console.log('SuperCalls Truoc khi tim: ', Math.floor(new Date().getTime()))
    //                 for (let i = 0; i < calls.length; i++) {
    //                     try {
    //                         console.log('So thu tu : ', i)
    //                         // console.log('data[i].call_id: ', calls[i].call_id)
    //                         if (calls[i].call_id === da.call_id) {
    //                             calls[i].status = da.status
    //                             calls[i].event_name = da.event_name
    //                             calls[i].queue = da.queue
    //                             calls[i].agent_id = da.agent_id
    //                             calls[i].to = da.to
    //                             calls[i].wait_time = Math.floor(new Date().getTime() / 1000) - da.wait_time
    //                             console.log('Waiting time data[i].status: ', i,
    //                                 calls[i].status, ' data[i]: ', calls[i]);
    //                             break;
    //                         } else {
    //                             console.log('before set data queuecallerjoin : ', calls)
    //                         }
    //                     } catch (e) {
    //                         console.error('queuecallerjoin: Hiển thị thông tin error: ', e);
    //                     }
    //                 }
    //             }
    //         }
    //         if (da.event_name === 'bridgeenter') {
    //             // let data = new Array();
    //             console.log('Insert Loop all: ', da, ' _data.length: ', calls.length)
    //             if (calls.length > 0) {
    //                 for (let i = 0; i < calls.length; i++) {
    //                     try {
    //                         console.log('data[0].call_id: ', calls[0].call_id)
    //                         console.log('data[i].call_id: ', calls[i].call_id)
    //                         if (calls[i].call_id === da.call_id) {
    //                             calls[i].status = da.status
    //                             calls[i].event_name = da.event_name
    //                             calls[i].queue = da.queue
    //                             calls[i].agent_id = da.agent_id
    //                             calls[i].to = da.to
    //                             // data[i].wait_time = Math.floor(new Date().getTime() / 1000) - da.wait_time
    //                             calls[i].talk_time = Math.floor(new Date().getTime() / 1000) - da.talk_time
    //                             console.log('Cap nhat trang thai connected time data[i].status: ', i,
    //                                 calls[i].status, ' data[i]: ', calls[i]);
    //                             break;
    //                         } else {
    //                             console.log('before set data: ', calls)
    //                         }
    //                     } catch (e) {
    //                         console.error('Hiển thị thông tin error: ', e);
    //                     }
    //                 }
    //             }
    //         }
    //         if (da.event_name === 'hangup') {
    //             for (let i = 0; i < calls.length; i++) {
    //                 console.log('Loop all: ', calls[i])
    //                 if (calls[i].call_id === da.call_id) {
    //                     calls.splice(i, 1)
    //                     // console.log('gia tri tuong dong: ', i, da.event_name);
    //                     break;
    //                 }
    //             }
    //             setCalls(calls);
    //         }
    //     });

    //     return () => {
    //         socket.on("disconnect", (reason) => {
    //             console.log('disconnect socketid: ', socket.id); // undefined
    //             console.log('disconnect reason: ', reason);
    //             socket.disconnect(true);
    //         });
    //         // before the component is destroyed
    //         // unbind all event handlers used in this component
    //         socket.off("CallsEvent", function () {
    //             console.log('socket off: ')
    //         });
    //         socket.off("connect_error", function () {
    //             console.log('socket off connect_error: ')
    //         });
    //         socket.off("connect", function () {
    //             console.log('socket off connect: ')
    //         });
    //     };
    // }, [])
    // Stop cái không biết này
    const [seconds, setSeconds] = useState(0);
    const myRef = useRef(seconds);
    myRef.current = seconds;
    useEffect(() => {
        let interval;
        interval = setInterval(() => {
            // console.log('Seconds 1: ', seconds)
            // let currCount = myRef.current;
            setSeconds(currCount => currCount + 1);
            // console.log('Seconds 2: ', seconds)
        }, 1000);
        if (calls.length < 0) {
            // setSeconds(0);
            clearInterval(interval);
            console.log('clear 1: ', seconds)
        }
        return () => {
            // setSeconds(0);
            clearInterval(interval);
            console.log('clear: ', seconds)
        }
    }, []);

    const handleListen = async (row) => {
        // alert('Nghe xen')
        console.log('Listen 0 : ', row)
        console.log('Listen channel : ', row.channel)
        console.log('Listen to Agent Extension : ', row.to)
        let agentChannel;
        let superChannel;
        if (row.event_name === 'bridgeenter') {
            if (row.direction === 'Outbound') {
                agentChannel = 'PJSIP/' + row.to;
            } else {
                agentChannel = row.to;
            }
            console.log('agentChannel 1: ', agentChannel)
        } else {
            agentChannel = row.channel;
            console.log('agentChannel 2: ', agentChannel)
        }
        superChannel = 'PJSIP/' + localStorage.getItem('extension');
        console.log('Supervisor Channel: ', superChannel);
        // Cần check trạng thái superChannel trước khi chuyển cuộc gọi đến
        let extensionId = { extensionId: superChannel }
        const statusSuperChannel = await axios.post(`${URL_API}/online/getOnlineExtensionById`, extensionId, {
            // headers: {
            //     'x-access-token': token
            // }
        });
        console.log('supervisor Channel 11: ', statusSuperChannel)
        console.log('supervisor Channel 22 ', statusSuperChannel.data.state)
        if (statusSuperChannel.data.state == 'NOT_INUSE') {
            // Stop check Extension Supervisor Channel Status
            const token = localStorage.getItem('accessToken');
            let ngheXen = { superExten: superChannel, agentChannel: agentChannel }
            const result = await axios.post(`${URL_API}/online/onlineListenSupervisor`, ngheXen, {
                headers: {
                    'x-access-token': token
                }
            });
            // setData(result.data);
            console.log('Goi API nghe xen: ', result)
        } else {
            toast.warning('Số nội bộ để nghe xen không sẵn sàng hoặc đang bận ' + superChannel);
        }
    }

    const handleCoach = async (row) => {
        console.log('Coach 0 : ', row)
        console.log('Coach channel : ', row.channel)
        console.log('Coach to Agent Extension : ', row.to)
        let agentChannel;
        let superChannel;
        if (row.event_name === 'bridgeenter') {
            if (row.direction === 'Outbound') {
                agentChannel = 'PJSIP/' + row.to;
            } else {
                agentChannel = row.to;
            }
            console.log('Coach agentChannel 1: ', agentChannel)
        } else {
            agentChannel = row.channel;
            console.log('Coach agentChannel 2: ', agentChannel)

        }
        superChannel = 'PJSIP/' + localStorage.getItem('extension');
        // kiểm tra trạng thái của supervisor Channel trước khi chuyển cuộc gọi đến
        const token = localStorage.getItem('accessToken');
        let nhacNho = { superExten: superChannel, agentChannel: agentChannel }
        const result = await axios.post(`${URL_API}/online/onlineCoachSupervisor`, nhacNho, {
            headers: {
                'x-access-token': token
            }
        });

        // setData(result.data);
        console.log('Coach goi API nghe xen: ', result)
    }

    const handleConf = async (row) => {
        console.log('Conference 0 : ', row)
        console.log('Conference channel : ', row.channel)
        console.log('Conference to Agent Extension : ', row.to)
        let agentChannel;
        if (row.event_name === 'bridgeenter') {
            if (row.direction === 'Outbound') {
                agentChannel = 'PJSIP/' + row.to;
            } else {
                agentChannel = row.to;
            }
            console.log('Conference agentChannel 1: ', agentChannel)
        } else {
            agentChannel = row.channel;
            console.log('Conference agentChannel 2: ', agentChannel)

        }
        const token = localStorage.getItem('accessToken');
        let hoiThoai = { superExten: 'PJSIP/8005', agentChannel: agentChannel }
        const result = await axios.post(`${URL_API}/online/onlineConfSupervisor`, hoiThoai, {
            headers: {
                'x-access-token': token
            }
        });
        // setData(result.data);
        console.log('Conference goi API nghe xen: ', result)
    }

    const handleDisconnect = async (row) => {
        console.log('Disconnect 0 : ', row)
        console.log('Disconnect channel : ', row.channel)
        console.log('Disconnect cause : ', row.to)
        let disconnectChannel = row.channel;
        let disCause = 'supervisor';
        const token = localStorage.getItem('accessToken');
        let disconnect = { disconnectChannel: disconnectChannel, disCause: disCause }
        const result = await axios.post(`${URL_API}/online/onlineDisSupervisor`, disconnect, {
            headers: {
                'x-access-token': token
            }
        });
        // setData(result.data);
        console.log('Disconnect goi API nghe xen: ', result)
    }

    const headerData = [
        {
            Header: 'STT',
            accessor: 'id',
            Filter: false,
            Cell: ({ row }) => (
                <>
                    <span>{row.index + 1}</span>
                </>
            ),
        },
        {
            Header: 'Call_ID',
            accessor: 'call_id'
        },
        {
            Header: 'Duration',
            // accessor: row => convertDate(row.started_time),
            accessor: 'duration',
            Cell: row => (
                <>
                    <span>
                        {/* {new Date((row.value + seconds) * 1000).toISOString().substring(11, 19)} */}
                        {(row.value > 0) ? (Math.floor(new Date().getTime() / 1000) - (row.value)) : ""}
                        {/* {(Math.floor(new Date().getTime() / 1000) - (row.value))} */}
                    </span>
                </>
            ),
            // accessor: {Math.floor(new Date().getTime() / 1000)}'time_id'
        },
        {
            Header: 'Số điện thoại',
            accessor: 'from',
        },
        {
            Header: 'Waiting',
            accessor: 'wait_time',
            Cell: row => (
                <>
                    <span>
                        {/* {(row.value > 0) ? new Date((row.value + seconds) * 1000).toISOString().substring(11, 19) : ""} */}
                        {(row.value > 0) ? (Math.floor(new Date().getTime() / 1000) - (row.value)) : ""}
                        {/* {row.value} */}
                    </span>
                </>
            ),
        },
        {
            Header: 'Talktime',
            accessor: 'talk_time',
            Cell: row => (
                <>
                    <span>
                        {/* {{(row.value === '0') ? "" : new Date((row.value + seconds) * 1000).toISOString().substring(11, 19)} */}
                        {(row.value > 0) ? (Math.floor(new Date().getTime() / 1000) - (row.value)) : ""}
                        {/* {(Math.floor(new Date().getTime() / 1000) - (row.value))} */}
                    </span>
                </>
            ),
        },
        {
            Header: 'EventName',
            accessor: 'event_name',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: 'AgentID',
            accessor: 'agent_id',
        },
        {
            Header: 'Nhóm',
            accessor: 'queue',
        },
        {
            Header: 'Số nội bộ',
            accessor: 'to',
            // Cell: row => (
            //     <>
            //         <span>
            //             {(row.direction === 'Inbound') ? row.extPhone : row.from}
            //         </span>
            //     </>
            // )
        },
        {
            Header: 'Hướng',
            accessor: 'direction',
        },
        {
            Header: 'Action',
            Filter: false,
            // accessor: 'call_id',
            Cell: ({ row }) =>
                <>
                    <span style={{ cursor: 'pointer' }} onClick={() => handleListen(row.original)}><button><i className="fas fa-user-secret" title='Nghe xen' style={{ color: 'green' }} /></button></span>&nbsp;
                    <span style={{ cursor: 'pointer' }} onClick={() => handleCoach(row.original)}><button><i className="fas fa-user-friends" title='Nhắc nhở' style={{ color: 'blue' }} /></button></span>&nbsp;
                    <span style={{ cursor: 'pointer' }} onClick={() => handleConf(row.original)}><button><i className="fas fa-users" title='Hội thoại' style={{ color: 'blue' }} /></button></span>&nbsp;
                    <span style={{ cursor: 'pointer' }} onClick={() => handleDisconnect(row.original)}><button><i className="fas fa-phone-slash" title='Ngắt cuộc gọi' style={{ color: 'red' }} /></button></span>
                </>,
        },
    ]

    const columns = useMemo(
        () => headerData);
    return (
        <div>
            {/* <section className="content-header">
                <div className="container-fluid"> */}
            <div className="row mb-1" style={{ padding: '1px' }}>
                <div className="col-sm-6" style={{ padding: '1px' }}>
                    <h5>Giám sát cuộc gọi</h5>
                </div>
                <div className="col-sm-6">
                    <ol className="breadcrumb float-sm-right" style={{ padding: '1px', backgroundColor: 'white' }}>
                        <li className="breadcrumb-item"><a href="/Dashboard">Home</a></li>
                        <li className="breadcrumb-item active">Giám sát cuộc gọi</li>
                    </ol>
                </div>
            </div>
            {/* </div>
            </section> */}
            <section className="content">
                <div className="row mb-2">
                    <div className="col-sm-12">
                        <TableSuperCalls columns={columns} data={calls} />
                    </div>
                </div>
            </section>
        </div >
    )
}

export default SuperCalls